import React, { useEffect, useState } from 'react'

import RegistrationFormSection from './RegistrationFormSection'
import RegistrationFormRow from './RegistrationFormRow';
import useApiUrlTranslation from './useApiUrlTranslation';
import RegistrationFormVideoPresentationSection from './RegistrationFormVideoPresentationSection';
import { use } from 'i18next';
import { useFormContext } from 'react-hook-form';
import { API_URL } from '../config';


const RegistrationFormProfilCooperationSection = ({ formulaire, contact }) => {

    const { register } = useFormContext();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactCycleLangList, setContactCycleLangList] = React.useState([]);

    const [textAreas, setTextAreas] = useState([
        { name: 'contactCycleLangList', value: '' } // Initialiser avec un textarea vide ou avec des valeurs par défaut
    ]);

    const handleAddTextArea = () => {
        setTextAreas([...textAreas, { name: 'contactCycleLangList', value: '' }]);
    };

    const handleTextAreaChange = async (e) => {
        // const newTextAreas = [...textAreas];
        // newTextAreas[index].value = newValue;
        // setTextAreas(newTextAreas);

        const newValue = e.target.value;

        const id_contact_cycle_lang = e.target.getAttribute('data-contact_cycle_lang_profil');

        await fetch(`${API_URL}?action=updateContactCycleLang2&id_contact_cycle_lang='${id_contact_cycle_lang}'`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id_contact_cycle_lang: id_contact_cycle_lang,
                contact_cycle_lang_profil: newValue
            })
        })

        console.log(id_contact_cycle_lang, newValue);
    };

    const fetchContactCycleLang = async (id_contact) => {

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter= c.id_contact='${id_contact}' AND`)
            .then(res => res.json())
            .then(res => {
                setContactCycleLangList(res);
            }
            )
    }

    useEffect(() => {

        contact && fetchContactCycleLang(contact.id_contact);

    }, [i18n.resolvedLanguage]);



    return (
        <>
            <RegistrationFormSection
                formSectionId={2}
                title={formulaire.profil_section_title}
                instructions={formulaire.profil_section_instructions}>

                <RegistrationFormRow
                    isRegister
                    inputType="textarea"
                    name='profil_cooperation'
                    placeholder={

                        'profil description'     //    t('form.sections.organisme_section.edito_long')
                    }
                    required={false}
                    defaultValue={contact?.profil_cooperation} />

            </RegistrationFormSection>
            {contact && contactCycleLangList && contactCycleLangList
                //filter cycle_lang_type
                .filter(contactCycleLang => ['collectif_cercle', 'collectif_partenaire'].includes(contactCycleLang.cycle_lang.cycle_lang_type))
                .map((contactCycleLang, index) =>
                    <RegistrationFormSection
                        title={`Profil ${contactCycleLang.cycle_lang.nom_cycle_lang}`}>


                        {/* <RegistrationFormRow
                            onChange={(e) => handleTextAreaChange(index, e.target.value)}
                            isRegister
                            inputType="textarea"
                            name={`contactCycleLangList`}
                            placeholder={

                                'profil description'     //    t('form.sections.organisme_section.edito_long')
                            }
                            required={false}
                            {...register(`contactCycleLangList`)}
                            defaultValue={contactCycleLang.contact_cycle_lang_profil} /> */}
                        <div className='flex '>
                            <label htmlFor=""

                                className="col-sm-3 col-form-label">{t('form.sections.organisme_section.edito_long')}</label>
                            <textarea
                                data-contact_cycle_lang_profil={contactCycleLang?.id_contact_cycle_lang}
                                defaultValue={contactCycleLang?.contact_cycle_lang_profil}
                                onChange={(e) => handleTextAreaChange(e)}
                                className="w-full border" name="" id=""></textarea>
                        </div>

                    </RegistrationFormSection>)}

        </>
    )
}

export default RegistrationFormProfilCooperationSection;