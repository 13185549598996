import { createRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../config';
import RegistrationFormRow from './RegistrationFormRow'
import RegistrationFormSection from './RegistrationFormSection';
import { useParams } from "react-router-dom";
import { CheckData, capitalize } from '../utils';
import RegistrationFormCountry from './RegistrationFormCountry';
import HTMLReactParser from 'html-react-parser';
import OrganismeVideoUrlChecker from './OrganismeVideoUrlChecker';
import OrganismeWebSiteChecker from './OrganismeWebSiteChecker';

const FORM_SECTION_ID = 4;

const OrganismeShow = ({ contact, setShowEditSection }) => {


    const { t } = useTranslation();
    const Logo = () => {

        if (contact.logos.large.includes("no_picture")) {

            return <div className="bg-gray-300 rounded flex justify-center p-10 font-bold text-gray-400">Logo</div>

        } else {

            return <img className="w-68" src={contact.logos.large} />
        }

    }

    if (!contact) return null;

    return <div className="flex flex-row">
        <div className="pr-5 basis-1/6" >
            <Logo />
        </div>
        <div className="basis-5/6">
            <div className="divide-y divide-gray-100">
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="société"
                    value={contact.societe}
                />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="adresse"
                    value={contact.adresse}
                />

                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="code postal"
                    value={contact.cp}
                />

                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="ville"
                    value={contact.ville}
                />

                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="pays"
                    value={contact.pays}
                    content={<img src={contact.flag} className='w-10 h-10 mx-3 float-left my-auto' />}
                />

                <CheckData
                    setShowEditSection={setShowEditSection}
                    name={t('form.sections.organisme_section.edito_long')}
                    value={contact.edito_long ? HTMLReactParser(contact.edito_long) : ""}
                />
            </div>
        </div>
    </div >
}

const RegsistrationFormOrganismeSection = ({ contact }) => {

    const { t } = useTranslation();

    const { id_cycle_lang, idEvent } = useParams();

    const [showEditSection, setShowEditSection] = useState(false);


    const handleEditButton = (e) => {
        e.preventDefault()

        setShowEditSection(!showEditSection);
        console.log("handleEditButton")
    }

    useEffect(() => {
        if (contact) {
            setShowEditSection(false);
        } else {
            setShowEditSection(true);
        }

    }, [contact])

    return <RegistrationFormSection
        formSectionId={FORM_SECTION_ID}
        title={t('form.sections.organisme_section.section_title')}>

        <div className='flex justify-end'>
            <button
                onClick={handleEditButton}
                className=" border-2 bg-blue-200 rounded-full w-28 text-black border-blue-600 ">
                {showEditSection ? t('form.voir_appercu') : t('form.modifier')}
            </button>
        </div>

        {!showEditSection ? <OrganismeShow
            setShowEditSection={setShowEditSection}
            contact={contact} /> :
            <>

                <RegistrationFormRow name='societe'
                    placeholder={t('form.sections.organisme_section.societe')}
                    defaultValue={contact?.societe} />

                <RegistrationFormRow name='adresse' placeholder={t('form.sections.organisme_section.adresse')} defaultValue={contact?.adresse} />
                <RegistrationFormRow name='cp' placeholder={t('form.sections.organisme_section.code_postal')} defaultValue={contact?.cp} />
                <RegistrationFormRow name='ville' placeholder={t('form.sections.organisme_section.ville')} defaultValue={contact?.ville} />

                {!["1731", 1731].includes(idEvent) && <RegistrationFormCountry required={true} />}

                <RegistrationFormRow
                    isRegister
                    inputType="textarea"
                    name='edito_long'
                    placeholder={t('form.sections.organisme_section.edito_long')}
                    required={false}
                    defaultValue={contact?.edito_long} />

                <RegistrationFormRow
                    inputType="file"
                    name='logo'
                    placeholder={t('form.sections.organisme_section.logo')}
                    defaultValue={contact?.logos.large}
                    required={contact?.logos.large != '' ? false : true}
                    accept={[
                        ".jpg",
                        ".jpeg",
                        ".png",

                    ]} />

                <RegistrationFormRow name='web'
                    placeholder={t('form.sections.organisme_section.web')}
                    defaultValue={contact?.web} >

                    <OrganismeWebSiteChecker />
                </RegistrationFormRow>

                <hr className='mb-3' />
                <h4 className="row pl-5  pb-3 text-2xl font-bold">
                    {t('form.sections.contact_and_organisation_section.video_section_title')}
                </h4>

                <RegistrationFormRow
                    required={false}
                    textLabel="Option 1"
                    inputType="text"
                    name='organisme_video_url'
                    placeholder=""

                    helper="Coller ci-dessus  l'url de la viéo à diffuser. Ex : https://www.youtube.com/watch?v=XXXXXXXXX"
                    defaultValue={contact?.organisme_video_url} >
                    <OrganismeVideoUrlChecker />

                </RegistrationFormRow>

                <RegistrationFormRow
                    required={false}
                    inputType="file"
                    name='organisme_video_hosted'
                    placeholder="Option 2"
                    helper=""
                    defaultValue={contact?.organisme_video_hosted}
                    accept={[
                        ".mp4",
                        ".mov",
                        ".avi",
                        ".mpg",
                        ".mpeg",
                        ".wmv",
                    ]} />

            </>
        }
    </RegistrationFormSection>
}

export default RegsistrationFormOrganismeSection;