import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { useFormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RegistrationFormSection from './RegistrationFormSection';


const FORM_SECTION_ID = 15;

const CheckPasswordCondition = ({ label, isConditionOk, value }) => {

    const [color, setColor] = useState('text-red-500')

    useEffect(() => {
        if (isConditionOk) {
            setColor('text-green-600')
        }
    }, [isConditionOk])

    return <div className={`flex ${color}  text-xs  w-auto m-2  items-center justify-start flex-wrap`}>

        {isConditionOk ? <CheckCircleIcon className="w-8 h-8 text-green-600" /> : <XMarkIcon className="ml-1 my-1  bg-red-400 w-6 h-6 rounded-full text-white" />}
        <span className="ml-2">{label}</span>
    </div>
}

const RegistrationFormPasswordSection = ({ contact }) => {

    const { t, i18n } = useTranslation();

    const { register, watch } = useFormContext();

    const watchPass = watch("pass", false);

    const [isPasswsordLengthOk, setIsPasswsordLengthOk] = useState(false)
    const [isPasswsordHaveMajuscule, setIsPasswsordHaveMajuscule] = useState(false)
    const [isPasswsordHaveMinuscule, setIsPasswsordHaveMinuscule] = useState(false)
    const [isPasswsordHaveInt, setIsPasswsordHaveInt] = useState(false)
    const [isPasswsordHaveSpecialChar, setIsPasswsordHaveSpecialChar] = useState(false)

    const checkPassword = (pass) => {

        if (!pass) {
            return false
        }
        setIsPasswsordHaveMinuscule(pass.match(/[a-z]+/))

        setIsPasswsordHaveMajuscule(pass.match(/[A-Z]+/))

        setIsPasswsordHaveInt(pass.match(/[0-9]+/))

        setIsPasswsordHaveSpecialChar(pass.match(/[$@#&!]+/))

        setIsPasswsordLengthOk(pass.length > 7)
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // console.log(value.pass)
            checkPassword(value.pass)
            //   console.log(value, name, type)
        })
        //  console.log(contact)

        return () => subscription.unsubscribe();
    }, [watch])

    if (contact) {
        return false
    }

    return <RegistrationFormSection

        formSectionId={FORM_SECTION_ID}
        title={t("form.sections.password.section_title")}  >

        <div className="row">
            <div className="form-check">
                <input className="p-2 rounded-full border mb-2"
                    type="password" name="pass"
                    {...register("pass", { required: true })}
                />
                <div className='flex flex-col justify-start md:flex-row'>
                    <CheckPasswordCondition label={t("form.sections.password.min_chars")} isConditionOk={isPasswsordLengthOk} value={watchPass} />
                    <CheckPasswordCondition label={t("form.sections.password.minuscule")} isConditionOk={isPasswsordHaveMinuscule} value={watchPass} />
                    <CheckPasswordCondition label={t("form.sections.password.majuscule")} isConditionOk={isPasswsordHaveMajuscule} value={watchPass} />
                    <CheckPasswordCondition label={t("form.sections.password.chiffre")} isConditionOk={isPasswsordHaveInt} value={watchPass} />
                </div>
            </div>

        </div>
    </RegistrationFormSection>
}


export default RegistrationFormPasswordSection;