import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import { useParams } from 'react-router-dom';
import { API_URL } from '../config';
import i18n from './i18n';
import HTMLReactParser from 'html-react-parser';
import { useFormContext } from 'react-hook-form';
import { register } from 'module';
import useApiUrlTranslation from './useApiUrlTranslation';

const EventContactTypeHandler = ({ cycleLang, formulaire }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventContactTypeList, setEventContactTypeList] = useState(false);

    const [composantList, setComposantList] = useState(false);

    const { register } = useFormContext();


    useEffect(() => {

        const getEventContactTypeList = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventContactTypeList`)
                .then(res => res.json())
                .then(res => {
                    setEventContactTypeList(res)

                    setComposantList([
                        { nom: "Blitz pitch", eventContactTypeList: res.filter(item => ["263"].includes(item.id_event_contact_type)) },
                        { nom: "Pitching global challenge", eventContactTypeList: res.filter(item => ["239", "124"].includes(item.id_event_contact_type)) },
                        { nom: "Opening session", eventContactTypeList: res.filter(item => ["264"].includes(item.id_event_contact_type)) }
                    ])
                })
        }

        getEventContactTypeList();


    }, [i18n.resolvedLanguage])



    return <>

        <table className='w-100'>

            <tbody>
                {composantList && composantList.map(composant =>
                    <><tr className="bg-neutral-200 p-2">
                        <td className="p-2 font-bold text-neutral-600">{composant.nom}</td>
                        <td></td>
                    </tr>
                        {composant.eventContactTypeList.map(eventContactType =>
                            <tr>

                                <td colSpan="2" > <label className="font-normal">
                                    <input
                                        {...register("cycleLangIdEventContactTypeList")}
                                        type="checkbox"
                                        value={JSON.stringify({ id_cycle_lang: cycleLang.id_cycle_lang, id_event_contact_type: eventContactType.id_event_contact_type })} />  {eventContactType.instructions_formulaire}
                                </label>
                                </td>

                            </tr>
                        )}
                    </>

                )}

            </tbody>
        </table>

    </>



}



const CycleLangHeader = ({ cycleLang, instructions, formulaire }) => {

    const { register } = useFormContext();

    if (!formulaire) return null;

    return (
        <div className=''>
            <div className='group flex space-x-3 items-center bg-gray-100 p-2 rounded font-bold'>

                <img
                    src={cycleLang.attachedFilesCollection.find(item => item.id_attached_file_type == 65).tiny}
                    className="w-12 rounded" alt='' />
                <div>
                    <span>{cycleLang.nom_cycle_lang}</span>
                    <span className="hidden" >{cycleLang.cycle_lang_type} </span>
                    {/* <div><span className="font-normal"> : {HTMLReactParser(instructions)}</span></div>  */}
                </div>

            </div>
            <input
                {...register("eventCycleLang")}
                id={cycleLang.id_cycle_lang}
                key={`header-${cycleLang.id_cycle_lang}`}
                className="w-100 border"
                type="checkbox"
                defaultChecked={true}
                hidden
                value={JSON.stringify({ id_event_contact_type: formulaire?.id_event_contact_type_main, id_cycle_lang: cycleLang.id_cycle_lang })} />

        </div>
    )
}

const ProgrammeItem = ({ child, cycleLang }) => {

    const { register, unregister } = useFormContext();

    const [isChecked, setIsChecked] = useState(false);

    const [activeRole, setActiveRole] = useState(false);

    const [eventContactTypeList, setEventContactTypeList] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();


    const handleChangeRole = (e) => {

        if (e.target.value == "") {
            setIsChecked(false);
            unregister("eventCycleLang");
        }
        else {
            setActiveRole(e.target.value);
            setIsChecked(true);
        }
    }

    useEffect(() => {

        const getEventContactTypeList = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventContactTypeList`)
                .then(res => res.json())
                .then(res => setEventContactTypeList(res))
        }

        getEventContactTypeList();



    }, [isChecked, i18n.resolvedLanguage])

    return (<div className="flex ml-3 divide-y border-b py-1">


        <div className="w-1/2">  {child.nom_cycle_lang}

            {isChecked && <input
                {...register("eventCycleLang")}

                id={child.id_cycle_lang}
                key={`pi-${child.id_cycle_lang}`}
                className="w-100 border"
                type="checkbox"
                defaultChecked={true}
                hidden
                disabled={!isChecked}
                value={JSON.stringify({ id_event_contact_type: activeRole, id_cycle_lang: child.id_cycle_lang })} />
            }

            <p className="text-sm text-gray-400 hidden">{child.cycle_lang_type} </p>
        </div>
        <div className="w-1/2">
            <select className='p-1 rounded' onChange={handleChangeRole} >
                <option value="">{t("form.sections.programmes_innovation.contribution_no")} </option>

                {eventContactTypeList && eventContactTypeList
                    .filter(eventContactType => ["235", "252", "248"].includes(eventContactType.id_event_contact_type))
                    .map(eventContactType => <option value={eventContactType.id_event_contact_type}>{eventContactType.instructions_formulaire}</option>)}

            </select>
        </div>
    </div>
    )
}

const CycleLangPreselectionItem = ({ cycleLang, formulaire }) => {

    const { t } = useApiUrlTranslation();

    const { register } = useFormContext();

    if (!cycleLang.children) return null;

    return (
        <>
            {cycleLang.children &&
                // cycleLang
                //     .children.filter(child => child.cycle_lang_state == "active" && child.cycle_lang_type == "collectif_programme").length > 0 &&

                <div div className=''>

                    <CycleLangHeader
                        formulaire={formulaire}
                        cycleLang={cycleLang}
                        instructions_="Vous pouvez désormais contribuer ou proposer la création de programme d’actions, d’innovation et d’accélération collective"
                        instructions={t("form.sections.programmes_innovation.contribution")} />
                    {/* 
                    <p className='font-bold pt-3'>Programmes thématiques</p>

                    <div className='pl-5 py-2'>

                        {cycleLang.children && cycleLang.children

                            .filter(child => child.cycle_lang_state == "active"
                                && child.cycle_lang_type == "collectif_programme"
                                && child.cycle_lang_categorie == "programme_thematique")
                            .map(child => <ProgrammeItem key={`children-${cycleLang.id_cycle_lang}`}
                                cycleLang={cycleLang} child={child} />)}
                    </div>


                    <p className='font-bold'>Programmes verticaux</p>
                    <div className='pl-5 py-2'>

                        {cycleLang.children && cycleLang.children
                            .filter(child => child.cycle_lang_state == "active"
                                && child.cycle_lang_type == "collectif_programme"
                                && child.cycle_lang_categorie == "programme_vertical"
                            )
                            .map(child => <ProgrammeItem key={`children-${cycleLang.id_cycle_lang}`}
                                cycleLang={cycleLang} child={child} />)}
                    </div> */}

                </div >
            }
        </>
    )
}

const CycleLangGeographiquePreselectionItem = ({ cycleLang, formulaire }) => {

    const { t } = useApiUrlTranslation();

    return (
        <>
            <CycleLangHeader
                formulaire={formulaire}
                cycleLang={cycleLang}
                instructions={`${t('form.sections.collectifs_geographiques.rattachement')}`} />

            {
                //cycleLang.children && cycleLang.children.filter(child => child.cycle_lang_state == "active" && child.cycle_lang_type == "collectif_geographique").length > 0 &&
                cycleLang.cycle_lang_type == "collectif_geographique" &&

                <div className='py-2 hidden'>
                    <EventContactTypeHandler
                        formulaire={formulaire}
                        cycleLang={cycleLang} />
                </div>
            }
        </>
    )
}

const RegistrationFormCycleLangPreselection = ({ title, instructions, cycleLangType, cycleLangTypeList, formulaire }) => {

    const { id_cycle_lang_preselection_list } = useParams();

    const [cycleLangPreselectionList, setCycleLangPreselectionList] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const fetchCycleLang = async ({ idCycleLangPreselectionList }) => {

        const filter = ` id_cycle_lang IN(${idCycleLangPreselectionList}) AND `

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=${filter}&get_parents=0&get_children=0`)
            .then(res => res.json())

            .then(res => {
                setCycleLangPreselectionList(res)
            })
    }
    //Validation de mes ecosystèmes rattachés
    //Une fois ce formulaire enregistré vous serez connecté aux écosystèmes ci-dessous


    //Selection des domaines d'expertise et d'action thematiques et verticaux de votre ecosystème
    //Je sélectionne ci-dessous les verticales et les thématiques auxquelles des acteurs de mon écosystème pourraient contribuer. 

    useEffect(() => {

        // Récupérer l'URL actuelle
        const currentURL = window.location.href;

        // Créer un objet URLSearchParams à partir de la chaîne de requête de l'URL
        const searchParams = new URLSearchParams(currentURL.split('?')[1]);

        // Extraire la valeur du paramètre id_cycle_lang_preselection_list
        const idCycleLangPreselectionList = searchParams.get('id_cycle_lang_preselection_list');

        fetchCycleLang({ idCycleLangPreselectionList })
    }, [i18n.resolvedLanguage, id_cycle_lang_preselection_list])

    return (
        <>
            {/* {cycleLangType == "collectif_geographique" &&

                cycleLangPreselectionList &&
                cycleLangPreselectionList
                    .filter(cycleLang => cycleLang.cycle_lang_type == "collectif_geographique")
                    .length > 0 &&

                <RegistrationFormSection title={title} instructions={instructions}>
                    {cycleLangPreselectionList && cycleLangPreselectionList
                        .filter(cycleLang => cycleLang.cycle_lang_type == "collectif_geographique")
                        .map(cycleLang =>

                            <CycleLangGeographiquePreselectionItem
                                formulaire={formulaire}
                                key={`cycleLangGeographiquePreselectionItem-${cycleLang.id_cycle_lang}`}
                                cycleLang={cycleLang} />
                        )
                    }
                </RegistrationFormSection>
            }


            {cycleLangType == "collectif_planaction" && cycleLangPreselectionList && cycleLangPreselectionList
                .filter(cycleLang => cycleLang.cycle_lang_type == "collectif_planaction").length > 0
                && <RegistrationFormSection title={title} instructions={instructions}>
                    {cycleLangPreselectionList && cycleLangPreselectionList
                        .map(cycleLang => <CycleLangPreselectionItem
                            key={`cycleLangPreselectionItem-${cycleLang.id_cycle_lang}`}
                            formulaire={formulaire}
                            cycleLang={cycleLang} />)
                    }
                </RegistrationFormSection>
            } */}


            {cycleLangPreselectionList &&
                // cycleLangPreselectionList
                //     .length > 0 &&

                <RegistrationFormSection title={formulaire.cycle_lang_preselection_section_title}
                    instructions={formulaire.cycle_lang_preselection_section_instructions}>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
                        {cycleLangPreselectionList && cycleLangPreselectionList
                            .map(cycleLang => <CycleLangPreselectionItem
                                key={`cycleLangPreselectionItem-${cycleLang.id_cycle_lang}`}
                                formulaire={formulaire}
                                cycleLang={cycleLang} />)
                        }
                    </div>
                </RegistrationFormSection>
            }



        </>
    )
}

export default RegistrationFormCycleLangPreselection
