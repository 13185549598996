import React, { useEffect, useState } from "react";
import CollectifAnnuaireItemContact from "./CollectifAnnuaireItemContact";
import useApiUrlTranslation from "./useApiUrlTranslation";
import HTMLReactParser from "html-react-parser";
import { use } from "i18next";
import { useFormContext } from "react-hook-form";
import i18n from "./i18n";



const RegistrationFormCycleLangListItemSection = ({ cycle_lang,
    isChildren,
    formulaire,
    contactCycleLangList,
    parentId }) => {

    const { t, i18n } = useApiUrlTranslation();

    const { register } = useFormContext();

    const [itemIsChecked, setItemIsChecked] = useState('');

    const handleChange = (e) => {

        const itemIsChecked = e.target.checked;

        console.log(itemIsChecked)

        setItemIsChecked(itemIsChecked)

        //checkAllChildren(e);
    }


    const checkAllChildren = (e) => {

        const isChildren = e.target.getAttribute('isChildren');

        const parentId = e.target.getAttribute('parentId');

        if (!isChildren && e.target.checked) {

            //console.log('checked')
            cycle_lang.children.forEach(child => {

                const query = `input[value*='"id_cycle_lang":"${child.id_cycle_lang}"']`;
                //console.log(query);

                //document.querySelector(`input[value*='id_cycle_lang="${child.id_cycle_lang}"']`).checked = true;

                const query2 = `input[parentId='${cycle_lang.id_cycle_lang}']`;

                console.log(query2);
                document.querySelector(query2).checked = true;

            })
        } else {
            cycle_lang.children.forEach(child => {
                document.querySelector(`input[value='${JSON.stringify({ id_cycle_lang: child.id_cycle_lang, id_event_contact_type: formulaire.id_event_contact_type_main })}']`).checked = false;
            })
        }
    }


    useEffect(() => {


        if (contactCycleLangList && contactCycleLangList
            .map(contactCycleLang => contactCycleLang.cycle_lang.id_cycle_lang)
            .includes(cycle_lang.id_cycle_lang)) {

            setItemIsChecked(true)

        }


    }, [i18n.resolvedLanguage, contactCycleLangList])

    if (!formulaire) {
        return null;
    }


    return (<div className={`${isChildren ? ' w-100' : ' md:w-1/5'}`}>

        <div
            className={`border   align-items-center ${isChildren ? "ml-10 " : "mt-2"}`}
            key={cycle_lang.id_cycle_lang}>


            <label className={`flex items-center space-x-3 f ${isChildren ? "font-normal " : ""}`}>
                <input
                    parentId={parentId}
                    isChildren={isChildren}
                    checked={itemIsChecked}
                    onClick={handleChange}
                    type="checkbox"
                    {...register("cycleLangIdEventContactTypeList")}
                    value={JSON.stringify({ id_cycle_lang: cycle_lang.id_cycle_lang, id_event_contact_type: formulaire.id_event_contact_type_main })}
                />


                <img
                    style={{ width: isChildren ? "20px" : "30px" }}
                    src={cycle_lang.attachedFilesCollection.find(attachedFile => attachedFile.id_attached_file_type == "65").tiny}
                    alt=""
                    className={`${cycle_lang.cycle_lang_state == "draft" && 'grayscale'}  rounded `} />
                <p className={`uppercase ${isChildren ? "text-sm" : ""}`}>{cycle_lang.nom_cycle_lang}</p>
            </label>
        </ div>
        <div className="">
            {
                cycle_lang.children && cycle_lang.children
                    //trier par child.nom_cycle_lang
                    .sort((a, b) => a.nom_cycle_lang.localeCompare(b.nom_cycle_lang))
                    .filter(child => child.cycle_lang_state == 'active')
                    .map((child) => <RegistrationFormCycleLangListItemSection
                        key={child.id_cycle_lang}
                        cycle_lang={child}
                        formulaire={formulaire}
                        parentId={cycle_lang.id_cycle_lang}
                        contactCycleLangList={contactCycleLangList}
                        isChildren />)
            }</div>
    </div>
    )
}

export default RegistrationFormCycleLangListItemSection;