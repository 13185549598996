import React, { useEffect, useState } from 'react'
import useApiUrlTranslation from './useApiUrlTranslation';
import { use } from 'i18next';

function RegistrationFormContactCycleLangList({ event }) {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [cycleLangEventLigneList, setCycleLangEventLigneList] = useState();

    const [contactCycleLangList, setContactCycleLangList] = useState(null);

    const fetchContactCycleLang = async ({ idCycleLangList }) => {

        const filter = ` cl.id_cycle_lang IN (${idCycleLangList}) AND id_event_contact_type IN(226) AND`;

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter=${filter}&group_result=GROUP BY c.id_contact`)

            .then(response => response.json())
            .then(data => {
                setContactCycleLangList(data);
            })

    }

    const getCycleLangEventLigne = async ({ event }) => {

        await fetch(`${apiUrlTranslation}?action=getCycleLangEventLigne&params=WHERE id_event_ligne=${event.event_ligne.id_event_ligne}`)

            .then(response => response.json())
            .then(data => {
                setCycleLangEventLigneList(data);
                fetchContactCycleLang({ idCycleLangList: data.map(cycleLangEventLigne => cycleLangEventLigne.id_cycle_lang.id_cycle_lang) });
            })
    }



    useEffect(() => {
        getCycleLangEventLigne({ event });

        console.log(event);

    }, [i18n.resolvedLanguage]);

    if (!contactCycleLangList || contactCycleLangList.length == 0) return null;

    return (

        <div>
            <p className="text-lg font-bold mt-5 mb-3">Les contacts de mon écosystème</p>
            <div className="flex flex-wrap">

                {contactCycleLangList && contactCycleLangList.map(contactCycleLang => {

                    const contact = contactCycleLang.contact;

                    return <div className="flex space-x-2 bg-neutral-50 rounded-3xl p-2 m-1 border-x border-y border-neutral-400">
                        <div>
                            <img src={contactCycleLang.contact.photos.tiny} alt="" className="rounded-full w-10 h-10 object-fill " />
                        </div>
                        <div>
                            <p className="text-sm">{contact.prenom} {contact.nom}</p>
                            <p className="text-sm text-gray-500">{contact.societe}</p>
                        </div>
                    </div>
                }
                )}
            </div>
        </div>
    )
}

export default RegistrationFormContactCycleLangList