import React from 'react'
import RegistrationFormSection from './RegistrationFormSection';
import RegistrationFormRow from './RegistrationFormRow';


const FORM_SECTION_ID = 10;

const RegistrationFormEventReferencementSection = ({ formulaire }) => {
    return (
        <RegistrationFormSection
            formSectionId={FORM_SECTION_ID}
            title={formulaire.event_referencement_section_title}
            instructions={formulaire.event_referencement_section_instructions ?? "J'ai prévu de participer, d'exposer voir même d'organiser un événement qui ne figure pas pour l'instant à votre agenda. Je souhaite bénéficier des ressources de  myglobalvillage pour augmenter la  visibilité, développer mon réseau et générer des contacts additionnels.  Je références les éléments descriptif de cet événement afin de solliciter des contributions additonnelles auprès des membres affiliés de myglobalvillage qui pourrait y participer."}>

            <RegistrationFormRow name='event_nom' placeholder=" Nom de l'événement " />
            <RegistrationFormRow name='event_web' placeholder=" Site web de l'événement " />

            <RegistrationFormRow
                inputType="file"
                name='event_logo'
                placeholder="Logo de l'événement"
                required={false}
                accept={[
                    '.jpeg',
                    '.png',
                    '.jpg'
                ]} />

            <RegistrationFormRow name='periodicite' inputType="select"
                placeholder=" Périodicité "
                inputOptions={[
                    { label: 'Sélectionner une périodicité', value: '' },
                    { label: 'Annuelle', value: "annuel" }, { label: 'Biennale', value: 'biennal' }, { label: 'Non définie', value: 'undefined' }]} />

            <RegistrationFormRow
                name='port'
                inputType="select"
                placeholder="Type d'événement"
                inputOptions={[
                    { label: "Sélectionner un type d'événement", value: '' }, { label: 'Salon' }, { label: "Rendez-vous d'affaire" },
                    { label: 'Festival' },
                    { label: 'Evénement grand public culture ou sportif' },
                    { label: 'Conventions' },
                    { label: 'Autre' },

                ]} />

            <RegistrationFormRow name='event_start_mois_usuel' inputType="select" placeholder=" Mois habituel d'organisation "
                inputOptions={[
                    { label: "Janvier", value: "01" },
                    { label: "Février", value: "02" },
                    { label: "Mars", value: "03" },
                    { label: "Avril", value: "04" },
                    { label: "Mai", value: "05" },
                    { label: "Juin", value: "06" },
                    { label: "Juillet", value: "07" },
                    { label: "Août", value: "08" },
                    { label: "Septembre", value: "09" },
                    { label: "Octobre", value: "10" },
                    { label: "Novembre", value: "11" },
                    { label: "Décembre", value: "12" }
                ]} />

            <RegistrationFormRow name='event_start' inputType="date" placeholder=" Date de début la prochaine éditon " />
            <RegistrationFormRow name='event_end' inputType="date" placeholder=" Date de fin la prochaine éditon " />
            <RegistrationFormRow
                required={false}

                name='port' placeholder=" Lien YouTube ou video présentant la manifestation " />
            {/* 
            TYPE DE CONTRIBUTION PROPOSEE (list déjà existante peut être à modfiier mais elle existe)
            (_) je me rend seul sur le salon et peut y organiser une tech'Xploration et un débriefing de tendances
            (_) j'ai un stand et peut y accueillir l'enregistrement de webcast
            (_) j'ai dispose d'un scène sur mon stand ou pavillon et peut y accueillir les formats événementiels de myGlobalVillage (pitch, tech'review…)
            (_) je suis organisateur et souhaite accueillir des temps forts de myGlobalVillage

 */}

        </RegistrationFormSection>
    )
}

export default RegistrationFormEventReferencementSection;