import React, { useState, useEffect } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import useApiUrlTranslation from './useApiUrlTranslation';
import { API_URL } from '../config';
import axios from 'axios';
import { set } from 'react-hook-form';
import HTMLReactParser from 'html-react-parser';
import Loader from './Loader';

const CYCLE_LANG_TYPES = [
    { type: 'collectif_geographique', label: 'Ecosystème géographique' },
    { type: 'collectif_sectoriel', label: 'Ecosystème sectoriel' },
    { type: 'collectif_technologique', label: 'Ecosystème linguistique' },

    //'collectif_entreprise','collectif_associatif','collectif_personnel'
    { type: 'collectif_entreprise', label: 'Ecosystème entreprise' },
    { type: 'collectif_associatif', label: 'Ecosystème associatif' },
    { type: 'collectif_personnel', label: 'Ecosystème personnel' },

];

const Spinner = () => {

    return <div className="basis-12 ">
        <div className="w-6 h-6 inline-flex items-center justify-center font-semibold leading-6 text-sm shadow rounded-full text-white bg-emerald-500 hover:bg-emerald-200 transition ease-in-out duration-150 cursor-not-allowed">
            <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
    </div>
}

const ContactCycleLangSection = ({ formulaire, idCycleLangList }) => {

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactCycleLangList, setContactCycleLangList] = useState(null);

    const getContactCycleLang = async (idCycleLangList) => {

        await fetch(`${apiUrlTranslation}?action=getContactCycleLang&filter=id_cycle_lang IN(${idCycleLangList})`)
            .then(res => res.json())
            .then(res => setContactCycleLangList(res))
    }

    useEffect(() => {

        getContactCycleLang(idCycleLangList);

    }, [i18n.resolvedLanguage]);


    return (
        <div class="col-start-2 row-start-1 row-end-3 sm:mt-4 lg:mt-0 xl:mt-4 flex-wrap ">

            <dt class="sr-only">Tire</dt>
            <dd class="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-1.5" >

                {contactCycleLangList && contactCycleLangList
                    //deduplicate by contact.id_contact
                    .filter((contact, index, self) =>
                        index === self.findIndex((t) => (
                            t.contact.id_contact === contact.contact.id_contact
                        ) && contact.contact.id_contact != 607

                        )
                    )
                    .map(contactCycleLang => (

                        <img src={contactCycleLang.contact.photos.small} alt="" className="w-20 h-20 rounded-full bg-slate-100 ring-2 ring-white dark:ring-slate-700 dark:group-hover:ring-white" loading="lazy" decoding="async" />
                    ))}
            </dd>
        </div >
    )
}

const CreateCycleLangSection = ({ formulaire, cycleLangMain, setCycleLangMain, setCycleLangMainPreviewIsVisible, contact }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [newCycleLang, setNewCycleLang] = useState(null);

    const [dialogisVisible, setDialogisVisible] = useState(false);

    const handleChange = (e) => {

        setNewCycleLang({ ...newCycleLang, [e.target.name]: e.target.value });
    }

    const getCycleLang = async (id_cycle_lang) => {

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=id_cycle_lang=${id_cycle_lang} AND&bug=bug`)
            .then(res => res.json())

            .then(res => {
                setCycleLangMain(res[0]);
                setCycleLangMainPreviewIsVisible(false);
            })
    }


    const updateContactCycleLang = async ({ id_cycle_lang, id_contact }) => {

        const url = `${apiUrlTranslation}?action=updateContactCycleLang&id_cycle_lang=${id_cycle_lang}&id_contact=${id_contact}`;

        await fetch(url)
            .then(res => res.json())
            .then(res => {
                console.log(res);
            })
    }

    const handleCreate = async (event) => {

        event.preventDefault();

        const formData = new FormData();
        formData.append('nom_cycle_lang', newCycleLang.nom_cycle_lang);
        // formData.append('cycle_lang_type', newCycleLang.cycle_lang_type);
        formData.append('cycle_lang_type', 'collectif_partenaire');
        formData.append('cycle_lang_descriptif', newCycleLang.cycle_lang_descriptif);

        await fetch(`${API_URL}?action=createCycleLang`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(res => {

                console.log(res);
                getCycleLang(res);

                //ajouter res au parametre id_cycle_lang_preselection_list de l'url qui est de la forme
                // &id_cycle_lang_preselection_list=2228,2306,2299,2295,3068,3129,2256

                const currentURL = window.location.href;
                const searchParams = new URLSearchParams(currentURL.split('?')[1]);
                const idCycleLangPreselectionList = searchParams.get('id_cycle_lang_preselection_list');
                const newIdCycleLangPreselectionList = idCycleLangPreselectionList ? `${idCycleLangPreselectionList},${res}` : res;
                const newURL = `${currentURL.split('?')[0]}?${searchParams}&id_cycle_lang_preselection_list=${newIdCycleLangPreselectionList}`;
                window.history.pushState({ path: newURL }, '', newURL);

                setDialogisVisible(false);
                updateContactCycleLang({ id_cycle_lang: res, id_contact: contact.id_contact });
            })
    }

    return (
        <div>

            <div className="flex justify-center my-4 max-w-xs mx-auto">
                <button
                    onClick={(event) => {
                        setDialogisVisible(true);
                        event.preventDefault();
                    }}
                    className=" font-normal text-white bg-pink-600 px-6 py-2  rounded-full hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-pink-500 shadow-lg"
                >
                    Activer mon écosystème
                </button>
            </div>

            {dialogisVisible && <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50"></div>
                <dialog open={true} className="p-6  rounded-lg shadow-lg z-10">
                    <div className="space-y-4">

                        {/* *  {JSON.stringify(newCycleLang)}
                        {JSON.stringify(cycleLangMain)}* */}

                        {/* <div className="grid grid-cols-4 gap-4 items-center">
                            <label className="col-span-1 text-gray-700 font-medium" htmlFor="cycle_lang_type">Type d'écosystème</label>
                            <select
                                onChange={handleChange}
                                name="cycle_lang_type"
                                id="cycle_lang_type"
                                className="col-span-3 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                <option value="">Sélectionner un type d'écosystme</option>
                                {CYCLE_LANG_TYPES && CYCLE_LANG_TYPES.map(cycleLangType => (
                                    <option key={cycleLangType.type} value={cycleLangType.type}>
                                        {cycleLangType.label}
                                    </option>
                                ))}
                            </select>
                        </div> */}

                        <div className="grid grid-cols-4 gap-4 items-center">
                            <label htmlFor="nom_cycle_lang" className="col-span-1 text-gray-700 font-medium">Nom de mon écosystème</label>
                            <input
                                type="text"
                                name="nom_cycle_lang"
                                onChange={handleChange}
                                id="nom_cycle_lang"
                                className="col-span-3 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="grid grid-cols-4 gap-4 items-start">
                            <label htmlFor="cycle_lang_descriptif" className="col-span-1 text-gray-700 font-medium mt-4">Descriptif de mon écosystème</label>
                            <textarea
                                name="cycle_lang_descriptif"
                                onChange={handleChange}
                                id="cycle_lang_descriptif"
                                cols="30"
                                rows="5"
                                className="col-span-3 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="flex justify-end  space-x-4  ">
                            <button
                                style={{ border: "1px solid #9999" }}
                                onClick={(event) => {
                                    setDialogisVisible(false);
                                    event.preventDefault();
                                }}
                                className="w-52  border-2 border-neutral-600 border-x border-y bg-neutral-50 px-4 py-2 text-neutral-700 rounded-full hover:bg-neutral-100 "
                            >
                                Fermer
                            </button>
                            <button
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleCreate(event);
                                }}
                                className="w-52 border  bg-pink-600 text-white px-4 py-2 rounded-full hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-pink-700"
                            >
                                Activer
                            </button>
                        </div>
                    </div>
                </dialog>
            </div>
            }
        </div>
    )

}

const CycleLangMainPreview = ({ cycleLangMain, cycleLangMainPreviewIsVisible }) => {

    useEffect(() => {

    }, [cycleLangMain]);


    if (!cycleLangMain) return null;

    return (

        <div className={`py-3 space-x-5 ${cycleLangMainPreviewIsVisible ? 'flex' : 'hidden'}`}>

            {/* {JSON.stringify(cycleLangMain)} */}

            <div className="basis-1/6 flex justify-center ">
                <div className='bg-neutral-200 p-3 flex justify-center items-center rounded '>
                    <img src={cycleLangMain.logo_better?.small}
                        className=" object-contain " alt="" />
                </div>
            </div>
            <div className="basis-5/6">

                <p className="font-bold text-5xl mb-3">{cycleLangMain.nom_cycle_lang}</p>
                {HTMLReactParser(cycleLangMain.cycle_lang_descriptif ?? "")}</div>
        </div>
    )
}

const CycleLangMainSection = ({
    idCycleLangList,
    formulaire,
    cycleLangMainPreviewIsVisible,
    cycleLangMain,
    setCycleLangMain,
    contact }) => {

    const [cycleLangPreselectionList, setCycleLangPreselectionList] = useState(false);

    const [programmeActionCollectiveList, setProgrammeActionCollectiveList] = useState(false);

    const [CollectifPlanActionList, setCollectifPlanctionList] = useState(false);

    const [collectifLinguistiqueList, setCollectifLinguistiqueList] = useState(false);

    const [logoPreview, setLogoPreview] = useState(null);

    const [updateCycleLangParentIsLoading, setUpdateCycleLangParentIsLoading] = useState(false);


    const [fetchCycleLangPreselectionIsLoading, setFetchCycleLangPreselectionIsLoading] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {

        e.preventDefault();

        // console.log(e.target.files[0]);
        setSelectedFile(e.target.files[0]);

        setLogoPreview(URL.createObjectURL(e.target.files[0]));

        handleSubmitFile(e);
    }

    const handleSubmitFile = event => {
        event.preventDefault()
        const url = 'https://www.mlg-consulting.com/manager_cc/cycles/do.php';

        const formData = new FormData();
        formData.append('Filedata', selectedFile);
        formData.append('fileName', selectedFile.name);
        formData.append("attached_file_table", "cycle_lang");
        formData.append("primary_key_name", "id_cycle_lang");
        formData.append("do", "attached_file");
        formData.append("primary_key_value", cycleLangMain.id_cycle_lang);
        formData.append("attached_file_libelle", 'Logo Better');
        formData.append("id_attached_file_type", 65);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        axios.post(url, formData, config).then((response) => {
            console.log(response.data);
        })
            .then(res => fetchCycleLangPreselection());
    }

    const createContactCycleLang = async ({ id_cycle_lang, id_contact }) => {

        const data = {
            id_cycle_lang: id_cycle_lang,
            id_contact: id_contact,
            id_event_contact_type: [227],

        };
        await fetch(`${API_URL}?action=createContactCycleLang&id_cycle_lang=${id_cycle_lang}`,
            {
                method: 'POST',
                body: JSON.stringify(data)
            }
        )
    }

    const deleteContactCycleLang = async ({ id_cycle_lang, id_contact }) => {

        await fetch(`${API_URL}?action=deleteContactCycleLang&id_cycle_lang=${id_cycle_lang}&id_contact=${id_contact}`)
            .then(res => res.json())
    }




    const handleChangeParent = async (e) => {

        setUpdateCycleLangParentIsLoading(true);

        const ischecked = e.target.checked;

        const id_cycle_lang = e.target.value;

        await fetch(`${API_URL}?action=updateCycleLangParent&id_parent=${id_cycle_lang}&idcl=${cycleLangMain.id_cycle_lang}`)
            .then(res => {
                // console.log('updateCycleLangParent');
                fetchCycleLangPreselection(cycleLangMain.id_cycle_lang);
                setCycleLangMain({ ...cycleLangMain, parents: res })
                setUpdateCycleLangParentIsLoading(false);

                if (ischecked) {
                    createContactCycleLang({ id_cycle_lang: id_cycle_lang, id_contact: contact.id_contact });
                } else {
                    deleteContactCycleLang({ id_cycle_lang: id_cycle_lang, id_contact: contact.id_contact });
                }
            })
    }

    const handleChange = async (e) => {

        const cycleLang = { ...cycleLangMain, [e.target.name]: e.target.value }

        setCycleLangMain(cycleLang);

        await fetch(`${API_URL}?action=updateCycleLang`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                cycleLang: {
                    id_cycle_lang: cycleLang.id_cycle_lang,
                    cycle_lang_descriptif: cycleLang.cycle_lang_descriptif,
                    cycle_lang_categorie: cycleLang.cycle_lang_categorie
                }
            })
        })
            .then(res =>
                fetchCycleLangPreselection()
            )
    }

    const fetchCycleLangPreselection = async (id_cycle_lang = null) => {

        setFetchCycleLangPreselectionIsLoading(true);

        const currentURL = window.location.href;
        const searchParams = new URLSearchParams(currentURL.split('?')[1]);
        const idCycleLangPreselectionList = searchParams.get('id_cycle_lang_preselection_list');

        const filter = ` id_cycle_lang IN(${idCycleLangPreselectionList}${id_cycle_lang ? `,${id_cycle_lang}` : ''}) AND `

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=${filter}&truc=truc`)
            .then(res => res.json())

            .then(res => {
                console.log(filter, id_cycle_lang);
                setCycleLangPreselectionList(res);
                setCycleLangMain(res.find(cycleLang => cycleLang.cycle_lang_type == "collectif_partenaire"))
                console.log('ouf', res.find(cycleLang => cycleLang.cycle_lang_type == "collectif_partenaire"))
                setFetchCycleLangPreselectionIsLoading(false);
            })
    }

    const fetchCycleLang = async () => {

        const filter = ` cycle_lang_type IN ('collectif_linguistique', 'collectif_planaction') AND cycle_lang_state='active' AND &get_parents=0&get_children=0&get_child=0`

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=${filter}`)
            .then(res => res.json())

            .then(res => {
                setProgrammeActionCollectiveList(res.filter(cycleLang => cycleLang.cycle_lang_type == "programme_action"));
                setCollectifPlanctionList(res.filter(cycleLang => cycleLang.cycle_lang_type == "collectif_planaction"));
                setCollectifLinguistiqueList(res.filter(cycleLang => cycleLang.cycle_lang_type == "collectif_linguistique"));
            })
    }

    useEffect(() => {

        //fetchCycleLangPreselection();

        fetchCycleLang();

    }, [i18n.resolvedLanguage, cycleLangMain, cycleLangMainPreviewIsVisible]);

    if (!cycleLangMain) return null

    return (<>

        <div className={` ${cycleLangMainPreviewIsVisible ? 'hidden' : ''}`}>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
                <label htmlFor="last-name" className="block text-sm  leading-6 text-gray-900 sm:pt-1.5 font-bold">
                    Logo
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <img src={logoPreview ?? cycleLangMain.logo_better?.small} className="mb-3 w-40 ring-4" />
                    <input type="file" name="" id="" onChange={handleFileInput} />

                </div>
            </div>


            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
                <label htmlFor="nom_cycle_lang" className="block text-sm font-bold leading-6 text-gray-900 sm:pt-1.5">
                    Nom de mon écosystème
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <input
                        onChange={handleChange}
                        rows={5}
                        className="block w-full border-2 border-gray-300 rounded-md p-2"
                        name="cycle_lang_descriptif" value={cycleLangMain.nom_cycle_lang} />
                </div>
            </div>


            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
                <label htmlFor="last-name" className="block text-sm font-bold leading-6 text-gray-900 sm:pt-1.5">
                    Description de mon écosystème
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <textarea
                        onChange={handleChange}
                        rows={5}
                        className="block w-full border-2 p-2 rounded-md"
                        name="cycle_lang_descriptif" value={cycleLangMain.cycle_lang_descriptif}></textarea>
                </div>
            </div>

            {/* 
            {HTMLReactParser(t('form.sections.client_partenaire_section.modalite_colaboration_title'))}


            <label className="block font-normal" >
                <input
                    checked={cycleLangMain.cycle_lang_categorie == "collaboration_marchande"}
                    type="radio"
                    name="cycle_lang_categorie"
                    value="collaboration_marchande"
                    onClick={handleChange} />  {HTMLReactParser(t('form.sections.client_partenaire_section.collaboration_marchande'))}
            </label> 

            <label className="block font-normal" >
                <input
                    checked={cycleLangMain.cycle_lang_categorie == "collaboration_non_marchande"}
                    type="radio"
                    name="cycle_lang_categorie"
                    value="collaboration_non_marchande"
                    onClick={handleChange} />  {HTMLReactParser(t('form.sections.client_partenaire_section.collaboration_non_marchande'))}
            </label>
*/}

            {/* {HTMLReactParser(t('form.sections.client_partenaire_section.programme_action_title'))}

            {
                programmeActionCollectiveList && programmeActionCollectiveList.map(programmeActionCollective =>
                    <label className="block font-normal" >
                        <input
                            checked={cycleLangMain.parents && cycleLangMain.parents.map(parent => parent.id_cycle_lang).includes(programmeActionCollective.id_cycle_lang)}
                            type="checkbox"
                            value={programmeActionCollective.id_cycle_lang}
                            onChange={handleChangeParent} /> {programmeActionCollective.nom_cycle_lang}
                    </label>)
            } */}

            {fetchCycleLangPreselectionIsLoading ?
                <div className="text-center mx-auto text-emerald-700 bg-emerald-100 max-w-2xl py-2 rounded-full  "> ...Connecting in progress. Please wait </div>
                : <div className="py-2"> {'.'}</div>}
            <div className="grid grid-cols-1 lg:grid-cols-2 mt-3">
                <div>
                    <p className="font-bold py-2">Connexion de mon écosystème à des plans d'action</p>

                    {/* {JSON.stringify(Array.isArray(cycleLangMain.parents) && cycleLangMain.parents.map(parent => parent.id_cycle_lang))} */}
                    {
                        CollectifPlanActionList && CollectifPlanActionList.map((collectifPlanAction, index) => {

                            const checked = Array.isArray(cycleLangMain.parents) && cycleLangMain.parents.map(parent => parent.id_cycle_lang).includes(collectifPlanAction.id_cycle_lang);

                            return <label
                                className="font-normal flex items-center "
                                key={index}>

                                {fetchCycleLangPreselectionIsLoading ?
                                    <Spinner /> :
                                    <input
                                        className="basis-12"
                                        checked={checked}
                                        type="checkbox"
                                        value={collectifPlanAction.id_cycle_lang}
                                        onChange={handleChangeParent} />}

                                <img src={collectifPlanAction.logo_better.tiny} alt="" className="w-10" />
                                <span> {collectifPlanAction.nom_cycle_lang}</span>

                            </label>
                        })

                    }
                </div>
                <div>

                    <p className="font-bold py-2">Connexion de mon écosysètme à des cercles linguistiques </p>
                    {collectifLinguistiqueList && collectifLinguistiqueList.map(item => {

                        const checked = Array.isArray(cycleLangMain.parents) && cycleLangMain.parents.map(parent => parent.id_cycle_lang).includes(item.id_cycle_lang);


                        return <label className="flex font-normal  items-center" >

                            {fetchCycleLangPreselectionIsLoading ? <Spinner /> : <input
                                className="basis-12"
                                checked={checked}
                                type="checkbox"
                                value={item.id_cycle_lang}
                                onChange={handleChangeParent} />
                            }
                            <img src={item.logo_better.tiny} alt="" className="w-10" />
                            <span>  {item.nom_cycle_lang}</span>

                        </label>
                    })
                    }
                </div>
            </div>

            <ContactCycleLangSection

                idCycleLangList={[cycleLangMain.id_cycle_lang]}
                formulaire={formulaire} />
        </div>
    </>
    )
}

function RegistrationFormClienPartenaireSection({ formulaire, contact }) {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [cycleLangMain, setCycleLangMain] = useState(false);

    const [cycleLangMainPreviewIsVisible, setCycleLangMainPreviewIsVisible] = useState(true);

    const handleEditButton = (e) => {
        e.preventDefault();

        setCycleLangMainPreviewIsVisible(!cycleLangMainPreviewIsVisible);
    }

    useEffect(() => {
        const getContactCycleLang = async () => {

            await fetch(`${apiUrlTranslation}?action=getContactCycleLang&filter=id_contact = ${contact.id_contact}&get_parents=1`)
                .then(res => res.json())
                .then(res => {

                    const first = res.find(contactCycleLang => contactCycleLang.cycle_lang.cycle_lang_type == 'collectif_partenaire').cycle_lang;

                    setCycleLangMain(first)
                    setCycleLangMainPreviewIsVisible(true);
                })
        }


        getContactCycleLang();

    }, [i18n.resolvedLanguage, contact]);

    return (
        <RegistrationFormSection
            formSectionId={34}
            title={formulaire.client_partenaire_section_title}
            instructions={formulaire.client_partenaire_section_instructions}>

            {cycleLangMain && <div className='flex justify-end'>
                <button
                    onClick={handleEditButton}
                    className=" border-2 bg-blue-200 rounded-full w-28 text-black border-blue-600 ">
                    {!cycleLangMainPreviewIsVisible ? t('form.voir_appercu') : t('form.modifier')}
                </button>
            </div>
            }

            {/* {JSON.stringify(cycleLangMain)} */}

            {!cycleLangMain && contact && <CreateCycleLangSection
                contact={contact}
                setCycleLangMainPreviewIsVisible={setCycleLangMainPreviewIsVisible}
                setCycleLangMain={setCycleLangMain}
                cycleLangMain={cycleLangMain}
                formulaire={formulaire} />
            }


            <CycleLangMainPreview
                cycleLangMainPreviewIsVisible={cycleLangMainPreviewIsVisible}
                cycleLangMain={cycleLangMain} />


            <CycleLangMainSection
                contact={contact}
                cycleLangMainPreviewIsVisible={cycleLangMainPreviewIsVisible}
                cycleLangMain={cycleLangMain}
                setCycleLangMain={setCycleLangMain}
            />

        </RegistrationFormSection>
    )
}

export default RegistrationFormClienPartenaireSection;