import React, { useEffect, useState } from 'react'

import { useFormContext } from "react-hook-form";

const DisplayEventContactType = ({ eventContactTypeList, title, instructions }) => {

    const { register } = useFormContext();

    return <div className="mb-5">

        {/* <div className="text-lg font-bold">{title}</div>
        <div className="mb-4">{instructions}</div> */}

        {eventContactTypeList && eventContactTypeList.map(eventContactType =>
            <div className='text-left border py-1'>
                <label htmlFor="" className="font-normal">
                    <input
                        {...register("idEventContactTypeList")}
                        name="idEventContactTypeList" value={eventContactType.id_event_contact_type}
                        type="checkbox" /> <span className="font-bold">{eventContactType.libelle} : </span>
                    <span className="text-green-600 font-bold hidden"> {eventContactType.id_event_contact_type}</span>
                    <span className=" ml-2">{eventContactType.instructions_formulaire}</span>
                </label>
            </div>)
        }
    </div>
}

export default DisplayEventContactType;