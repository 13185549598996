import React, { useEffect, useState, useContext, useRef } from 'react';
import RegistrationFormSection from './RegistrationFormSection';
import parse from 'html-react-parser'
import { get, set, useFormContext } from 'react-hook-form';
import EventContext from '../contexts/EventContext'
import Moment from 'react-moment';
import "moment/locale/fr";
import { useTranslation } from 'react-i18next';
import useApiUrlTranslation from './useApiUrlTranslation';
import { sortByAttribute } from '../utils';
import Loader from './Loader';
import EnableLanguages from './EnableLanguages';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { use } from 'i18next';
import { RegistrationFormSupertitleSection } from './RegistrationFormStyleSection';

const juryEventStatutList = ['57', '66', '63', '67', '88', '74', '92', '97', '101', '102', '103', '116'];
//["63", "66", "67", "92", "96", "97", "74", "88"];
//['66', '63', '67', '88', '74'];


const eventContactStatutList = [
    { id_event_contact_type: "1", event_contact_type_nom: "Participant" },
    { id_event_contact_type: "1", event_contact_type_nom: "Coordinateur de Texploration" },
    { id_event_contact_type: "1", event_contact_type_nom: "Coordinateur de parcours de visite" },
    { id_event_contact_type: "2", event_contact_type_nom: "Membre du jury" }
];


const Contribution = ({
    confEventContribution,
    setActiveConfEventContribution,
    setShowEdit,
    handlePreview,
    handleDeleteConfEventContribution
}) => {

    const handleShowEdit = (e) => {

        e.preventDefault();

        setActiveConfEventContribution(confEventContribution);
        handlePreview(confEventContribution);
        setShowEdit(true);
    }

    const handleDelete = (e) => {
        e.preventDefault();
        handleDeleteConfEventContribution(confEventContribution)
    }

    return <tr className='text-xs' key={confEventContribution.id_conf_event_contribution}>
        <td>{confEventContribution.name}</td>
        <td>
            <div className="flex space-x-2">
                <button className="px-2 rounded bg-neutral-400 py-1" onClick={handleShowEdit}>éditer</button>
                <button className="px-2 rounded bg-neutral-400" onClick={handleDelete}>supprimer</button>
            </div>
        </td>
    </tr>
}

const Contributions = ({ confEvent, formulaire, contact }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [confEventContributionList, setConfEventContributionList] = useState(null);

    const [activeConfEventContribution, setActiveConfEventContribution] = useState(null);

    const [showEdit, setShowEdit] = useState(false);

    const [flashMessage, setFlashMessage] = useState(null);

    const preview = useRef();

    const handleChangeConfEventContribution = (e) => {

        e.preventDefault();

        const newActiveConfEventContribution = { ...activeConfEventContribution, [e.target.name]: e.target.value };
        setActiveConfEventContribution(newActiveConfEventContribution);
    }

    const handleCreateNewConfEventContribution = async (e) => {

        e.preventDefault();

        const formData = new FormData();

        setShowEdit(true);

        formData.append('name', activeConfEventContribution.name);
        formData.append('contribution', activeConfEventContribution.contribution);
        formData.append('id_conf_event', confEvent.id_conf_event);
        formData.append('id_contact', contact.id_contact);

        const url = `https://www.mlg-consulting.com/smart_territory/form/api.php?action=createConfEventContribution&id_conf_event=${confEvent.id_conf_event}`;

        await fetch(url, {
            method: 'POST',
            body: formData

        })
            .then(res => res.json())
            .then(res => {
                setFlashMessage(<div className="bg-green-100 p-2 my-3">Contribution ajoutée</div>)
                getConfEventContribution()
            })
    }

    const handleUpdateActiveConfEventContribution = async (e) => {

        e.preventDefault();

        const formData = new FormData();

        formData.append('name', activeConfEventContribution.name);
        formData.append('contribution', activeConfEventContribution.contribution);
        formData.append('id_contact', contact.id_contact);

        const url = `https://www.mlg-consulting.com/smart_territory/form/api.php?action=updateConfEventContribution&id_conf_event_contribution=${activeConfEventContribution.id_conf_event_contribution}`;

        await fetch(url, {
            method: 'POST',
            body: formData

        })
            .then(res => res.json())
            .then(res => {
                setFlashMessage(<div className="bg-green-100 p-2 my-3">Contribution mise à jour</div>)
                setShowEdit(false);
                getConfEventContribution()
            })
    }

    const handlePreview = (activeConfEventContribution) => {


        return false;
        setActiveConfEventContribution(activeConfEventContribution);

        preview.current.innerHTML = activeConfEventContribution.contribution;
    }

    const handleDeleteConfEventContribution = async (confEventContribution) => {

        const url = `https://www.mlg-consulting.com/smart_territory/form/api.php?action=deleteConfEventContribution&id_conf_event_contribution=${confEventContribution.id_conf_event_contribution}`;

        await fetch(url)
            .then(res => res.json())
            .then(res => {
                setFlashMessage(<div className="bg-green-100 p-2 my-3">Contribution supprimée</div>)
                getConfEventContribution()
                setActiveConfEventContribution(false)
                setShowEdit(false);
                preview.current.innerHTML = "";
            })
    }

    const handleShowContribute = (e) => {
        e.preventDefault();
        setShowEdit(!showEdit);
    }

    const getConfEventContribution = async () => {

        await fetch(`${apiUrlTranslation}?action=getConfEventContribution&filter=WHERE id_conf_event=${confEvent.id_conf_event}`)
            .then(res => res.json())
            .then(res =>
                setConfEventContributionList(res)
            )
    }

    useEffect(() => {

        getConfEventContribution();

    }, [confEvent])

    return (
        <>

            {showEdit && <div className='flex flex-col '>

                <div className='flex flex-col'>
                    <label> Nom : </label>
                    <label htmlFor=""><input type="text" name="name" className='border'
                        onChange={handleChangeConfEventContribution}
                        value={activeConfEventContribution?.name} /></label>
                </div>

                <div className='flex flex-col py-3'>
                    <label htmlFor=""> Contribution</label>
                    <textarea
                        onChange={handleChangeConfEventContribution}
                        name="contribution"
                        className='border p-1'
                        id="" cols="30" rows="10" value={activeConfEventContribution?.contribution}></textarea>
                </div>

            </div>}

            <div className='flex space-x-3 mb-3'>
                {!showEdit && <button className="rounded text-xs" onClick={(e) => {
                    setActiveConfEventContribution(null);
                    handleShowContribute(e);

                }}>contribuer</button>}
                {showEdit && <button className="rounded text-xs " onClick={(e) => {

                    e.preventDefault();
                    handleShowContribute(e);
                    setShowEdit(false);
                    setActiveConfEventContribution(null);

                }}>Masquer</button>}

                {showEdit && activeConfEventContribution && <button className="rounded text-xs" onClick={handleUpdateActiveConfEventContribution}>Modifier</button>}
                {showEdit && !activeConfEventContribution?.id_conf_event_contribution && <button className="rounded text-xs" onClick={handleCreateNewConfEventContribution}>Créer</button>}

            </div>

            <table className="list-disc pl-3 w-100">

                <tbody className='divide-y'>
                    {confEventContributionList && confEventContributionList.map((confEventContribution, index) =>

                        <Contribution
                            key={confEventContribution.id_conf_event_contribution}
                            handleDeleteConfEventContribution={handleDeleteConfEventContribution}
                            handlePreview={handlePreview}
                            setShowEdit={setShowEdit}
                            confEventContribution={confEventContribution}
                            setActiveConfEventContribution={setActiveConfEventContribution} />)}
                </tbody>
            </table >
        </>

    )

}
const ConfEventCycleLangHandler = ({
    confEvent,
    eventCycleLang,
    formulaire
}) => {

    const { register, formState } = useFormContext();

    useEffect(() => {

        console.log('formulaire', formulaire)

    }, [formulaire])

    return <>

        {eventCycleLang && ["37"].includes(formulaire.id_formulaire) && <label className='font-normal'>
            <input type="checkbox"
                required

                {...register("confEventCycleLangSelection")}
                inputName="confEventCycleLangSelection"

                value={JSON.stringify({
                    id_cycle_lang: eventCycleLang.cycle_lang.id_cycle_lang,
                    id_conf_event: confEvent.id_conf_event,
                })}
            /> Je souhaite positionner cet item sur mon programme
        </label>}
    </>
}

const DisplayEventContactStatutList = ({
    confEvent,
    eventContactStatutList,
    formulaireEventContactTypeEventComposantList,
    conf_event_type,
    formulaire,
    inputValue,
    inputName }) => {

    const { apiUrlTranslation } = useApiUrlTranslation()

    const { register, formState } = useFormContext();

    const [eventComposantConfEventTypeList, setEventComposantConfEventTypeList] = useState([]);

    const getEventComposantConfEventTypeList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventComposantConfEventTypeList&params= WHERE id_conf_event_type=${conf_event_type}`)
            .then(res => res.json())
            .then(res => setEventComposantConfEventTypeList(res))
    }

    useEffect(() => {

        getEventComposantConfEventTypeList();

        //  console.log('formulaireEventContactTypeEventComposantList', formulaireEventContactTypeEventComposantList)
        // console.log('eventComposantConfEventTypeList', eventComposantConfEventTypeList)

    }, [])

    return <div className="flex flex-wrap divide-y space-y-2">

        {/* {conf_event_type}

        {JSON.stringify(eventComposantConfEventTypeList.map(eventComposantConfEventType => eventComposantConfEventType.id_event_composant.id_event_composant))}
        {JSON.stringify(formulaireEventContactTypeEventComposantList.map(item => item.id_event_contact_type_event_composant.event_composant.id_event_composant))} */}

        {formulaireEventContactTypeEventComposantList && formulaireEventContactTypeEventComposantList

            //dedup by  item.id_event_contact_type_event_composant.event_contact_type.id_event_contact_type
            // .filter(item => eventComposantConfEventTypeList.map(eventComposantConfEventType => eventComposantConfEventType.id_event_composant.id_event_composant)
            //     .includes(item.id_event_contact_type_event_composant.event_composant.id_event_composant)
            // )
            .filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.id_event_contact_type_event_composant.event_contact_type.id_event_contact_type === item.id_event_contact_type_event_composant.event_contact_type.id_event_contact_type
                ))
            )
            .map((item, index) => {

                if (
                    formulaire.id_formulaire == "9"
                    && item.id_event_contact_type_event_composant.event_contact_type.id_event_contact_type == "143"
                    && confEvent.type == "96"
                ) {
                    return false;
                }

                return <label className='font-normal w-100 flex space-x-2' key={index}>

                    <input type="checkbox"
                        {...register(inputName)}

                        name={inputName}


                        value={JSON.stringify({
                            id_event_contact_type: item.id_event_contact_type_event_composant.event_contact_type.id_event_contact_type,
                            inputName: inputName,
                            inputValue: inputValue,
                            id_conf_event: confEvent.id_conf_event,
                        })}
                    />  <span>

                        {item.id_event_contact_type_event_composant.event_contact_type.instructions_formulaire != '' ? item.id_event_contact_type_event_composant.event_contact_type.instructions_formulaire :
                            item.id_event_contact_type_event_composant.event_contact_type.libelle}</span>
                    {/* <div className='flex bg-gray-100 p-2 d-none'>
                        id_event_contact_type :  {item.id_event_contact_type_event_composant.event_contact_type.id_event_contact_type}                       id_event_composant :  {item.id_event_contact_type_event_composant.event_composant.id_event_composant}
                    </div> */}
                </label>
            })
        }</div>
}

const ConfEvent = ({ confEvent, isThematiques, eventContactStatutList, formulaireEventContactTypeEventComposantList,
    formulaire, eventCycleLang, contact }) => {

    const { register, formState } = useFormContext();

    let inputName, inputValue;

    if (juryEventStatutList.includes(confEvent.type)) {

        // inputName = "challenges";

        inputName = "confEventEventContactTypeSelection";
        inputValue = confEvent.jury_event.id_jury_event;

    } else {

        inputName = "confEventSelection";
        inputValue = confEvent.id_conf_event;
    }

    const timeZone = <div className="flex items-center divide-x space-x-2">
        <div className='font-normal p-1 rounded mt-2 cursor-pointer'>
            <div className='  text-neutral-500 text-sm'>{parse(confEvent.localTimeZone)}</div>
            <div className='flex flex-wrap text-xs text-neutral-500 mt-2 d-none'>{parse(confEvent.otherTimeZones)}</div>
        </div>
        {/* 
        <EnableLanguages confEvent={confEvent} /> */}
    </div>

    const horaires = confEvent.defaultTimeZones ? timeZone : <span style={{ fontWeight: "bold", fontSize: "13px" }}>{confEvent.heure_debut} - {confEvent.heure_fin} <br /> </span>

    //const horaires = <div className='text-gray-500' style={{ fontSize: "13px" }}>{confEvent.heure_debut} - {confEvent.heure_fin} <br /> </div>

    return <tr className='hover:bg-gray-100' id_conf_event={confEvent.id_conf_event}>
        <td className="text-center align-middle d-none"><input  {...register(inputName)} value={inputValue} type="checkbox" name={inputName} id={inputValue} /></td>
        <td><label htmlFor={inputValue}>
            <div className=' '>
                {confEvent.conf_event_type && <span className=" mr-3 text-white rounded-full px-3 py-1 text-xs font-normal" style={{ backgroundColor: confEvent.conf_event_type.color }}>{confEvent.conf_event_type.conf_event_type_nom}</span>}
                {parse(confEvent.conf_event_lang.cel_titre)}
            </div>
            {!isThematiques && horaires}
        </label>

        </td>
        <td>

            <DisplayEventContactStatutList
                inputName={inputName}
                confEvent={confEvent}
                inputValue={inputValue}
                formulaire={formulaire}
                conf_event_type={confEvent.type}
                formulaireEventContactTypeEventComposantList={formulaireEventContactTypeEventComposantList}
                eventContactStatutList={eventContactStatutList} />
        </td>
        <td>
            {formulaire && eventCycleLang && <ConfEventCycleLangHandler
                eventCycleLang={eventCycleLang}
                confEvent={confEvent}
                formulaire={formulaire} />
            }

            {formulaire && formulaire.id_formulaire == 50 && <Contributions
                contact={contact}
                confEvent={confEvent}
                formulaire={formulaire}
            />}
        </td>
    </tr>
}

const DisplayProgramm = ({ confEventList, isThematiques, eventContactStatutList, contact }) => {

    const { apiUrlTranslation } = useApiUrlTranslation()

    const { id_event_cycle_lang } = useParams();

    const { formName } = useParams();

    const [eventCycleLang, setEventCycleLang] = useState(null)

    const [confEventListLast, setConfEventListLast] = useState(confEventList)

    const [formulaireEventContactTypeEventComposantList, setFormulaireEventContactTypeEventComposantList] = useState(null)

    const [formulaire, setFormulaire] = useState(null);

    const { t, i18n } = useTranslation();

    let d = [];
    let divider;

    const getFormulaireEventContactTypeEventComposantList = async (id_formulaire) => {

        await fetch(`${apiUrlTranslation}?action=getFormulaireEventContactTypeEventComposantList&params=WHERE id_formulaire=${id_formulaire}`)
            .then(res => res.json())
            .then(res => {
                setFormulaireEventContactTypeEventComposantList(res);
            })
    }

    useEffect(() => {

        const getEventCycleLangList = async () => {

            await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params=WHERE id_event_cycle_lang=${id_event_cycle_lang} LIMIT 1`)
                .then(res => res.json())
                .then(res => setEventCycleLang(res[0]))
        }

        const getFormulaireList = async () => {

            await fetch(`${apiUrlTranslation}?action=getFormulaireList&params=WHERE route="${formName}"`)
                .then(res => res.json())
                .then(res => {
                    console.log(res[0]);
                    setFormulaire(res[0])
                    return res[0].id_formulaire
                })
                .then((id_formulaire) => {
                    getFormulaireEventContactTypeEventComposantList(id_formulaire);
                })
        }

        getFormulaireList();

        getEventCycleLangList();

        setConfEventListLast(confEventList);


        if (isThematiques) {
            //setConfEventListLast(confEventListLast.filter(confEvent => confEvent.jury_event.thematique == "y"))
        }

    }, [confEventList])



    return <table className="table table-sm table-bordered ">

        {
            confEventListLast.length > 0 && confEventListLast
                // .filter(confEvent => (confEvent.jury_event && juryEventStatutList.includes(confEvent.type)) ||
                //     (!confEvent.jury_event && !juryEventStatutList.includes(confEvent.type))
                // )
                .map((confEvent) => {

                    divider = !d.includes(confEvent.conf_event_date_local) && !isThematiques && <tr className="bg-dark text-white" >
                        <td className='d-none'></td>
                        <td colSpan=""><Moment locale={i18n.resolvedLanguage} format="LL">{confEvent.conf_event_date_local}</Moment></td>
                        <td>Mes choix</td>
                        <td></td>
                    </tr>

                    d.push(confEvent.conf_event_date_local)

                    return <tbody key={confEvent.id_conf_event}>
                        {divider}
                        {/* {JSON.stringify(formulaireEventContactTypeEventComposantList.map(item => item.id_event_contact_type_event_composant))} */}
                        <ConfEvent
                            contact={contact}
                            eventCycleLang={eventCycleLang}
                            formulaire={formulaire}
                            formulaireEventContactTypeEventComposantList={formulaireEventContactTypeEventComposantList}
                            eventContactStatutList={eventContactStatutList}
                            isThematiques={isThematiques}
                            confEvent={confEvent} /></tbody>
                })}
    </table >
}


const RegistrationFormJuryEventSection = ({ title, confEventTypeList,
    confEventTypeListFilter, instructions, isThematiques, formulaire, eventCycleLang, contact }) => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [confEventList, setConfEventList] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const { formName } = useParams();

    const { i18n, apiUrlTranslation } = useApiUrlTranslation()

    const fetchConfEventList = async () => {

        let filteredList;

        const urlParams = new URLSearchParams(window.location.search);
        const id_conf_event_list = urlParams.get('id_conf_event_list');


        await fetch(`${apiUrlTranslation}?action=getConfEvent&filter=id_event=${currentEvent.id_event} AND publier !='n'   AND type NOT IN (92,103)`)
            .then(res => res.json())
            .then(res => {

                let sortedList = sortByAttribute(res, 'conf_event_date_local', 'heure_debut_local');

                if (id_conf_event_list) {

                    let idConfEventList = id_conf_event_list.split(',');

                    sortedList = sortedList.filter(confEvent =>
                        idConfEventList.includes(confEvent.id_conf_event)
                    );
                }

                sortedList = sortedList.filter(confEvent =>
                    confEvent.publier != 'n'
                );

                if (formulaire.id_formulaire != "46") {

                    sortedList = sortedList.filter(confEvent =>
                        confEvent.selectable != 'n'
                    );
                }

                sortedList = ["techprovider-greater-regions", "techprovider-greater-regions-fast"].includes(formName) ? sortedList.filter(confEvent => ["176174"].includes(confEvent.id_conf_event)) : sortedList
                sortedList = ["techprovider-mgv", "techprovider-mgv-fast"].includes(formName) ? sortedList.filter(confEvent => ["174735", "174727"].includes(confEvent.id_conf_event)) : sortedList

                console.log("test 0")

                if (isThematiques) {
                    let filteredList = sortedList.filter(confEvent => confEvent.jury_event.thematique == "y")
                    setConfEventList(filteredList);
                    console.log("test A")
                } else {

                    console.log("test B")
                    let filteredList = sortedList.filter(confEvent => confEvent.type != '63' && confEvent.jury_event.thematique != "y")

                    if (confEventTypeList.length > 0) {
                        filteredList = sortedList.filter(confEvent => confEventTypeList.includes(confEvent.type))

                        console.log("test C")
                    }

                    if (confEventTypeListFilter && confEventTypeListFilter.length > 0) {
                        filteredList = sortedList.filter(confEvent => !confEventTypeListFilter.includes(confEvent.type))

                        console.log("test D")
                    }

                    switch (formulaire.id_formulaire) {

                        //blitz spitch candidat
                        case "44": filteredList = sortedList.filter(confEvent => ["101"].includes(confEvent.type) && confEvent.jury_event.thematique != "y"); break;

                        //blitz spitch candidat
                        case "26": filteredList = sortedList.filter(confEvent => !["101"].includes(confEvent.type) && confEvent.jury_event.thematique != "y"); break;

                        //blitz spitch candidat
                        case "11": filteredList = sortedList.filter(confEvent => !["101"].includes(confEvent.type) && confEvent.jury_event.thematique != "y"); break;


                        //blitz spitch candidat
                        case "4": filteredList = sortedList.filter(confEvent => ["67"].includes(confEvent.type) && confEvent.jury_event.thematique != "y"); break;


                        //Coordinateur Techxploration Onboarding
                        case "9": filteredList = sortedList.filter(confEvent => ["83", "95", "96"].includes(confEvent.type) && confEvent.jury_event.thematique != "y"); break;

                        //techxplorateur
                        case "31": filteredList = sortedList.filter(confEvent => ["83", "95", "96"].includes(confEvent.type) && confEvent.jury_event.thematique != "y"); break;

                        case "46": filteredList = sortedList.filter(confEvent => ["176451"].includes(confEvent.id_conf_event) && confEvent.jury_event.thematique != "y"); break;



                        default: break;
                    }

                    setConfEventList(filteredList);
                }

                setIsLoading(false);
            })
    }

    useEffect(() => {

        // console.log('confEventTypeListFilter', confEventTypeListFilter);

        currentEvent && fetchConfEventList()

    }, [currentEvent, i18n.resolvedLanguage, formName]);

    if (!confEventList || confEventList.length == 0) {
        return false
    }

    if (isLoading) {

        return <Loader />;
    }

    return <>

        {formulaire && formulaire.temps_forts_section_supertitle && <RegistrationFormSupertitleSection>
            {formulaire.temps_forts_section_supertitle}
        </RegistrationFormSupertitleSection>}

        <RegistrationFormSection
            title={formulaire.temps_forts_section_title}
            instructions={formulaire.temps_forts_section_instructions}>
            {currentEvent ? <DisplayProgramm
                eventContactStatutList={eventContactStatutList}
                isThematiques={isThematiques}
                confEventList={confEventList}
                contact={contact} /> : 'loading...'}

        </RegistrationFormSection>
    </>
}

export default RegistrationFormJuryEventSection;