import React, { useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import useApiUrlTranslation from './useApiUrlTranslation'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useEffect } from 'react'
import HTMLReactParser from 'html-react-parser'
import EventContext from '../contexts/EventContext'
import { useContext } from 'react'
import { act } from 'react-dom/test-utils'
import { set } from 'react-hook-form'
import { API_URL } from '../config'


function Modal({ showModal, setShowModal, body }) {

    return (
        <>

            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-7xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
                                    <span className="  font-bold text-base w-100">
                                        Prévisualisation de l'e-mail
                                    </span>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                                        {HTMLReactParser(body)}
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="hidden bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}



const ContactListItem = ({ contact, deleteContactListItem, showModal, setShowModal, activeContact, setActiveContact, sendEmailNotification }) => {

    const deleteItem = (e) => {
        e.preventDefault()
        deleteContactListItem(contact)
    }
    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const fields = ['prenom', 'nom', 'societe', 'fonction', 'mail']
    return (

        <div className="flex space-x-2 items-center justify-center">
            {fields.map(field => {

                if (!['prenom', 'nom', 'mail', 'societe'].includes(field)) {
                    return false;
                }

                return <input disabled type="text" value={contact[field]} className=" bg-gray-200 border border-gray-300 rounded-md px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />
            })}


            <button onClick={(e) => {
                e.preventDefault();
                setActiveContact(contact);
                setShowModal(true);
            }} className="w-52 border bg-gray-500 text-white rounded text-sm mt-2" data-toggle="modal" data-target="#exampleModal" href="#"> {t('form.voir_appercu')}</button>


            <button className="w-52 border hidden bg-red-300 text-white rounded text-sm mt-2" onClick={deleteItem}>Annuler</button>
            <button className="border bg-black text-white rounded hidden"> Update</button>
            <button className="w-52 border bg-green-500 text-white rounded text-sm mt-2" onClick={(e) => {
                e.preventDefault();
                //setActiveContact(contact);
                //sendEmailNotification(contact);

                //first set active contact then send email notification
                setActiveContact(contact);
                sendEmailNotification({ contact });
                console.log(contact, activeContact);

            }}>{t('form.envoyer')}</button>

        </div>
    )

}


const RegistrationContactsOnboardingSection = ({ contact }) => {

    // addd contact array to state
    const [contactList, setContactList] = useState([])

    const [newContact, setNewContact] = useState({})

    const { idContact } = useParams();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [activeContact, setActiveContact] = useState(false);

    const [showModal, setShowModal] = React.useState(false);

    const [statutList, setStatutList] = useState([]);

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [body, setBody] = useState('');



    // add contact to array on button click
    // map over contact array to display contacts

    const handleChangeNewContact = (e) => {

        const { name, value } = e.target;

        setNewContact({ ...newContact, [name]: value })
    }

    const getContactList = async () => {

        if (!contact) return;

        await fetch(`${API_URL}?action=getContactList&params= WHERE auteur=${contact.id_contact} AND DATE(date_creat)>'2023-02-01'  `)
            .then(response => response.json())
            .then(data => {
                setContactList(data);
            })
    }

    const createNewContact = async (e) => {

        e.preventDefault()

        //  setContactList([...contactList, newContact]);

        console.log(newContact)

        let formData = new FormData();

        formData.append('auteur', idContact);
        formData.append('prenom', newContact.prenom);
        formData.append('nom', newContact.nom);
        formData.append('societe', newContact.societe);
        formData.append('mail', newContact.mail);
        formData.append('statut', newContact.statut);

        await fetch(`https://www.mlg-consulting.com/smart_territory/form/api.php?action=createContact`, {

            method: 'POST',
            body: formData

        })
            .then(response => response.json())
            .then(data => {
                //setEventContactTypeEngagementList(data);
                getContactList()
            })
    }

    const deleteContactListItem = (contact) => {

        const filteredContactList = contactList.filter(item => item !== contact)

        setContactList(filteredContactList)
    }


    const sendEmailNotification = async ({ contact }) => {

        const newActiveContact = contact ? contact : activeContact;

        setActiveContact(newActiveContact);

        const formData = new FormData();

        formData.append('body', body);
        formData.append('dest', newActiveContact.mail);
        formData.append('objet', `Demande de participation juré ${currentEvent.nom} myglobalVillage`);

        await fetch(`${apiUrlTranslation}?action=sendEmailNotification`, {

            method: 'POST',
            body: formData
        })
    }


    useEffect(() => {

        const getStatuts = async () => {

            await fetch(`${apiUrlTranslation}?action=getStatuts&filter=id_statut IN(77, 79, 80, 82)`)
                .then(response => response.json())
                .then(data => {
                    setStatutList(data);
                })
        }

        getContactList();
        getStatuts();

        setNewContact({ ...newContact, idContact: idContact });


        activeContact && setBody(`
        <div className="text-xs">
        <p className="font-bold pb-3">Subject: Request for Jury Participation in #sportech Event myglobalVillage</p>
        <p className="pb-2">This email is sent to you by <span className="font-bold"> ${contact.prenom} ${contact.nom} </span> to propose that you join the jury who will select the best solutions presented during the pitch sessions of the Global Challenge:</p>
        <p className="font-bold">Event: ${currentEvent.nom}</p>
        <p className="pb-2 font-bold">Date: ${currentEvent.precision_date}</p>
        <p className="pb-2">You will be able to join the stage of the event which is held at:</p>
        <p className="pb-2 font-bold"> ${currentEvent.lieu.lieu_nom} <br />
        ${currentEvent.lieu.lieu_adresse} 
        ${currentEvent.lieu.lieu_ville}, ${currentEvent.lieu.lieu_pays} </p>
        <p className="pb-2"> If you plan to participate or connect remotely, here is the Zoom link to attend.</p>
        <p className="pb-2">
        
        <a href="https://us06web.zoom.us/j/4135227009?pwd=bk9LRUxtYTJXTmNWcXdVZTRVRlM1Zz09" className="text-blue-700" target="_blank">https://us06web.zoom.us/j/4135227009?pwd=bk9LRUxtYTJXTmNWcXdVZTRVRlM1Zz09</a>
        </p>
        <p className="pb-4"> On this occasion, you will be equipped with our voting and connection solution and will be able to:</p>
        <ul className="pb-4">
        <li> - Participate in the election of the best startups</li>
        <li> - Ask questions to startup leaders</li>
         <li> - Connect with those that have intrigued you</li>
        </ul> 
        <p> To confirm your participation <a href="https://www.myglobalvillage.com/events/${currentEvent.id_event}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${currentEvent.id_event}/signup/jury/c/{id_contact}?formisvisible=1&id_formulaire=11&form-name=jury&lng=fr" className="text-blue-700" target="_blank">click on this registration link</a></p>
        <p className=""> To decline this request <a href="#" className="text-blue-700" target="_blank">click on this link to cancel your participation </a> </p>
        <p className="pb-3">With ${contact.prenom} ${contact.nom} We sincerely hope to welcome you among the jurors of our pitch session</p>
        <p className=""> Thank you for your support of myGlobalVillage </p>
        <p>Marc-Lionel Gatto - General Coordinator </p>

<hr  className="my-3"/>
        
        
        
        
        
        
        
        
        
        <p className="font-bold pb-3">Objet : Demande de participation juré #sportech nom de l'événement myglobalVillage</p>

        <p className="pb-2"> Ce mail vous est envoyé par <span className="font-bold"> ${contact.prenom} ${contact.nom} </span>  afin de vous proposer de rejoindre les jurés qui éliront les meilleures solutions présentées lors des sessions de pitch  du Global Challenge  :</p>
         
        <p className="font-bold">Evénement :  ${currentEvent.nom}</p>
         
       <p className="pb-2 font-bold">Date  ${currentEvent.precision_date}</p>
         
        <p className="pb-2"> Vous pourrez rejoindre la scène de l'événement qui se se tient : </p>
        
        
        <p className="pb-2 font-bold">  ${currentEvent.lieu.lieu_nom} <br />
        ${currentEvent.lieu.lieu_adresse} 
    ${currentEvent.lieu.lieu_ville}, ${currentEvent.lieu.lieu_pays} </p>

   <p className="pb-2"> Si vous avez prévu d'y participer  ou vous connecter à distance,  voilà ci-dessous le lien zoom pour y assister.</p>
   
   <p className="pb-2">
   
   <a  href="https://us06web.zoom.us/j/4135227009?pwd=bk9LRUxtYTJXTmNWcXdVZTRVRlM1Zz09" className="text-blue-700" target="_blank">https://us06web.zoom.us/j/4135227009?pwd=bk9LRUxtYTJXTmNWcXdVZTRVRlM1Zz09</a>
   
   </p>
         
         <p className="pb-4">  À cette occasion vous serez équipé de notre solution de vote et de connexion vous pourrez :</p>
         
        <ul className="pb-4">
        <li> - Participer à l'élection des meilleures satrtups</li>
         
        <li> - Poser des questions aux dirigeants de stratups</li>
         
         <li> - Vous mettre en relation avec celles qui vous ont séduite</li>
        </ul> 

        <p> Pour valider votre participation <a href="https://www.myglobalvillage.com/events/${currentEvent.id_event}/login-redirect?redirectUrl=https://www.myglobalvillage.com/events/${currentEvent.id_event}/signup/jury/c/{id_contact}?formisvisible=1&id_formulaire=11&form-name=jury&lng=fr" className="text-blue-700" target="_blank">cliquez sur ce lien d'inscription</a></p>
         
        <p  className=""> Pour décliner cette demande <a href="#" className="text-blue-700" target="_blank">cliquer sur ce lien d'annuluation de votre participation </a> </p>
         
        <p  className="pb-3">Avec ${contact.prenom} ${contact.nom}  Nous espèrerons sincèrement vous accueillir parmi les jurés de notre session de pitch</p>
         
        <p className=""> Merci de votre soutien à myGlobalVilage  </p>
         
        <p>Marc-Lionel Gatto -  Coordinateur général  </p></div>
         `)

    }, [activeContact])

    if (!currentEvent || !contact) return null;


    return (
        <RegistrationFormSection
            formSectionId={26}
            title={t('form.sections.onboarding_section.section_title')}
            instructions={HTMLReactParser(t('form.sections.onboarding_section.section_instructions'))}

        >

            <div div className="flex flex-col bg-neutral-100 p-2 rounded   mb-3" >

                <div className="flex p-2 space-x-1 ">
                    <select name="statut" className='bg-white rounded text-center h-10 mt-2 w-52' onChange={handleChangeNewContact} >
                        <option value="">{t('form.sections.onboarding_section.statut')}</option>


                        {statutList.map(statut => <option key={statut.id_statut} value={statut.id_statut}>{statut.statut_nom}</option>)}
                    </select>
                    <input onChange={handleChangeNewContact} type="text" name="prenom" placeholder={t('form.sections.contact_section.prenom')} className="border border-gray-300 rounded-md px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />
                    <input onChange={handleChangeNewContact} type="text" name="nom" placeholder={t('form.sections.contact_section.nom')} className="border border-gray-300 rounded-md px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />
                    <input onChange={handleChangeNewContact} type="text" name="societe" placeholder={t('form.sections.organisme_section.societe')} className="border border-gray-300 rounded-md px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />
                    <input onChange={handleChangeNewContact} type="text" name="mail" placeholder={t('form.sections.contact_section.e-mail')} className="border border-gray-300 rounded-md px-4 py-2 mt-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />

                    <button onClick={createNewContact} className="border bg-blue-500  text-white rounded">{t('form.sections.parcours_thematiques.ajouter')}</button>
                </div>
            </div>


            {
                contactList && contactList.map(contact => <ContactListItem

                    sendEmailNotification={sendEmailNotification}
                    key={contact.id_contact}
                    showModal={showModal}
                    activeContact={activeContact}
                    setActiveContact={setActiveContact}
                    setShowModal={setShowModal}
                    contact={contact}
                    deleteContactListItem={deleteContactListItem} />)
            }

            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                body={body} />

        </RegistrationFormSection >
    )
}

export default RegistrationContactsOnboardingSection;