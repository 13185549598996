import React from 'react'


export function AnimatedButton({ btnText }) {
    return (
        <button className="bg-pink-600 relative min-w-[184px] inline-flex items-center justify-center px-6 py-[18px] overflow-hidden font-medium transition duration-300 ease-out rounded-full shadow-xl group">
            <span className="absolute inset-0 w-full h-full bg-gradient-to-br from-accent via-accent_secondary to-accent"></span>
            <span className="absolute bottom-0 right-0 block w-72 h-72 mb-32 mr-4 transition duration-500 origin-bottom-left transform rotate-45 translate-x-24 bg-white rounded-full opacity-10 group-hover:rotate-90 ease"></span>
            <span className="relative text-white text-sm uppercase tracking-[1px]">{btnText}</span>
        </button>
    )
}

const Button = (props) => {
    return (
        <button {...props}
            type={props.type ?? "button"}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
            {props.children}
        </button>
    )
}

export default Button

