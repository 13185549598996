import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RegistrationFormSection from './RegistrationFormSection';

const RegistrationFormParticipationDistance = () => {

    const [statutList, setStatutList] = useState(null);

    const { register, formState } = useFormContext();

    const { t, i18n } = useTranslation();

    const api = `https://www.myglobalvillage.com/${i18n.resolvedLanguage === 'fr' ? '' : i18n.resolvedLanguage + '/'}api/`

    useEffect(() => {



    }, [i18n.resolvedLanguage]);

    return <RegistrationFormSection

        formSectionId={23}
        title={t("form.sections.mode_participation_section.section_title")} >

        <div className="row">
            <div
                className="col-md-6">
                <div className="form-check">
                    <label className="form-check-label me-3" style={{ fontWeight: "normal" }}>
                        <input  {...register("participation_a_distance")} type="radio" id="" name="participation_a_distance" value="1" /> {t("form.sections.mode_participation_section.participation_a_distance")}
                    </label>
                    <label className="form-check-label" style={{ fontWeight: "normal" }}>
                        <input  {...register("participation_a_distance")} type="radio" id="" name="participation_a_distance" value="0" /> {t("form.sections.mode_participation_section.participation_sur_place")}
                    </label>
                </div>
            </div>
        </div>
    </RegistrationFormSection>
}

export default RegistrationFormParticipationDistance;