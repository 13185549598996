import React, { useEffect, useState } from 'react'
import useApiUrlTranslation from './useApiUrlTranslation';
import Moment from 'react-moment';

const Carousel = ({ attachedFileList }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? attachedFileList.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === attachedFileList.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="relative w-full max-w-4xl mx-auto">
            <div className="overflow-hidden">
                <div
                    className="flex transition-transform duration-500"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {attachedFileList && attachedFileList.map((attachedFile, index) => (
                        <div key={attachedFile.id_event_composant} className="min-w-full bg-neutral-950 p-2">
                            <div className="text-white">{attachedFile.attached_file_libelle}</div>
                            <img src={attachedFile.file_name_thumbs.large} alt="" className="w-full h-auto" />
                            <div className="text-gray-400">{attachedFile.description}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex justify-between w-full">
                <button
                    onClick={handlePrev}
                    className="  top-1/2 w-10   transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    aria-label="Previous"
                >
                    &#9664;
                </button>
                <button
                    onClick={handleNext}
                    className="  top-1/2  w-10   transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    aria-label="Next"
                >
                    &#9654;
                </button>
            </div>
        </div>
    );
};



const AttachedFileSection = ({ attachedFileList }) => {

    return (

        <div className="">

            <h2 className="m-3 animatedgradient font-extrabold">A l'affiche</h2>

            <div className="py-5">
                <Carousel attachedFileList={attachedFileList} />
            </div>
            {/* 
            <div className="flex space-x-1">

                {attachedFileList && attachedFileList.map(attachedFile =>
                    <div key={attachedFile.id_event_composant}
                        className="bg-neutral-900 p-2">

                        <div className="">  {attachedFile.attached_file_libelle}</div>

                        <img src={attachedFile.file_name_thumbs.large} alt="" />

                        {attachedFile.description}

                    </div>)}

            </div> */}
        </div>
    )


}

const EventSection = ({ eventLigne }) => {

}

const AgendaAnnuel = ({ cycleLang }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [cycleLangEventLigneList, setCycleLangEventLigneList] = useState(null);

    const [eventList, setEventList] = useState(null);

    const [eventComposantList, setEventComposantList] = useState(null);

    const [attachedFileList, setAttachedFileList] = useState(null);

    const getAttachedFiles = async ({ idPrimaryKeyValue }) => {

        await fetch(`${apiUrlTranslation}?action=getAttachedFiles&params=WHERE attached_file_table='event_composant' AND primary_key_value IN(${idPrimaryKeyValue.join(',')})`)
            .then(res => res.json())
            .then(res => {
                setAttachedFileList(res);
            })
    }

    const getEventEventComposant = async ({ idEventList }) => {

        await fetch(`${apiUrlTranslation}?action=getEventEventComposant&req_suite=WHERE id_event IN(${idEventList.join(',')})`)

            .then(res => res.json())
            .then(res => {
                setEventComposantList(res);
                getAttachedFiles({
                    idPrimaryKeyValue: res
                        //deduplicate
                        .filter((eventComposant, index, self) =>
                            index === self.findIndex((t) => (
                                t.id_event_composant === eventComposant.id_event_composant
                            ))
                        )
                        .map(eventComposant => eventComposant.id_event_composant)
                })

            })
    }

    const getEvents = async ({ idEventLigneList }) => {

        await fetch(`${apiUrlTranslation}?action=getEvents&params&yo=yo&params=WHERE id_event_ligne IN(${idEventLigneList.join(',')}) AND event_start >= NOW() AND `)
            .then(res => res.json())
            .then(res => {
                setEventList(res);
                getEventEventComposant({ idEventList: res.map(event => event.id_event) })
            })
    }

    const getCycleLangEventLigne = async () => {

        await fetch(`${apiUrlTranslation}?action=getCycleLangEventLigne&params=WHERE id_cycle_lang IN(${cycleLang.id_cycle_lang})  `)
            .then(res => res.json())
            .then(res => {
                setCycleLangEventLigneList(res);
                getEvents({ idEventLigneList: res.map(cycleLangEventLigne => cycleLangEventLigne.id_event_ligne.id_event_ligne) })
            })
    }

    useEffect(() => {
        getCycleLangEventLigne()
    }, [i18n.resolvedLanguage, cycleLang]);


    return (
        <div className=" px-2 mx-auto w-full">

            <AttachedFileSection attachedFileList={attachedFileList} />

            <h2 className="m-3 animatedgradient font-extrabold">Agenda annual</h2>
            <table className="w-full text-left text-sm text-gray-400">
                <thead className="text-lg capitalize text-white">
                    <tr>
                        <th className=" text-center">Mois <br />habituel</th>
                        <th className="">Logo</th>
                        <th className="">Nom</th>
                        <th className="">Prochain événement</th>
                        <th className="">Actions collectives</th>
                        <th className="">Périodicité</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-neutral-900">
                    {cycleLangEventLigneList && cycleLangEventLigneList

                        .sort((a, b) => a.id_event_ligne.event_start_mois_usuel.localeCompare(b.id_event_ligne.event_start_mois_usuel))
                        .map(cycleLangEventLigne => {
                            const cycleLangItem = cycleLangEventLigne.id_cycle_lang;

                            const eventLigne = cycleLangEventLigne.id_event_ligne;

                            const nextEvent = eventList && eventList.find(event => event.event_ligne.id_event_ligne == eventLigne.id_event_ligne);

                            return (
                                <tr key={cycleLangEventLigne.id_cycle_lang_event_ligne} className="hover:bg-neutral-800">

                                    <td className="p-2 capitalize">
                                        <Moment format="MMMM">{eventLigne.event_start_mois_usuel}</Moment>
                                    </td>
                                    <td className="py-2">
                                        <img src={eventLigne.el_visuels.small} alt="" className="w-18 h-14 object-cover " />
                                    </td>
                                    <td className="p-2 font-bold uppercase">
                                        {eventLigne.event_ligne_nom}
                                    </td>
                                    <td>
                                        {
                                            nextEvent && (
                                                <div>
                                                    <p className="font-bold">{nextEvent.nom}</p>
                                                    <div className='text-gray-500 text-xs'>{nextEvent.precision_date != "" ? nextEvent.precision_date : <Moment format="dddd D MMMM">{nextEvent.event_start}</Moment>}

                                                        <p className="">
                                                            <a
                                                                href={`/events/${nextEvent.id_event}/`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="text-blue-500 hover:text-blue-700 underline font-semibold"
                                                            >
                                                                Site web
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </td>
                                    <td>
                                        <div className="flex space-x-3 my-3">
                                            {eventComposantList && nextEvent && eventComposantList
                                                .filter(eventComposant => eventComposant.id_event.id_event == nextEvent.id_event)
                                                .map(eventComposant => {
                                                    return (
                                                        <div key={eventComposant.id_event_composant}>
                                                            <p className="text-xs text-gray-500">
                                                                <img src={eventComposant.visuel_thumbs.tiny} className=' w-10' alt=""
                                                                    style={{ filter: 'invert(1)' }} />
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </td>
                                    <td className="p-2 capitalize">
                                        {eventLigne.periodicite}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    )
}

export default AgendaAnnuel