import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import { useParams } from 'react-router-dom'
import { use } from 'i18next';
import useApiUrlTranslation from './useApiUrlTranslation';
import { AnimatedButton } from './Ui/Button';

const EventsSection = ({ contactCycleLangList }) => {

    return (<div className="mt-5">
        <h2 className="dark:text-white h2 my-5">Mes événements</h2>


        <div className="mt-5 flex space-x-5 justify-between items-center">
            <div className="flex space-x-5 "></div>
            <div><AnimatedButton btnText='Ajouter / modifier' /></div>
        </div>
    </div>

    )

}

const ActionsCollectives = ({ contactCycleLangList }) => {

    return (

        <div className="mt-5">
            <h2 className="dark:text-white h2 my-5">Mes actions collectives</h2>


            <div className="mt-5 flex space-x-5 justify-between items-center">
                <div className="flex space-x-5 ">

                    {contactCycleLangList && contactCycleLangList.map((contactCycleLangList, index) => {
                        const cycle_lang = contactCycleLangList.cycle_lang;

                        return (<div div key={index} className="flex space-x-3 mt-5" >

                            {cycle_lang.logo_better.small && <div><img src={cycle_lang.logo_better.small} alt="" className="rounded-full w-20 h-20 object-cover" /></div>}
                        </div>)
                    })}
                </div>
                <div><AnimatedButton btnText='Accéder aux outils' /></div>
            </div>
        </div>
    )
}

const Network = ({ contactCycleLangList }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [allContactCycleLangList, setAllContactCycleLangList] = useState(null);

    const getContactCycleLang = async () => {

        await fetch(`${apiUrlTranslation}?action=getContactCycleLang&filter=id_cycle_lang IN (${contactCycleLangList.map(contactCycleLangList => contactCycleLangList.cycle_lang.id_cycle_lang)}) `)
            .then(res => res.json())
            .then(res => setAllContactCycleLangList(res))
    }


    useEffect(() => {
        contactCycleLangList && getContactCycleLang();
    }, [contactCycleLangList])

    return (
        <div className="mt-5">

            <h2 className="dark:text-white h2 my-5">Les membres de mon ecosystem</h2>

            <div className="flex justify-between items-center">

                {allContactCycleLangList && <div className="flex flex-wrap -space-x-2">
                    {allContactCycleLangList &&
                        allContactCycleLangList
                            .filter(({ contact }) => !contact.photos.small.includes('silhouette'))
                            .slice(0, 10)
                            .map(({ contact }, index) => (
                                contact.photos?.small &&
                                (
                                    <div key={index} className="w-20">
                                        <img
                                            src={contact.photos.small}
                                            alt=""
                                            className="rounded-full w-20 h-20 object-cover"
                                        />
                                    </div>
                                )
                            ))}

                    <div className=" text-2xl flex items-center justify-center w-20 h-20 bg-neutral-700/30 text-white rounded-full">+ {allContactCycleLangList.length}</div>
                </div>
                }

                <div>
                    <AnimatedButton btnText='Modifier / enrichir' />
                </div>
            </div>

        </div>
    )
}



const Header = ({ contact, contactCycleLangList }) => {

    if (!contact) return null;

    return (
        <div className="flex space-x-3 mt-5 justify-between">

            <div className="flex space-x-3">
                <div><img src={contact.photos.small} alt="" className="rounded-full w-40 h-40 object-cover" /></div>
                <div>
                    <p className="dark:text-white text-2xl">{contact.prenom} {contact.nom} </p>
                    <p className="dark:text-white text-xl">{contact.fonction}</p>
                    <p className="dark:text-white text-xl">{contact.societe} </p>
                    <p><a href="e">Modifier mes coordonnnées et mon profil</a></p>
                </div>

            </div>

            <div className="flex">
                {contactCycleLangList && contactCycleLangList.map((contactCycleLangList, index) => {

                    const cycle_lang = contactCycleLangList.cycle_lang;

                    return <div key={index}>
                        <div className="flex space-x-3 mt-5">
                            <div><img src={cycle_lang.logo_better.small} alt="" className="rounded-full w-20 h-20 object-cover" /></div>

                        </div>
                    </div>
                }

                )}
            </div>
        </div>
    )


}



const Workspace = () => {

    const { idContact } = useParams();

    const [contact, setContact] = useState(null);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contactCycleLangList, setContactCycleLangList] = useState(null);


    const getContactCycleLang = async () => {

        await fetch(`${apiUrlTranslation}?action=getContactCycleLang&filter=id_contact=${idContact} AND id_cycle_lang NOT IN (0)`)
            .then(res => res.json())
            .then(res => setContactCycleLangList(res))
    }

    const getContact = async () => {

        await fetch(`${apiUrlTranslation}?action=getContact&id_contact=${idContact}`)
            .then(res => res.json())
            .then(res => {
                setContact(res)
                getContactCycleLang(res.id_contact)
                console.log(res);

            })
    }


    useEffect(() => {
        getContact();
    }, [])



    return (
        <Layout>
            <div className="px-5">

                <Header
                    contactCycleLangList={contactCycleLangList}
                    contact={contact} />

                <Network contactCycleLangList={contactCycleLangList} />

                <ActionsCollectives contactCycleLangList={contactCycleLangList} />

                <EventsSection contactCycleLangList={contactCycleLangList} />
            </div>
        </Layout>
    )
}

export default Workspace