import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import DisplayEventContactType from './DisplayEventContactType'
import useApiUrlTranslation from './useApiUrlTranslation';

const RegistrationFormModalitesCollaborationSection = ({ formulaire }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventContactTypeList, setEventContactTypeList] = useState();

    const getEventContactTypeList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventContactTypeList&filter=WHERE%20event_contact_type_is_active%20=1`)

            .then(response => response.json())
            .then(data => {
                setEventContactTypeList(data);
            })
    }



    useEffect(() => {
        getEventContactTypeList();
    }, [i18n.resolvedLanguage])


    if (!eventContactTypeList) return null


    return (
        <RegistrationFormSection
            title={formulaire.modalites_collaboration_section_title}
            instructions={formulaire.modalites_collaboration_section_instructions}
            formSectionId={35}>

            <DisplayEventContactType
                title_={t('form.sections.role_multiple_section.contribution_buisness_developper')}
                instructions_={t('form.sections.role_multiple_section.contribution_buisness_developper_instructions')}
                title="Modalité de collaboration myglobalvillage "
                instructions="Je souhaite participer aux actions collective de myglobalvillage en tant que :"
                eventContactTypeList={eventContactTypeList.filter(eventContactType => ["305", "306"].includes(eventContactType.id_event_contact_type))} />

        </RegistrationFormSection>
    )
}

export default RegistrationFormModalitesCollaborationSection