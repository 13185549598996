import React, { useEffect, useState } from 'react'
import PageSection from './PageSection'

import useApiUrlTranslation from './useApiUrlTranslation'
import Loader from './Loader';

function PlanActionListSection() {

    const [cycleLangList, setCycleLangList] = useState(false);

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();


    const handleClick = (cycleLang) => {

        if (cycleLang.cycle_lang_state !== 'draft') {
            window.open(`./collectif-categorie/collectif_planaction/${cycleLang.id_cycle_lang}`, '_blank');
        }
    }

    const getCycleLangList = async () => {

        const filter = ` cycle_lang_type IN('collectif_planaction') AND `

        await fetch(`${apiUrlTranslation}?action=getCycleLang&filter=${filter}&get_parents=0&get_children=0`)
            .then(res => res.json())
            .then(res => {
                setCycleLangList(
                    res.sort((a, b) => a.cycle_lang_state.localeCompare(b.cycle_lang_state))
                )
            })
    }

    useEffect(() => {
        getCycleLangList();
    }, [i18n.resolvedLanguage])


    return (
        <PageSection title="Planning d'actions collectives" darkmode>

            <div className="py-5 max-w-7xl mx-auto text-white">Sélectionnez les plans d'actions annuels qui correspondent à vos domaines d'activités ou vos usages verticaux. Et connectez vous avec plus de 700 écosystèmes privés, publics du monde entier.</div>

            <div className="flex flex-wrap space-x-1 space-y-1 mx-auto justify-center ">

                {!cycleLangList && <Loader />}
                {
                    cycleLangList && cycleLangList

                        .map(cycleLang => <>

                            <div
                                id_cycle_lang={cycleLang.id_cycle_lang}
                                key={cycleLang.id_cycle_lang}
                                cycle_lang_state={cycleLang.cycle_lang_state}
                                onClick={() => handleClick(cycleLang)}
                                target='_blank'
                                className={`${cycleLang.cycle_lang_state == 'draft' ? 'dark:bg-neutral-400/10' : 'dark:bg-neutral-800/10 hover:border-pink-600 dark:hover:border-pink-600 hover:cursor-pointer hover:scale-110 transition-transform duration-300'} p-2  bg-neutral-100 flex flex-col justify-between items-center w-40 border-x border-y border-neutral-200 dark:border-neutral-800 rounded-xl group `}>
                                <img alt="" src={cycleLang.logo_better.large}

                                    style={{
                                        filter: cycleLang.cycle_lang_state == 'draft' ? 'grayscale(100%)' : 'none'
                                    }}
                                    className={`mb-2 inset-x-0 top-0 aspect-square w-16 h-16 ${cycleLang.cycle_lang_state == 'draft' ? 'grayscale' : ''}`} />
                                <div className="text-sm text-gray- dark:text-neutral-400">{cycleLang.nom_cycle_lang}</div>
                                <p className={`${cycleLang.cycle_lang_state == 'draft' ? "hidden" : ""} dark:text-neutral-400 border-pink-500 my-2 text-xs px-4 border-x border-y py-2 rounded-full group-hover:bg-pink-600 group-hover:text-white`}> En savoir plus</p>

                                {cycleLang.cycle_lang_state == 'draft' && <p className={`text-green-400 rounded-full text-xs`}> En cours de développement</p>}

                            </div>
                        </>)
                }

            </div>

        </PageSection >
    )
}

export default PlanActionListSection