import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import useApiUrlTranslation from './useApiUrlTranslation';
import { useFormContext } from 'react-hook-form';
import DisplayEventContactType from './DisplayEventContactType';
import CycleLangSelection from './CycleLangSelection';

const RegistrationFormProfilEcosystemSection = ({ formulaire }) => {

    const { register } = useFormContext();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [eventContactTypeList, setEventContactTypeList] = useState();

    const getEventContactTypeList = async () => {

        await fetch(`${apiUrlTranslation}?action=getEventContactTypeList&filter=WHERE%20event_contact_type_is_active%20=1`)

            .then(response => response.json())
            .then(data => {
                setEventContactTypeList(data);
            })
    }


    useEffect(() => {
        getEventContactTypeList();
    }, [i18n.resolvedLanguage])


    if (!eventContactTypeList) return null

    return (
        <RegistrationFormSection
            formSectionId={32}
            title={formulaire.profil_ecosystem_section_title}
            instructions={formulaire.profil_ecosystem_section_instructions}
        >

            <DisplayEventContactType
                title_={t('form.sections.role_multiple_section.contribution_buisness_developper')}
                instructions_={t('form.sections.role_multiple_section.contribution_buisness_developper_instructions')}
                title="Modalité de collaboration myglobalvillage "
                instructions="Je souhaite participer aux actions collective de myglobalvillage en tant que :"
                eventContactTypeList={eventContactTypeList.filter(eventContactType => ["305", "306"].includes(eventContactType.id_event_contact_type))} />

            <CycleLangSelection
                formulaire={formulaire}
                idCycleLangList={[3240, 3242, 3241, 3239]}
                cycleLangSelection={
                    [
                        { id_cycle_lang: 3240, eventContactType: eventContactTypeList.find(eventContactType => eventContactType.id_event_contact_type == 237) },
                        { id_cycle_lang: 3242, eventContactType: eventContactTypeList.find(eventContactType => eventContactType.id_event_contact_type == 2) },
                        { id_cycle_lang: 3241, eventContactType: eventContactTypeList.find(eventContactType => eventContactType.id_event_contact_type == 307) },
                        { id_cycle_lang: 3239, eventContactType: eventContactTypeList.find(eventContactType => eventContactType.id_event_contact_type == 308) }
                    ]
                }
            />

        </RegistrationFormSection>
    )
}

export default RegistrationFormProfilEcosystemSection