import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "./Layout";
import RegistrationFormContactSection from "./RegistrationFormContactSection";
import { useForm, FormProvider } from "react-hook-form";
import RegistrationFormOrganismeSection from "./RegistrationFormOrganismeSection";
import RegistrationFormDemoSection from "./RegistrationFormDemoSection";
import RegistrationFormDroitImageSection from "./RegistrationFormDroitImageSection";
import axios from "axios";
import RegistrationFormJuryEventSection from "./RegistrationFormJuryEventSection";
import EventContext from "../contexts/EventContext";
import RegistrationFormCycleLangSection from "./RegistrationFormCycleLangSectionEventLigne";
import parse from "html-react-parser";
import RegistrationFormEventSection from "./RegistrationFormEventSection";
import RegistrationFormCoordinateurPresentationSection from "./RegistrationFormCoordinateurPresentationSection";
import RegistrationFormStatutSection from "./RegistrationFormStatutsSection";
import { isDev } from "../utils";
import { useTranslation } from "react-i18next";
import RegistrationFormCollectifPresentationSection from "./RegistrationFormCollectifPresentationSection";
import RegistrationFormPlanActionSection from "./RegistrationFormPlanActionSection";
import RegistrationFormZonesDecors from "./RegistrationFormZonesDecors";
import RegistrationFormParcoursThematiques from "./RegistrationFormParcoursThematiques";
import RegistrationFormCooptation from "./RegistrationFormCooptation";
import RegistrationFormParticipationDistance from './RegistrationFormParticipationDistance'
import RegistrationFormContactAndOrganismeSection from "./RegistrationFormContactAndOrganismeSection";
import Loader from "./Loader";
import { API_URL } from "../config";
import useApiUrlTranslation from "./useApiUrlTranslation";
import RegistrationFormPasswordSection from "./RegistrationFormPasswordSection";
import RegistrationFormTechFundRaiserSection from "./RegistrationFormTechFundRaiserSection";
//import RegistrationFormCasterSection from "./RegistrationFormCasterSection";
import RegistrationFormCasterSectionAcheteur from "./RegistrationFormCasterSectionAcheteur";
import RegistrationFormCasterSectionOffreur from "./RegistrationFormCasterSectionOffreur";
import RegistrationFormEventCycleLangSection from "./RegistrationFormEventCycleLangSection";
import RegistrationFormOnboardingSection from "./RegistrationFormOnboardingSection";
import RegistrationFormProfileCheckerSection from "./RegistrationFormProfileCheckerSection";
import RegistrationFormContactCommunauteSection from "./RegistrationFormContactCommunauteSection";
import { RegistrationFormSubtitleSection, RegistrationFormSupertitleSection } from "./RegistrationFormStyleSection";
import HTMLReactParser from "html-react-parser";
import RegistrationFormDelegationSection from "./RegistrationFormDelegationSection";
import RegistrationContactsOnboardingSection from "./RegistrationContactsOnboardingSection";
import RegistrationFormCycleLangPreselection from "./RegistrationFormCycleLangPreselection";
import RegistrationFormDemoSectionLight from "./RegistrationFormDemoSectionLight";
import PitchRegistrationCycleLangSelectionHeader from "./PitchRegistrationCycleLangSelectionHeader";
import RegistrationFormJuryEventCoordinatorToolsSection from "./RegistrationFormJuryEventCoordinatorToolsSection";
import RegistrationFormEngagementsContrepartiesSection from "./RegistrationFormEngagementsContrepartiesSection";
import RegistrationFormShoppingCartSection from "./RegistrationFormShoppingCartSection";
import RegistrationFormRoleMultipleSection from "./RegistrationFormRoleMultipleSection";
import RegistrationFormCycleLangSectionEventLigne from "./RegistrationFormCycleLangSectionEventLigne";
import RegistrationFormEventReferencementSection from "./RegistrationFormEventReferencementSection";
import RegistrationFormVideoPresentationSection from "./RegistrationFormVideoPresentationSection";
import RegistrationFormCreateCycleLang from "./RegistrationFormDemandeReferencementCycleLang";
import RegistrationFormDemandeReferencementCycleLang from "./RegistrationFormDemandeReferencementCycleLang";
import RegistrationFormYourProjectSection from "./RegistrationFormYourProjectSection";
import RegistrationFormCertification from "./RegistrationFormCertification";
import RegistrationFormPreviewSection from "./RegistrationFormPreviewSection";
import RegistrationFormCycleLangListSection from "./RegistrationFormCycleLangListSection";
import RegistrationFormProfilCooperationSection from "./RegistrationFormProfilCooperationSection";
import moment from "moment";
import CoordonneesChecker from "./CoordonneesChecker";
import RegistrationFormProfilEcosystemSection from "./RegistrationFormProfilEcosystemSection";
import RegistrationFormProfilInvestisseurSection from "./RegistrationFormProfilInvestisseurSection";
import RegistrationFormClienPartenaireSection from "./RegistrationFormClienPartenaireSection";
import RegistrationFormModalitesCollaborationSection from "./RegistrationFormModalitesCollaborationSection";
import RegistrationFormProgrammesActionSection from "./RegistrationFormProgrammesActionSection";


let formConfig = {
  displayContactSection: true,
  displayOrganismeSection: true,
  displayContactAndOrganismeSection: false,
  displayOnboardingSection: false,
  displayDemoSection: false,
  displayCollectifsTechnoSection: false,
  displayCollectifsSectoSection: false,
  displayCollectifsProgramme: false,
  displayCollectifsGeographiquesSection: true,
  collectifProgrammeListSectionTitle: "Programmes d'innovation",
  collectifProgrammeListSectionInstructions: "",
  collectifsGeographiquesSectionSubtitle: "",
  displayContactCommunauteSection: false,
  techreviewInstructions: "",
  pitchsSectionInstructions: "",
  displayTechFundRaiserSection: false,
  displayRegistrationFormPlanActionSection: false,
  displayTechreviewSection: false,
  displayCollectifsPlanActionSection: false,
  displayParcoursSection: false,
  displayPitchsSection: false,
  displayTempsFortsSection: false,
  displayTempsFortsSectionAll: false,
  displayTempsFortsExposantsSection: false,
  contactSectionSuperTitle: "",
  displayTempsFortsSectionConfeventTypeList: ['3', '13', '83', '86', '90', '78', '51', '62', '89'],
  displaySoiree: false,
  displayBreakfast: false,
  displayTexplorationsSection: false,
  displayStatutSection: false,
  displayEventSection: false,
  displayCooptationSection: false,
  displayRegistrationFormZonesDecors: false,
  displayParcoursThematiques: false,
  displayParticipationDistance: false,
  displayDroitImageSection: true,
  displayCoordinateurPresentationSection: true,
  displayEventCycleLangSection: false,
  displayCasterSectionOffreur: false,
  displayCasterSectionAcheteur: false,
  displayPasswordSection: true,
  displayCertificationSection: true,
  parcoursInstructions: 'Précisez ci-dessous les thématiques de parcours de visites sur lesquels vous souhaitez positionner votre solution ou votre cas client',
  pageTitle: "",
  pageSubtitle: "",
  conferencierStatut: '',
  formName: '',
  contactSource: '',
  idStatutSelection: null
}

const equipautoPageSubtitle = `
<p>Vous souhaitez exposer votre produit et/ou service au sein du démonstrateur « Le Garage du Futur : de demain à 2032 » EQUIP AUTO ?</p>
<p>Pour se faire, merci de remplir ce formulaire pour vous pré-positionner sur une ou plusieurs des 6 thématiques de démonstration.</p>
<p>Après examen de votre dossier par le Comité des Partenaires, vous serez amené à présenter votre innovation lors d’une session de pitch. Une fois sélectionné, vous rejoindrez le premier démonstrateur mondial de l’innovation et de la performance en matière de « Garage du Futur ».</p>
<p><u><b>Nota Bene</b></u> : Nous vous rappelons que les professionnels retenus doivent être présents sur leur corner dans le Garage du Futur pendant toute la durée du salon afin d’accueillir les groupes de visite et les visiteurs individuels.</p>

`

const participantParcoursOpenPageTitle = `
<p className="text-lg font-bold"> Le Centre commercial Innovant du Club Enseigne et Innovation est situé au cœur du salon. Il s’agit d’un espace d’animation au sein duquel vous découvrirez les révolutions de l’agencement et l’équipement des points de vente au moyen de démonstrations animées par des membres du Club.</p>
<p className=" font-bold pt-6">Nous avons conçu pour vous 6 parcours de visite sur le Centre commercial en lien avec les enjeux actuels des enseignes. Ces parcours ont pour ambition de vous présenter des solutions concrètes et dès à présent disponibles pour faire face aux défis d’aujourd’hui et de demain : </p>

<ul classname="pt-2 list-disc list-inside">
<li>	Augmenter son chiffre d'affaires au m2</li>
<li>	Maintenir son point de vente ouvert pendant les travaux</li>
<li>	Concevoir et maintenir son point de vente en intégrant les enjeux RSE</li>
<li>	Assurer la maîtrise des coûts d'exploitation</li>
<li>	Garantir la sécurité des biens et des personnes</li>
<li>	Concevoir des expériences innovantes. </li>
<ul>

<p classname="pt-2">Inscrivez-vous gratuitement pour profiter d'un parcours de visite du centre commercial innovant. 
Chaque exposant vous présentera une solution en 90 seconde maximum sur la thématique choisie. Vous pourrez ensuite échanger de manière plus étendue avec certains d'entre eux, en fonction des enjeux auxquels vous faites face. </p>

<p classname="pt-2">Il ne vous reste qu'à vous inscrire ci-après.</p> 

<p classname="pt-2">A très bientôt sur le salon !  </p>

<!--  <hr /><p className="text-lg font-bold">Le Club Enseigne et EquipMag rassemblent 20 solutions innovantes pour votre enseigne ou votre centre commercial dans un démonstrateur de 360m²</p>
<p className=" font-bold pt-6">Inscrivez-vous gratuitement pour profiter d'un parcours de visite du centre commercial performant<p>

  <p  classname="">Formés au pitch de présentation de leur solution ou de leur cas-clients en 90 secondes, les exposants vous proposent des parcours de visites thématiques.</p>

  <p className=" font-bold">Choisissez votre thématique ci-dessous et inscrivez-vous gratuitement pour profiter d'un parcours de visite</p>
  <p classname="">Tout au long de la journée, réunis en groupe de visite, les décideurs sont invités à participer à des parcours de démonstrations à heure fixe sur les thématiques de leur choix.</p>
  <p>Tout au long de la journée, réunis en groupe de visite, participez à des parcours de démonstrations à heure fixe sur les thématiques de leur choix (jusqu'à 6 thématiques possibles)</p>
  <p classname="pt-6">Bonne visite, à très vite sur le démonstrateur "Centre commercial innovant</p>
  -->
  `;
const PitchRegistration = () => {

  const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

  const [activeProgramme, setActiveProgramme] = useState(null);

  const [contact, setContact] = useState(null);

  const [presta, setPresta] = useState(null);

  const [eventCycleLang, setEventCycleLang] = useState(null);

  const [defaultValues, setDefaultValues] = useState(null);

  const [parcoursThematiquesSelection, setParcoursThematiquesSelection] = useState([]);

  const eventContext = useContext(EventContext);

  const [formIsSubmitted, setFormIsSubmitted] = useState(false);

  const [auteur, setAuteur] = useState(null);

  const [message, setMessage] = useState(false);

  const [formIsVisible, setFormIsVisible] = useState(false);

  const [packId, setPackId] = useState(false);

  const [idCycleLangPreselectionList, setIdCycleLangPreselectionList] = useState("yo");

  const [displayCollectifsGeographiquesSection, setDisplayCollectifsGeographiquesSection] = useState(formConfig.displayCollectifsGeographiquesSection);

  const [displayCollectifsSectoSection, setDisplayCollectifsSectoSection] = useState(formConfig.displayCollectifsSectoSection);

  const [displayContactCommunauteSection, setDisplayContactCommunauteSection] = useState(formConfig.displayContactCommunauteSection);

  const [displayTechFundRaiserSection, setDisplayTechFundRaiserSection] = useState(formConfig.displayTechFundRaiserSection);

  const [displayEventSection, setDisplayEventSection] = useState(formConfig.displayEventSection);

  const [formulaire, setFormulaire] = useState(null);

  const { currentEvent } = eventContext;

  const { formName = 'startup', coordinateur = '', id_cycle_lang, idEvent, idContact, id_event_cycle_lang, packSelection } = useParams();

  let collectifsGeographiquesSectionTitle = t('form.sections.collectifs_geographiques.section_title')
  let collectifsGeographiquesSectionInstruction = t('form.sections.collectifs_geographiques.section_instructions')

  switch (formName) {

    case 'coordinateur-parcours':
      formConfig.pageTitle = t('form.templates.coordinateur-parcours.page_title');
      formConfig.pageSubtitle = t('form.templates.coordinateur-parcours.page_subtitle')
      formConfig.displayCooptationSection = true
      formConfig.displayEventSection = false;
      formConfig.displayParcoursThematiques = true;
      formConfig.conferencierStatut = "coordinateur_parcours";
      formConfig.contactSource = "coordinateur_parcours"
      formConfig.formName = "coordinateur_parcours";
      formConfig.displayDroitImageSection = false;
      break;

    case 'startup':
      formConfig.pageTitle = id_cycle_lang == 2270 ? t('pages.inscription.title_global_challenge_startup') : t('form.templates.startup.page_title');
      formConfig.pageSubtitle = id_cycle_lang == 2270 ? t('pages.inscription.subtitle_global_challenge_equipauto_startup') : t('form.templates.startup.page_subtitle');
      formConfig.conferencierStatut = "offreur_de_solution";
      formConfig.contactSource = "pitch_registration";
      formConfig.formName = "pitch_registration";
      formConfig.displayContactAndOrganismeSection = idEvent == 1752 ? true : false;
      formConfig.displayContactSection = idEvent == 1752 ? false : true;
      formConfig.displayOrganismeSection = idEvent == 1752 ? false : true;
      formConfig.displayCollectifsTechnoSection = id_cycle_lang == 2270 ? false : false;
      formConfig.displayCollectifsSectoSection = id_cycle_lang == 2270 ? false : true;
      formConfig.displayCollectifsGeographiquesSection = id_cycle_lang == 2270 ? false : true;
      formConfig.displayEventSection = id_cycle_lang == 2270 ? false : false;
      formConfig.displayDemoSection = true;
      formConfig.displayPitchsSection = true;
      formConfig.displayParcoursSection = true;
      formConfig.displayTempsFortsSection = false
      formConfig.displayParticipationDistance = id_cycle_lang == 2270 ? false : true;
      formConfig.displayRegistrationFormPlanActionSection = ["planaction", 'startup'].includes(formName) && ['2270', '2302'].includes(id_cycle_lang) ? true : false;
      formConfig.collectifProgrammeListSectionTitle = "Domaines d'innovation ou d'usage de ma solution";
      break;

    case 'startup-parcours':
      formConfig.pageTitle = "Formulaire de finalisation de votre participation au démonstrateur";
      formConfig.pageSubtitle = '<p>Je remplis ce formulaire afin de positionner mes solutions dans la zone thématique du démonstrateur<p></p>';
      formConfig.conferencierStatut = "offreur_de_solution";
      formConfig.contactSource = "startup-parcours";
      formConfig.formName = "startup-parcours";
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayRegistrationFormPlanActionSection = true;
      formConfig.displayDemoSection = idEvent == 1731 ? false : true;
      formConfig.displayPitchsSection = true;
      formConfig.displayParcoursSection = true;
      formConfig.displayRegistrationFormZonesDecors = false;
      formConfig.displayEventSection = false;
      formConfig.displayCollectifsPlanActionSection = false;
      formConfig.displayRegistrationFormPlanActionSection = false;
      formConfig.collectifProgrammeListSectionTitle = "Domaines d'innovation ou d'usage de ma solution"
      break;

    case 'coordinateur-delegation':
      formConfig.conferencierStatut = "coordinateur-de-la-da-la-gation";
      formConfig.contactSource = "coordinateur-de-la-da-la-gation";
      formConfig.formName = "coordinateur-de-la-da-la-gation";

      break

    case 'techproviders':
    case 'techprovider':

      formConfig.pageTitle = "Formulaire de finalisation de votre participation ";
      formConfig.pageSubtitle = '<p>Je remplis ce formulaire afin de positionner mes solutions dans la zone thématique du démonstrateur<p></p>';
      formConfig.conferencierStatut = "offreur_de_solution";
      formConfig.contactSource = "techproviders";
      formConfig.formName = "techproviders";
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayDemoSection = idEvent == 1731 ? false : true;
      formConfig.displayPitchsSection = false;
      formConfig.displayParcoursSection = true;
      formConfig.displayTechreviewSection = false;
      formConfig.displayRegistrationFormZonesDecors = false;
      formConfig.displayEventSection = false;
      formConfig.displayCollectifsPlanActionSection = false;
      formConfig.displayCollectifsProgramme = true;
      formConfig.collectifProgrammeListSectionTitle = "Domaines d'innovation ou d'usage de ma solution"
      formConfig.displayTechFundRaiserSection = false;
      formConfig.techreviewInstructions = "Contrairement aux pitchs, les tech'revues ne sont pas des compétitions. Elles rassemblent des fournisseurs de toutes tailles pour mettre en avant vos solutions lors de sessions centrées sur des thèmes spécifiques. Ces présentations s'adressent aux distributeurs de technologie, que ce soit en personne, à distance ou en différé. Vous avez 2 minutes 30 pour mettre en lumière un cas-client, une nouveauté ou un projet. Lors de votre prise de parole, la vidéo ou les diapositives PowerPoint téléchargées ci-dessous seront projetées (sans audio) pour appuyer votre discours."
      formConfig.pitchsSectionInstructions = "Les sessions de pitch mettent en avant des startups ou des porteurs de projets novateurs en quête de financement et de leurs premiers clients, qu'ils soient nationaux ou internationaux. Vous avez 2 minutes 30 pour détailler un cas-client, une innovation ou un projet. Durant votre exposé, la vidéo ou les diapositives PowerPoint téléchargées ci-dessous seront diffusées (sans audio) pour enrichir votre présentation.";
      break;

    case 'partenaire-exposant':
      formConfig.pageTitle = "Formulaire de finalisation de votre participation au démonstrateur";
      formConfig.pageSubtitle = `<p>Je remplis ce formulaire afin de finaliser ma présence sur le démonstrateur. Je décris la démonstration que j’effectuerai sur mon stand.  
      Je me positionne également pour présenter mes solutions lors des temps forts prévus sur le plateau de webcast</p>`;
      formConfig.conferencierStatut = "partenaire-exposant";
      formConfig.contactSource = "partenaire-exposant";
      formConfig.formName = "partenaire-exposant";
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayRegistrationFormPlanActionSection = true;
      formConfig.displayDemoSection = idEvent == 1731 ? false : true;
      formConfig.displayPitchsSection = true;
      formConfig.displayParcoursSection = true;
      formConfig.displayRegistrationFormZonesDecors = false;
      formConfig.displayEventSection = false;
      formConfig.displayCollectifsPlanActionSection = false;
      formConfig.displayRegistrationFormPlanActionSection = false;
      formConfig.displayTempsFortsExposantsSection = true;
      formConfig.displayPitchsSection = true;

      break;


    case 'techprovider-greater-regions':
    case 'techprovider-mgv':

      formConfig.pageTitle = t('form.templates.techprovider.page_title');
      formConfig.pageSubtitle = t('form.templates.techprovider.page_subtitle');
      formConfig.conferencierStatut = "offreur_de_solution";
      formConfig.contactSource = "techprovider";
      formConfig.formName = "techprovider";
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayRegistrationFormPlanActionSection = true;
      formConfig.displayDemoSection = idEvent == 1731 ? false : true;
      formConfig.displayPitchsSection = true;
      formConfig.displayParcoursSection = true;
      formConfig.displayRegistrationFormZonesDecors = false;
      formConfig.displayEventSection = false;
      formConfig.displayCollectifsPlanActionSection = false;
      formConfig.displayRegistrationFormPlanActionSection = false;
      formConfig.displayParticipationDistance = false;
      break;

    case 'techprovider-fast':
    case 'techprovider-greater-regions-fast':
    case 'techprovider-mgv-fast':
      formConfig.pageTitle = t('form.templates.startup-onboarding-fast.page_title');
      formConfig.pageSubtitle = t('form.templates.startup-onboarding-fast.page_subtitle');
      collectifsGeographiquesSectionTitle = t('form.templates.startup-onboarding.collectifsGeographiquesSectionTitle')
      collectifsGeographiquesSectionInstruction = t('form.templates.startup-onboarding.collectifsGeographiquesSectionInstruction');
      formConfig.conferencierStatut = "offreur_de_solution";
      formConfig.contactSource = "techprovider"
      formConfig.formName = "techprovider";
      formConfig.displayContactAndOrganismeSection = true;
      formConfig.displayContactSection = false;
      formConfig.displayOrganismeSection = false;
      formConfig.displayPitchsSection = false;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayTechFundRaiserSection = false;
      formConfig.displayDroitImageSection = false;
      formConfig.displayEventCycleLangSection = idEvent == 1752 ? true : false;
      formConfig.displayPasswordSection = idEvent == 1752 ? false : false;
      formConfig.displayTempsFortsExposantsSection = true;
      formConfig.displayDemoSection = true;

      break;

    case 'participant-parcours':
      formConfig.pageTitle = "Inscription en tant que participant aux parcours de visite";
      formConfig.pageSubtitle = 'Je remplis ce formulaire afin de participer gratuitement au parcours de visite  </p>';
      formConfig.conferencierStatut = "participant";
      formConfig.contactSource = "participant";
      formConfig.formName = "participant";
      formConfig.parcoursInstructions = t('form.sections.parcours_de_visite_ouvert.section_instructions');
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayDemoSection = false;
      formConfig.displayPitchsSection = false;
      formConfig.displayParcoursSection = true;
      formConfig.displayRegistrationFormZonesDecors = false;
      formConfig.displayEventSection = false;
      formConfig.displayTempsFortsSection = false;
      formConfig.displayParcoursThematiques = false;
      formConfig.displayDroitImageSection = false;
      break;

    case 'participant-delegation':
      formConfig.pageTitle = `${t('form.templates.participant-delegation.page_title')} ${currentEvent?.nom} `;
      formConfig.pageSubtitle = t('form.templates.participant-delegation.page_subtitle');
      formConfig.conferencierStatut = "participant-delegation";
      formConfig.contactSource = "participant-delegation";
      formConfig.formName = "participant-delegation";
      formConfig.parcoursInstructions = t('form.sections.parcours_de_visite_ouvert.section_instructions');
      formConfig.displayEventSection = false;
      formConfig.displayDroitImageSection = false;
      formConfig.displayTempsFortsSectionAll = true;
      /* formConfig.displayCollectifsTechnoSection = false;
       formConfig.displayCollectifsSectoSection = false;
       formConfig.displayCollectifsGeographiquesSection = false;
       formConfig.displayDemoSection = false;
       formConfig.displayPitchsSection = false;
       formConfig.displayParcoursSection = true;
       formConfig.displayRegistrationFormZonesDecors = false;
       formConfig.displayEventSection = false;
       formConfig.displayTempsFortsSection = false;
       formConfig.displayParcoursThematiques = false;
       formConfig.displayDroitImageSection = false;
       */
      break;

    case 'participant-delegation-soiree':
      formConfig.pageTitle = t('form.templates.participant-delegation-soiree.page_title') + currentEvent?.nom;
      formConfig.pageSubtitle = t('form.templates.participant-delegation-soiree.page_subtitle');
      formConfig.conferencierStatut = "participant-delegation-soiree";
      formConfig.contactSource = "participant-delegation-soiree";
      formConfig.formName = "participant-delegation-soiree";
      formConfig.parcoursInstructions = t('form.sections.parcours_de_visite_ouvert.section_instructions');
      formConfig.displayEventSection = false;
      formConfig.displayDroitImageSection = false;
      formConfig.displaySoiree = true;
      formConfig.displayTempsFortsSectionAll = true;

      /* formConfig.displayCollectifsTechnoSection = false;
       formConfig.displayCollectifsSectoSection = false;
       formConfig.displayCollectifsGeographiquesSection = false;
       formConfig.displayDemoSection = false;
       formConfig.displayPitchsSection = false;
       formConfig.displayParcoursSection = true;
       formConfig.displayRegistrationFormZonesDecors = false;
       formConfig.displayEventSection = false;
       formConfig.displayTempsFortsSection = false;
       formConfig.displayParcoursThematiques = false;
       formConfig.displayDroitImageSection = false;
       */
      break;

    case 'participant-delegation-soiree-breakfast':
      formConfig.pageTitle = t('form.templates.participant-delegation-soiree-breakfast.page_title') + currentEvent?.nom;
      formConfig.pageSubtitle = t('form.templates.participant-delegation-soiree-breakfast.page_subtitle');
      formConfig.conferencierStatut = "participant-delegation-soiree-breakfast";
      formConfig.contactSource = "participant-delegation-soiree-breakfast";
      formConfig.formName = "participant-delegation-soiree-breakfast";
      formConfig.parcoursInstructions = t('form.sections.parcours_de_visite_ouvert.section_instructions');
      formConfig.displayEventSection = false;
      formConfig.displayDroitImageSection = false;
      formConfig.displaySoiree = true;
      formConfig.displayBreakfast = true;
      formConfig.displayTempsFortsSectionAll = true;

      /* formConfig.displayCollectifsTechnoSection = false;
       formConfig.displayCollectifsSectoSection = false;
       formConfig.displayCollectifsGeographiquesSection = false;
       formConfig.displayDemoSection = false;
       formConfig.displayPitchsSection = false;
       formConfig.displayParcoursSection = true;
       formConfig.displayRegistrationFormZonesDecors = false;
       formConfig.displayEventSection = false;
       formConfig.displayTempsFortsSection = false;
       formConfig.displayParcoursThematiques = false;
       formConfig.displayDroitImageSection = false;
       */
      break;

    case 'participant-parcours-open':
      formConfig.pageTitle = t('form.templates.participant-parcours-open.page_title');
      formConfig.pageSubtitle = t('form.templates.participant-parcours-open.page_subtitle');//participantParcoursOpenPageTitle;
      formConfig.parcoursInstructions = t('form.sections.parcours_de_visite_ouvert.section_instructions');
      formConfig.conferencierStatut = "participant";
      formConfig.contactSource = "participant";
      formConfig.formName = "participant";
      formConfig.displayContactSection = false;
      formConfig.displayOrganismeSection = false;
      formConfig.displayContactAndOrganismeSection = true;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayDemoSection = false;
      formConfig.displayPitchsSection = false;
      formConfig.displayParcoursSection = true;
      formConfig.displayRegistrationFormZonesDecors = false;
      formConfig.displayEventSection = false;
      formConfig.displayTempsFortsSection = false;
      formConfig.displayParcoursThematiques = false;
      formConfig.displayDroitImageSection = false;
      formConfig.displayCoordinateurPresentationSection = true;
      break;

    case 'jury':
      formConfig.pageTitle = t('form.templates.jury.page_title');
      formConfig.pageSubtitle = t('form.templates.jury.page_subtitle');
      formConfig.conferencierStatut = "jury";
      formConfig.contactSource = "inscription_jury"
      formConfig.formName = "inscription_jury";
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayPitchsSection = true;
      formConfig.displayStatutSection = true;
      formConfig.displayParticipationDistance = true;
      formConfig.idStatutSelection = [77, 79, 80, 81, 82, 83, 84, 90, 91];
      break;

    case 'innovation-leader-onboarding':
      formConfig.pageTitle = "Formulaire de finalisation de votre participation";
      formConfig.pageSubtitle = `<div className="flex">  
      <img  src="https://www.mlg-consulting.com/manager_cc/contacts/img_uploaded/thumbs/photo_1389_220430160942_200x0.png" className="rounded w-25 mr-3" /> 
      <div  className="">
          <p>En tant que décideur innovation, acheteur, utilisateur final ou prescripteurs  nous sommes heureux de vous accueillir au sein du cercle mondial des "Innovation leaders" de myGlobalVillage.</p> 
          <p>C'est grâce, votre capacité à dénicher de vraies pépites, à vos questions aux entrepreneurs et vos votes finaux que myGlobalVillage peut détecter et labeliser par an plus de 900 solutions innovantes sur 5 continents.</p>
          <p> Selon vos disponibilités, rejoignez sans modération la liste des jurés, des "tech'xplorateurs" ou des "tech"xporters" en cochant les créneaux de votre choix ci-dessous. Un grand merci pour votre contribution.</p>
          <p>Bienvenue, vous êtes désormais chez vous partout sue la planète de l'innovation avec myGlobalVillage.</p>
          <p className="mt-2 font-bold">Marc Lionnel Gatto</p>
          <p>Fondateur myGlobalVillage</p></div></div>`;
      formConfig.conferencierStatut = "jury";
      formConfig.contactSource = "inscription_jury"
      formConfig.formName = "inscription_jury";
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayPitchsSection = false;
      formConfig.displayTechreviewSection = false;
      formConfig.displayStatutSection = true;
      formConfig.displayParticipationDistance = true;
      formConfig.idStatutSelection = [77, 79, 80, 81, 82, 83, 84, 90, 91];
      formConfig.pitchsSectionInstructions = `Les sessions de pitch vous présentent des startups ou des porteurs de projets innovants qui recherchent du financement, des distributeurs et leurs premiers clients.
      En tant que juré les candidats disposent de 1 minutes30 pour présenter un cas-client, une innovation ou un projet. Pendant votre intervention la video ou les visuels PowerPoint chargé       ci-dessous seront présentés (sans son) pour illustrer votre intervention.`;
      formConfig.techreviewInstructions = `Les tech'reviews sont conçues pour vous aider à sourcer les solutions les plus
      innovantes et performantes d'un même thématique ou d'une chaine de valeur. Elles
      regroupent des prestataires de toutes tailles En tant que décideurs, acheteur, utilisateur final, consultant, soft-lander, distributeurs de technologie (sur place, à distance ou en replay) vous pouvez poser des questions et vous mettre en contact avec les solutions qui vous
      intéressent. Si vous êtes labélisés en tant que «tech'xporter (importateur) exportateur de technologies) vous pouvez vous positionner pour être le premier à distribuer cette technologie dans votre région/province/pays.`;

      formConfig.displayCollectifsProgramme = true;
      formConfig.collectifProgrammeListSectionTitle = "Détail de mes univers de sourcing ou d'investissement"
      formConfig.collectifProgrammeListSectionInstructions = "Détaillez ci-dessous les domaines dans lesquels vous recherchez des solutions ou vous investissez";
      break;



    case 'jury-onboarding':
      formConfig.pageTitle = "Vous souhaitez participer en tant que juré de session de pitch";
      formConfig.pageSubtitle = `< p > Ce formulaire vous permet de vous pré - positionner à l’année parmi les jurés officiels des sessions de pitch des événements majeurs de votre secteur.En tant que jurés vous découvrirez lors de leur sortie les innovations et les startups les plus disruptives et pourrez vous mettre en relation avec les candidats de votre choix.</ >
      <p>Pour vos pré-positionner, cochez simplement les cases et les événements et les dates ci-dessous. Quelques semaines avant l’événement, votre coordinateur de jury vous fera parvenir un formulaire de finalisation de votre participation qui vous permettra de cochez les heures et les créneaux de pitch qui vous concernent.</p>
      <p>Merci de votre participation et de votre soutien aux temps forts de myGlobalVillage</p>`;
      formConfig.conferencierStatut = "jury";
      formConfig.contactSource = "inscription_jury"
      formConfig.formName = "inscription_jury";
      formConfig.displayPitchsSection = true;
      break;

    case 'jury-onboarding-fast':
      formConfig.pageTitle = "Vous souhaitez participer en tant que juré de session de pitch";
      formConfig.pageSubtitle = `< p > Ce formulaire vous permet de vous pré - positionner à l'année parmi les jurés officiels des sessions de pitch des événements majeurs de votre secteur.En tant que jurés vous découvrirez lors de leur sortie les innovations et les startups les plus disruptives et pourrez vous mettre en relation avec les candidats de votre choix.</ >
        <p>Pour vos pré-positionner, cochez simplement les cases et les événements et les dates ci-dessous. Quelques semaines avant l’événement, votre coordinateur de jury vous fera parvenir un formulaire de finalisation de votre participation qui vous permettra de cochez les heures et les créneaux de pitch qui vous concernent.</p>
        <p>Merci de votre participation et de votre soutien aux temps forts de myGlobalVillage</p>`;
      formConfig.conferencierStatut = "jury";
      formConfig.contactSource = "inscription_jury"
      formConfig.formName = "inscription_jury";
      formConfig.displayPitchsSection = true;
      formConfig.displayContactAndOrganismeSection = true;
      formConfig.displayContactSection = false;
      formConfig.displayOrganismeSection = false;
      formConfig.displayTempsFortsSectionConfeventTypeList = ["67", "74", "88"];
      break;

    case 'startup-onboarding':
      formConfig.pageTitle = t('form.templates.startup-onboarding.page_title')
      formConfig.pageSubtitle = t('form.templates.startup-onboarding.page_subtitle');
      collectifsGeographiquesSectionTitle = t('form.templates.startup-onboarding.collectifsGeographiquesSectionTitle');

      collectifsGeographiquesSectionInstruction = t('form.templates.startup-onboarding.collectifsGeographiquesSectionInstruction');
      formConfig.conferencierStatut = "offreur_de_solution";
      formConfig.contactSource = "startup-onboarding"
      formConfig.formName = "startup-onboarding";
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayStatutSection = true;
      formConfig.displayDemoSection = true;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = true;
      formConfig.displayCasterSectionOffreur = true;
      formConfig.displayTechFundRaiserSection = true;
      formConfig.displayDroitImageSection = false;
      formConfig.displayEventSection = true;
      formConfig.displayCertificationSection = true;
      formConfig.displayPitchsSection = false;
      formConfig.displayFormEventsSection = true;
      formConfig.displayContactCommunauteSection = false;
      formConfig.contactSectionSuperTitle = <RegistrationFormSupertitleSection>{t('form.sections.contact_section.section_supertitle')}</RegistrationFormSupertitleSection>;
      collectifsGeographiquesSectionTitle = t('form.templates.startup-onboarding.collectifsGeographiquesSectionTitle');
      formConfig.collectifsGeographiquesSectionSubtitle = <RegistrationFormSubtitleSection>{t('form.templates.startup-onboarding.collectifsGeographiques_section_subtitle')}</RegistrationFormSubtitleSection>
      collectifsGeographiquesSectionInstruction = t('form.templates.startup-onboarding.collectifsGeographiquesSectionInstruction')

      formConfig.collectifsGeographiquesSection_notabene = t('form.templates.startup-onboarding.collectifsGeographiquesSectionInstruction')
      formConfig.sectionCollectifsSectorielsTitle = "<p>Accompagnement à la détection et prospection de clients finaux pour vos produits";
      formConfig.sectionCollectifsSectorielsInstructions = `<p>A l’issue de votre pitch le cercle mondial de Tech’xplainer de myGlobalVillage va analyser votre solution / startup(et votre pitch video).Les tech’xplainer sont des coordinateurs de programme d’accélération en groupe spécialistes d’un secteur ou d’une industrie donnée.Afin de mobiliser des clients finaux les Tech’xplainer rassemblent les meilleures startups / solutions non concurrentes(présentation d’une chaine de valeur complète) dans le cadre de Tech’reviews.Ces présentations sont effectuées en ligne devant des distributeurs pays potentiels mais également en public voire sur des stands hébergeurs lors des grands événements annuels de la technologie de ou votre industrie.</p>
 <p> Afin de vous associer à des actions de prospection collaborative, cochez ci-dessous les univers d’usages dans lesquelles votre solution est efficace: </p>`
      formConfig.idStatutSelection = [75, 74, 76];
      break;

    case 'startup-onboarding-fast':
      formConfig.pageTitle = t('form.templates.startup-onboarding-fast.page_title');
      formConfig.pageSubtitle = t('form.templates.startup-onboarding-fast.page_subtitle');
      collectifsGeographiquesSectionTitle = t('form.templates.startup-onboarding.collectifsGeographiquesSectionTitle')
      collectifsGeographiquesSectionInstruction = t('form.templates.startup-onboarding.collectifsGeographiquesSectionInstruction');
      formConfig.conferencierStatut = "offreur_de_solution";
      formConfig.contactSource = "startup-onboarding"
      formConfig.formName = "startup-onboarding";
      formConfig.displayContactAndOrganismeSection = true;
      formConfig.displayContactSection = false;
      formConfig.displayOrganismeSection = false;
      formConfig.displayPitchsSection = false;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayTechFundRaiserSection = false;
      formConfig.displayDroitImageSection = false;
      formConfig.displayEventCycleLangSection = idEvent == 1752 ? true : false;
      formConfig.displayPasswordSection = idEvent == 1752 ? false : false;
      formConfig.displayTempsFortsExposantsSection = false;
      formConfig.displayDemoSection = true;

      break;

    case 'exposant-demonstrateur':
      formConfig.pageTitle = "Formulaire d'inscription exposant démonstrateur";
      formConfig.pageSubtitle = t('form.templates.startup-onboarding-fast.page_subtitle');
      collectifsGeographiquesSectionTitle = t('form.templates.startup-onboarding.collectifsGeographiquesSectionTitle')
      collectifsGeographiquesSectionInstruction = t('form.templates.startup-onboarding.collectifsGeographiquesSectionInstruction');
      formConfig.conferencierStatut = "offreur_de_solution";
      formConfig.contactSource = "exposant-demonstrateur"
      formConfig.formName = "exposant-demonstrateur";
      formConfig.displayContactAndOrganismeSection = true;
      formConfig.displayContactSection = false;
      formConfig.displayOrganismeSection = false;
      formConfig.displayPitchsSection = false;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayTechFundRaiserSection = false;
      formConfig.displayDroitImageSection = false;
      formConfig.displayEventCycleLangSection = idEvent == 1752 ? true : false;
      formConfig.displayPasswordSection = idEvent == 1752 ? false : false;
      formConfig.displayTempsFortsExposantsSection = true;
      formConfig.displayDemoSection = true;

      break;

    case 'coordinateur-techxploration-onboarding':
      formConfig.pageTitle = "Coordinateurs de Tech'xploration : formulaire de finalisation de votre participation";
      formConfig.pageSubtitle = `Vous avez accepté d'être coordinateur des tech’xplorations de myGlobalVillage. 
    Ce formulaire permet de vous positionner sur les temps forts de l’événement selon votre agenda et vos domaines d’intérêt: 
      Sélectionnez ci - dessous les temps forts de votre choix
  < ul >
        <li>- briefing et départ des groupes de tech’xploration</li>
        <li>- sessions de pitch et tech’review </li>
        <li>- webcast de débriefing avec les partenaires</li>
      </ >
  <p>Vous pourrez également vous pré-positionner sur les événements annuels internationaux auxquels vous souhaitez participer.</p> 
      Merci de votre engagement et de votre soutien au collectif de myGlobalVillage`;
      collectifsGeographiquesSectionTitle = t('form.templates.startup-onboarding.collectifsGeographiquesSectionTitle')
      collectifsGeographiquesSectionInstruction = t('form.templates.startup-onboarding.collectifsGeographiquesSectionInstruction');
      formConfig.conferencierStatut = "coordinateur-techxploration-onboarding";
      formConfig.contactSource = "coordinateur-techxploration-onboarding"
      formConfig.formName = "coordinateur-techxploration-onboarding";
      formConfig.displayPitchsSection = false;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayCasterSectionOffreur = false;
      formConfig.displayTechFundRaiserSection = false;
      formConfig.displayEventSection = true;
      formConfig.displayTempsFortsSectionAll = true;
      break;

    case 'techxplorateur-expert':
      formConfig.pageTitle = "Coordinateurs de Tech'xploration : formulaire de finalisation de votre participation";
      formConfig.pageSubtitle = `Vous avez accepté d'être coordinateur des tech’xplorations de myGlobalVillage. 
      Ce formulaire permet de vous positionner sur les temps forts de l’événement selon votre agenda et vos domaines d’intérêt: 
        Sélectionnez ci - dessous les temps forts de votre choix
  < ul >
          <li>- briefing et départ des groupes de tech’xploration</li>
          <li>- sessions de pitch et tech’review </li>
          <li>- webcast de débriefing avec les partenaires</li>
        </ >
  <p>Vous pourrez également vous pré-positionner sur les événements annuels internationaux auxquels vous souhaitez participer.</p> 
        Merci de votre engagement et de votre soutien au collectif de myGlobalVillage`;
      collectifsGeographiquesSectionTitle = t('form.templates.startup-onboarding.collectifsGeographiquesSectionTitle')
      collectifsGeographiquesSectionInstruction = t('form.templates.startup-onboarding.collectifsGeographiquesSectionInstruction');
      formConfig.conferencierStatut = "techxplorateur-expert";
      formConfig.contactSource = "techxplorateur-expert"
      formConfig.formName = "techxplorateur-expert";
      formConfig.displayPitchsSection = false;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayCasterSectionOffreur = false;
      formConfig.displayTechFundRaiserSection = false;
      formConfig.displayEventSection = true;
      formConfig.displayTempsFortsSectionAll = true;
      break;

    case 'coordinateur-programme-onboarding':
      formConfig.pageTitle = "Coordinateurs de Tech'xploration : formulaire de finalisation de votre participation";
      formConfig.pageSubtitle = `Vous avez accepté d'être coordinateur des tech’xplorations de myGlobalVillage. 
    Ce formulaire permet de vous positionner sur les temps forts de l’événement selon votre agenda et vos domaines d’intérêt: 
      Sélectionnez ci - dessous les temps forts de votre choix
  < ul >
        <li>- briefing et départ des groupes de tech’xploration</li>
        <li>- sessions de pitch et tech’review </li>
        <li>- webcast de débriefing avec les partenaires</li>
      </ >
  <p>Vous pourrez également vous pré-positionner sur les événements annuels internationaux auxquels vous souhaitez participer.</p> 
      Merci de votre engagement et de votre soutien au collectif de myGlobalVillage`;
      collectifsGeographiquesSectionTitle = t('form.templates.startup-onboarding.collectifsGeographiquesSectionTitle')
      collectifsGeographiquesSectionInstruction = t('form.templates.startup-onboarding.collectifsGeographiquesSectionInstruction');
      formConfig.conferencierStatut = "coordinateur-techxploration-onboarding";
      formConfig.contactSource = "coordinateur-techxploration-onboarding"
      formConfig.formName = "coordinateur-techxploration-onboarding";
      formConfig.displayPitchsSection = false;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayCasterSectionOffreur = false;
      formConfig.displayTechFundRaiserSection = false;
      formConfig.displayEventSection = true;
      formConfig.displayTempsFortsSectionAll = true;
      break;

    case 'coordinateur-geographique-onboarding':
      formConfig.pageTitle = "Coordinateur géographique : formulaire de finalisation de votre participation";
      collectifsGeographiquesSectionTitle = t('form.templates.startup-onboarding.collectifsGeographiquesSectionTitle')
      collectifsGeographiquesSectionInstruction = t('form.templates.startup-onboarding.collectifsGeographiquesSectionInstruction');
      formConfig.conferencierStatut = "coordinateur_geographique";
      formConfig.contactSource = "coordinateur_geographique-onboarding"
      formConfig.formName = "coordinateur-geographique-onboarding";
      formConfig.displayPitchsSection = false;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayCasterSectionOffreur = false;
      formConfig.displayTechFundRaiserSection = false;
      formConfig.displayEventSection = true;

      break;

    case 'speaker':

      formConfig.pageTitle = t('form.templates.speaker.page_title');
      formConfig.pageSubtitle = t('form.templates.speaker.page_subtitle');
      formConfig.conferencierStatut = "intervenant";
      formConfig.contactSource = "speaker_registration"
      formConfig.formName = "speaker_registration";
      formConfig.displayEventSection = false;
      formConfig.displayCoordinateurPresentationSection = true;
      formConfig.displayTempsFortsSectionAll = true;
      break;

    case 'contributeur':
      formConfig.displayTempsFortsSection = true;
      formConfig.pageTitle = t('form.templates.contributeur.page_title');
      formConfig.pageSubtitle = t('form.templates.contributeur.page_subtitle');
      formConfig.conferencierStatut = "contributeur";
      formConfig.contactSource = "contributeur"
      formConfig.formName = "contributeur";
      formConfig.displayEventSection = false;
      formConfig.displayDroitImageSection = false;
      formConfig.displayTexplorationsSection = true;
      formConfig.displayPitchsSection = true;
      break;

    case 'techxplorateur':
      formConfig.displayTempsFortsSection = false;
      formConfig.pageTitle = t('form.templates.techxplorateur.page_title');
      formConfig.pageSubtitle = t('form.templates.techxplorateur.page_subtitle');
      formConfig.conferencierStatut = "techxplorateur";
      formConfig.contactSource = "techxplorateur"
      formConfig.formName = "techxplorateur";
      formConfig.displayEventSection = false;
      formConfig.displayDroitImageSection = false;
      formConfig.displayTexplorationsSection = true;
      formConfig.displayPitchsSection = false;
      break;

    case 'copil':
      formConfig.displayTempsFortsSection = true;
      formConfig.pageTitle = "Formulaire de participation au comité de pilotage";
      formConfig.pageSubtitle = `Si vous avez déjà participé à un événement de myGLobalVillage NE PAS REMPLIR CE FORMULAIRE. 
      Demandez à rejoindre le comité de pilotage et les équipes d'organisation vous positionneront dans le collectif`;
      formConfig.conferencierStatut = "copil";
      formConfig.contactSource = "copil"
      formConfig.formName = "copil";
      break;

    case 'partenaire_officiel':
      formConfig.displayTempsFortsSection = true;
      formConfig.pageTitle = "Formulaire de participation partenaire";
      formConfig.pageSubtitle = `Si vous avez déjà participé à un événement de myGLobalVillage NE PAS REMPLIR CE FORMULAIRE. Demandez à rejoindre le comité de pilotage et les équipes d'organisation vous positionneront dans le collectif`;
      formConfig.conferencierStatut = "partenaire_officiel";
      formConfig.contactSource = "partenaire_officiel"
      formConfig.formName = "partenaire_officiel";
      formConfig.displayTempsFortsSectionConfeventTypeList = [];
      break;

    case 'soutien':
      formConfig.displayTempsFortsSection = true;
      formConfig.pageTitle = t('form.templates.soutien.page_title');
      formConfig.pageSubtitle = t('form.templates.soutien.page_subtitle');;
      formConfig.conferencierStatut = "soutien";
      formConfig.contactSource = "soutien"
      formConfig.formName = "soutien";
      break;

    case 'coordinateur-jury':
      formConfig.displayTempsFortsSection = true;
      formConfig.displayStatutSection = true;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayCooptationSection = false;
      formConfig.pageTitle = t('form.templates.coordinateur-jury.page_title');
      formConfig.pageSubtitle = t('form.templates.coordinateur-jury.page_subtitle');
      formConfig.conferencierStatut = "coordinateur_jury";
      formConfig.contactSource = "coordinateur_jury"
      formConfig.formName = "coordinateur_jury";
      formConfig.idStatutSelection = [77, 79, 80, 81, 82, 83, 84, 90, 91];
      break;

    case 'onboarding':
      formConfig.pageTitle = "Formulaire de prépositionnement";
      formConfig.displayTempsFortsSection = true;
      formConfig.displayOnboardingSection = true;
      formConfig.displayCollectifsPlanActionSection = true;
      break;

    case 'coordinateur-pitch':
      formConfig.displayTempsFortsSection = true;
      formConfig.displayStatutSection = true;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayCooptationSection = false;
      formConfig.pageTitle = t('form.templates.coordinateur-pitch.page_title');
      formConfig.pageSubtitle = t('form.templates.coordinateur-pitch.page_subtitle');
      formConfig.sectionTempsfortsTitle = t('form.sections.tempsforts_section.section_title_coordinateur_pitch');
      formConfig.sectionTempsfortsInstructions = t('form.sections.tempsforts_section.section_instructions_coordinateur_pitch');
      formConfig.conferencierStatut = "coordinateur_pitch";
      formConfig.contactSource = "coordinateur_pitch"
      formConfig.formName = "coordinateur_pitch";
      formConfig.idStatutSelection = [77, 79, 80, 81, 82, 83, 84, 90, 91];
      formConfig.displayTempsFortsSectionConfeventTypeList = ["67", "74", "88"];
      break;

    case 'coordinateur-parcours':
      formConfig.displayTempsFortsSection = true;
      formConfig.displayStatutSection = true;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayCooptationSection = true;
      formConfig.pageTitle = t('form.templates.coordinateur-jury.page_title');
      formConfig.pageSubtitle = t('form.templates.coordinateur-jury.page_subtitle');
      formConfig.conferencierStatut = "coordinateur_jury";
      formConfig.contactSource = "coordinateur_jury"
      formConfig.formName = "coordinateur_jury";
      formConfig.idStatutSelection = [77, 79, 80, 81, 82, 83, 84, 90, 91];
      break;

    case 'coordinateur-programme':
      formConfig.displayTempsFortsSection = true;
      formConfig.displayStatutSection = true;
      formConfig.displayCollectifsTechnoSection = true;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayCollectifsPlanActionSection = true;
      formConfig.displayCollectifsPlanActionSection = false;
      formConfig.pageTitle = 'Coordinateur de programme : demande de participation initiale';
      formConfig.pageSubtitle = `<p>Merci d'avoir accepté de vous positionner comme coordinateur annuel de programme de #myGlobalVillage.</p>
      Ce formulaire permet:
      <ul>
        <li> de vous pré-positionner sur le calendrier évènementiel de votre choix,</li>
        <li>de mettre à jour vos coordonnées,</li>
        <li>de disposer du lien de rattachement à envoyer à vos jurés afin qu'ils soient intégrés à votre jury</li>
      </ul>
      Merci de votre engagement au sein la dynamique d'intelligence collective de #myGlobalVillage`;
      formConfig.conferencierStatut = "coordinateur_programme";
      formConfig.contactSource = "coordinateur_programme"
      formConfig.formName = "coordinateur_programme";
      formConfig.idStatutSelection = [77, 79, 80, 81, 82, 83, 84, 90, 91];
      break;

    case 'participant':
      formConfig.displayTempsFortsSection = true;
      formConfig.displayStatutSection = true;
      formConfig.displayCollectifsTechnoSection = true;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayRegistrationFormPlanActionSection = true;
      formConfig.displayCollectifsPlanActionSection = true;
      formConfig.pageTitle = t('form.templates.participant.page_title');
      formConfig.pageSubtitle = t('form.templates.participant.page_subtitle');
      formConfig.conferencierStatut = "participant";
      formConfig.contactSource = "participant"
      formConfig.formName = "participant";
      //, 75,74,76,77,78 offreur
      formConfig.idStatutSelection = [77, 79, 80, 81, 82, 83, 84, 90, 91, 75, 74, 76, 77, 78];
      break;


    case 'participant-comptedetiers':
      formConfig.displayTempsFortsSection = true;
      formConfig.displayStatutSection = true;
      formConfig.displayCollectifsTechnoSection = false;
      formConfig.displayCollectifsSectoSection = false;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayRegistrationFormPlanActionSection = false;
      formConfig.displayContactAndOrganismeSection = true;
      formConfig.displayContactSection = false;
      formConfig.displayOrganismeSection = false;
      formConfig.displayCollectifsPlanActionSection = false;
      formConfig.displayDemoSection = true;
      formConfig.pageTitle = t('form.templates.participant-comptedetiers.page_title');
      formConfig.pageSubtitle = t('form.templates.participant-comptedetiers.page_subtitle');
      formConfig.conferencierStatut = "participant";
      formConfig.contactSource = "participant"
      formConfig.formName = "participant";
      //, 75,74,76,77,78 offreur
      formConfig.idStatutSelection = [77, 79, 80, 81, 82, 83, 84, 90, 91, 75, 74, 76, 77, 78];
      formConfig.displayTempsFortsSectionConfeventTypeList = []
      break;

    case 'planaction':
      formConfig.displayTempsFortsSection = false;
      formConfig.displayStatutSection = false;
      formConfig.displayCollectifsTechnoSection = true;
      formConfig.displayCollectifsSectoSection = true;
      formConfig.displayCollectifsGeographiquesSection = false;
      formConfig.displayRegistrationFormPlanActionSection = true;
      formConfig.displayEventSection = false;
      formConfig.displayCooptationSection = false;
      formConfig.pageTitle = `Demande de participation au comité de soutien et au groupe de travail`;
      formConfig.pageSubtitle = (`Merci de votre participation au comité de soutien et aux groupes de travail du démonstrateur.

     <p>Ce formulaire permet selon vos ambitions et vos disponibilités : </p>
      <ul>
      	<li>de participer en tant que contributeur ou coordinateur aux programmes d'innovation et de développement du démonstrateur </li>
        <li>de vous positionner au sein de comité de soutien et de vous pré-positionner pour des créneaux de visite du démonstrateur en groupe privatif le jour J</li>
      </ul>
`);
      formConfig.conferencierStatut = "planaction";
      formConfig.contactSource = "planaction"
      formConfig.formName = "planaction";
      //, 75,74,76,77,78 offreur
      formConfig.idStatutSelection = [77, 79, 80, 81, 82, 83, 84, 90, 91, 75, 74, 76, 77, 78];
      formConfig.displayCollectifsTechnoSection = ['2270'].includes(id_cycle_lang) ? false : true;
      formConfig.displayCollectifsSectoSection = ['2270'].includes(id_cycle_lang) ? false : true;
      break;

    default: formConfig.pageTitle = ""
      break
  }

  //const { register, handleSubmit, watch, formState: { errors } } = useForm();

  //const RegisterContext = createContext(register);
  const methods = useForm({
    defaultValues: defaultValues
  });

  const updateContactVisuel = async ({ visuelType, id_contact, file, id_presta, id_event, id_event_ligne }) => {

    if (!file) {
      return false
    }

    let formData = new FormData();
    formData.append('do', "update_visuels_candidature")
    formData.append('visuel', visuelType)
    formData.append('id_contact', id_contact)
    formData.append('id_presta', id_presta)
    formData.append('Filedata', file[0])
    formData.append('id_event', id_event)
    formData.append('id_event_ligne', id_event_ligne)

    await axios.post('https://www.mlg-consulting.com/websites/g_event_beta/views/exhibitor_registration/do.php',
      formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }


  const onSubmit = async data => {

    var formData = new FormData();

    setFormIsSubmitted(true);

    let allEventCycleLangList = [];

    //if(allEventCycleLangList)data.demonstrateurs &&  ? [...data.demonstrateurs, id_event_cycle_lang] : data.demonstrateurs;

    if (data.demonstrateurs && data.demonstrateurs.length > 0) {
      allEventCycleLangList = [...allEventCycleLangList, data.demonstrateurs];
    }

    if (data.eventCycleLang && data.eventCycleLang.length > 0) {
      allEventCycleLangList = [...allEventCycleLangList, data.eventCycleLang];
    }

    if (id_event_cycle_lang) {
      allEventCycleLangList = [...allEventCycleLangList, id_event_cycle_lang];
    }

    data = {
      ...data,
      do: 'save_candidature',
      debug: 'n',
      id_event_contact_type_main: formulaire.id_event_contact_type_main,
      ie_: currentEvent?.encrypted_id_event ?? '',
      ie: idEvent == 1738 ? 'Z5tlag==' : currentEvent?.encrypted_id_event ?? '',
      id_contact: idContact,
      id_presta: presta?.id_presta,
      id_contact_test: idContact,
      contact_source: formulaire.route,
      inscription_source: formulaire.route,
      formName: formulaire.route,
      formRoute: formulaire.route,
      formId: formulaire.id_formulaire,
      lng: i18n.language,
      conferencier_statut: formConfig.conferencierStatut,
      historique: "",
      auteur: auteur ?? coordinateur,
      challenges: JSON.stringify(data.challenges),
      confEventSelection: JSON.stringify(data.confEventSelection),
      demonstrateurs: JSON.stringify(allEventCycleLangList),

      programmeList: JSON.stringify(data.programmeList),
      parcoursThematiques: JSON.stringify(parcoursThematiquesSelection),
      idEventContactTypeList: JSON.stringify(data.idEventContactTypeList),

      contactProgrammeInnovationList: data.contactProgrammeInnovationList ? JSON.stringify(data.contactProgrammeInnovationList.map(item => JSON.parse(item))) : '',

      confEventEventContactTypeSelection: data.confEventEventContactTypeSelection && Array.isArray(data.confEventEventContactTypeSelection) ? JSON.stringify(data.confEventEventContactTypeSelection.map(item => JSON.parse(item))) : '',
      eventSelection: data.eventSelection ? JSON.stringify(data.eventSelection.map(item => JSON.parse(item))) : '',

      allCycleLangList: data.eventCycleLang && Array.isArray(data.eventCycleLang) ? JSON.stringify(data.eventCycleLang.map(item => JSON.parse(item))) : JSON.stringify([data.eventCycleLang]),



      //eventCycleLangPrestaTypeList: data.eventCycleLangPrestaTypeList ? JSON.stringify(data.eventCycleLangPrestaTypeList.map(item => JSON.parse(item))) : '',

      confEventCycleLangSelection: data.confEventCycleLangSelection ? JSON.stringify(data.confEventCycleLangSelection.map(item => JSON.parse(item))) : '',

      cycleLangIdEventContactTypeList: data.cycleLangIdEventContactTypeList ? JSON.stringify(data.cycleLangIdEventContactTypeList.map(item => JSON.parse(item))) : '',

      cycleLangList: data.cycleLangList ? JSON.stringify([...data.cycleLangList, id_cycle_lang]) : JSON.stringify([id_cycle_lang]),

      onboardedContactList: data.onboardedContactList ? JSON.stringify(data.onboardedContactList.map(item => (item))) : ''
    }

    JSON.parse(JSON.stringify(data), (key, value) => {
      formData.append(key, value);
    });

    await axios('https://www.mlg-consulting.com/websites/g_event_beta/views/exhibitor_registration/do.php', {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
      data: formData
    })
      .then(res => {
        updateContactVisuel({ visuelType: 'photo', id_contact: res.data.new_id_contact, file: data.photo })
        updateContactVisuel({ visuelType: 'logo', id_contact: res.data.new_id_contact, file: data.logo })
        updateContactVisuel({ visuelType: 'organisme_video_url', id_contact: res.data.new_id_contact, file: data.organisme_video_url })
        updateContactVisuel({ visuelType: 'organisme_video_hosted', id_contact: res.data.new_id_contact, file: data.organisme_video_hosted })
        data.presta_nom && updateContactVisuel({ visuelType: 'presta_visuel', id_presta: res.data.new_id_presta, file: data.presta_visuel })
        data.presta_nom && updateContactVisuel({ visuelType: 'presta_ppt', id_presta: res.data.new_id_presta, file: data.presta_ppt })
        data.presta_nom && updateContactVisuel({ visuelType: 'video_hosted', id_presta: res.data.new_id_presta, file: data.video_hosted })
        data.event_nom && updateContactVisuel({
          visuelType: 'el_visuel',
          id_event_ligne: res.data.new_id_event_ligne,
          id_event: res.data.new_id_event,
          file: data.event_logo
        })
        data.event_nom && updateContactVisuel({
          visuelType: 'event_logo',
          id_event_ligne: res.data.new_id_event_ligne,
          id_event: res.data.new_id_event,
          file: data.event_logo
        })

        return res;
      })
      .then(res => {
        setFormIsSubmitted('end')
        return res;
      })
      .then(res => {
        if (packId) {

          handleLoginPaiement({
            id_contact: res.data.new_id_contact,
            contact: res.data.contact,
            mail: res.data.new_id_contact,
            pass_encrypt: res.data.new_id_contact,
            panier: data.panier
          })
        }
      })
  }

  const Visuel = () => {

    if (!currentEvent) return null

    let img = currentEvent && currentEvent?.logo ? `http://www.mlg-consulting.com/manager_cc/events/img_uploaded/${currentEvent?.logo}` : `https://www.mlg-consulting.com/manager_cc/docs/archives/${currentEvent?.event_ligne?.visuel_1024}`

    img = `https://www.mlg-consulting.com/manager_cc/docs/archives/${currentEvent.event_ligne.visuel_1024}`;

    return <img className="w-100 " src={img} alt="" />

  }

  const getFormulaireList = async () => {

    await fetch(`${apiUrlTranslation}?action=getFormulaireList&params=WHERE route="${formName}"`)
      .then(res => res.json())
      .then(res => {
        console.log(res[0]);
        setFormulaire(res[0])
      })
  }

  const checkContactisTrue = ({ contact, panier }) => {

    //    const redirect_url = `https://www.mlg-consulting.com/partners/login.php?origin=mgv&iecl=${packId}&ie=${idEvent}&ic=${contact.id_contact_encrypt}`;

    const redirect_url = `https://www.mlg-consulting.com/partners/login.php?origin=mgv&iecl=${packId}&ie=${idEvent}&ic=${contact.id_contact_encrypt}&panier=${panier}`;

    window.location.replace(redirect_url);

    //console.log(redirect_url)
  }

  const handleLoginPaiement = async ({ id_contact, mail, pass_encrypt, contact, panier }) => {

    //e.preventDefault();

    var datas_login = {

      "action": "login",
      "email_login": mail,
      //  "password_login": 'ù*$$*ù',
      "password_login_encrypt": pass_encrypt,
      'id_event': idEvent,
      'id_contact': id_contact,
      'iecl': packId,
      panier: JSON.stringify(panier)
    }

    //console.log(datas_login); return false;

    setMessage(<div className="alert alert-success font-bold text-2xl flex flex-col  items-center justify-center"> Vous allez être redirigé vers votre espace de paiment en ligne... <Loader /></div>)

    await fetch(`https://www.mlg-consulting.com/myglobalvillage/do.php?action=login`, {
      method: 'POST',
      body: JSON.stringify(datas_login)
    })
      .then(res => res.json())
      .then(res => {

        if (res.condition === 'true') {

          checkContactisTrue({ contact, panier: JSON.stringify(panier) });

          console.log(res.condition)
        }

        console.log(res)
      })
  }


  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);
    const isVisible = urlParams.get('formisvisible');
    const idFormulaire = urlParams.get('id_formulaire');
    const auteur = urlParams.get('auteur');
    const packSelection = urlParams.get('packSelection');

    setAuteur(auteur)

    getFormulaireList();

    if (isVisible === '1') {
      setFormIsVisible(true);
    }

    // if (idFormulaire == 48 && idEvent == 1822) {

    //   setPackId(7098)
    // } else if (idFormulaire == 48 && idEvent == 1809) {
    //   setPackId(7123)
    // }
    // else if (idFormulaire == 54 && idEvent == 1809) {
    //   setPackId(7138)
    // }

    //packSelection is like 1544,1588,252588,

    //convert packSelection to array
    if (packSelection) {
      const packSelectionArray = packSelection && packSelection.split(',');
      setPackId(packSelectionArray)
    }



    const new_id_cycle_lang_preselection_list = urlParams.get('id_cycle_lang_preselection_list');
    setIdCycleLangPreselectionList(new_id_cycle_lang_preselection_list)

    console.log('isVisible', isVisible)

    const getEventCycleLangList = async () => {

      await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params=WHERE id_event_cycle_lang=${id_event_cycle_lang} LIMIT 1`)
        .then(res => res.json())
        .then(res => setEventCycleLang(res[0]))
    }

    const getDemo = async ({ idContact }) => {

      await fetch(`${API_URL}?action=getPrestaList&params=WHERE id_contact=${idContact} ORDER BY date_creation DESC LIMIT 1`)
        .then(res => res.json())
        .then(res => {
          console.log(res[0]);
          setPresta(res[0])
          methods.reset()
        })
    }

    const checkProfile = () => {

      const urlParams = new URLSearchParams(window.location.search);

      const isVisible = urlParams.get('formisvisible');
      setFormIsVisible(isVisible === '1' ? true : false);

      console.log('checkProfile', contact)

    }

    const getContact = async () => {

      await fetch(`${API_URL}?action=getContact&id_contact=${idContact}`)
        .then(res => res.json())
        .then(res => {
          console.log(res);
          setContact(res)
          methods.reset()
          return res
        })
        .then(res => getDemo({ idContact: res.id_contact }))
    }

    checkProfile();

    id_event_cycle_lang && getEventCycleLangList()
    idContact && getContact()
    idContact && checkProfile()


  }, [i18n.resolvedLanguage, displayTechFundRaiserSection, formIsSubmitted])


  if (!formulaire) return null


  if (formulaire && formulaire.id_formulaire == 26 && idEvent == 1789) {

    return <Layout className="h-full">
      <div className="h-full">


        <div className="bg-indigo-700">
          <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Registration for the myGlobalvillage pitch sessions is now closed.


              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-indigo-200">
                We thank you for your interest and look forward to welcoming you at one of our upcoming events         </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="#"
                  className="hidden rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Contact
                </a>
                <a href={`/events/${idEvent}/event-component/14/`} className="text-sm font-semibold leading-6 text-white">
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>

  }


  return (
    <Layout className="bg-light">

      {/* <CoordonneesChecker contact={contact} /> */}

      <div className="pr-0 md:pr-3 pl-0 md:pl-3">

        <RegistrationFormCollectifPresentationSection pageTitle={formulaire && formulaire.page_title != "" ? formulaire.page_title : formConfig.pageTitle} />

        {!id_cycle_lang && <div className="flex flex-col md:flex-row px-1 d-none">

          <div className="basis-1/3__ pr-5 ">

            {
              // currentEvent && formName == "startup-onboarding" ? <img src="https://www.mlg-consulting.com/manager_cc/docs/archives/230728140516_whatsapp-image-2023-07-17-a-12.21.08-.jpg"></img> : <Visuel /> 
            }

            {/* {currentEvent && formName == "startup-onboarding" ? <img src={formulaire && formulaire.visuels.medium} /> : <Visuel />} */}

            {eventCycleLang &&
              <div className=" mt-3 flex py-2 px-5 bg-gray-200 rounded-2xl pl-5 ">
                <div>
                  <img src={eventCycleLang.cycle_lang.logo_better.large} alt="" className="w-10 mr-3" /></div>
                <div className="font-bol m-auto"> {eventCycleLang.event_cycle_lang_nom} </div>
              </div>
            }

            <PitchRegistrationCycleLangSelectionHeader formulaire={formulaire} />

            {/* {formulaire.visuel && <img src={formulaire.visuels.large} />} */}
          </div>

          <div className="basis-2/3__ m-auto__">
            <div className=" lg:text-xl  text-lg sm:mb-3 ">  {formulaire.name}</div>
            <div className=" lg:text-3xl text-2xl font-bold">
              {formulaire && formulaire.page_title != "" ? formulaire.page_title : parse(formConfig.pageTitle)}
            </div>
          </div>
        </div>}

        <div className="flex  bg-neutral-950 p-3 text-white space-x-6">
          <div className="basis-/12 flex items-center">
            <img src={formulaire.visuels.large} alt="" className="w-40 " />
          </div>
          <div className=" flex flex-col basis-10/12 justify-center ">
            <div className=" lg:text-xl  text-lg sm:mb-3 ">  {formulaire.name}</div>
            <div className=" lg:text-4xl text-xl font-bold uppercase">
              {formulaire && formulaire.page_title != "" ? parse(formulaire.page_title) : parse(formConfig.pageTitle)}

            </div>
          </div>
        </div>

        <PitchRegistrationCycleLangSelectionHeader formulaire={formulaire} />




        <div className="alert alert-success my-5" style={{ display: formIsSubmitted == 'end' ? 'block' : 'none' }}> Votre demande a été envoyée</div>
        {message}
        <div className="alert alert-info my-5 text-center" style={{ display: formIsSubmitted && formIsSubmitted != 'end' ? 'block' : 'none' }}>
          <Loader />
          <p className=" text-4xl pb-28"> Traitement en cours, veuillez patienter</p>
        </div>
        <div className={`alert alert-info my-5 ${[true, 'end'].includes(formIsSubmitted) ? 'none' : 'lg:block'}`}>

          <div className="flex space-x-3">
            <div>
              <img src={formulaire.instructions_generales_section_visuel_thumbs.medium} alt="" className="w-60" style={{ width: '400px' }} />
            </div>
            <div>
              {formulaire && formulaire.page_subtitle != "" ? parse(formulaire.page_subtitle) : parse(formConfig.pageSubtitle)}
            </div>
          </div>
        </div>


        <FormProvider {...methods}>


          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="form-horizontal"
            style={{ display: formIsSubmitted ? 'none' : 'block' }}>

            <button
              style={{ display: isDev() ? 'block' : 'none' }}
              className="btn btn-secondary" onClick={(e) => {
                e.preventDefault();
                //const es = methods.getValues().eventSelection;
                //const b = es.map(item => JSON.parse(item))
                //console.log(JSON.stringify(b));

                console.log(methods.getValues());
              }} >check data</button>

            <RegistrationFormProfileCheckerSection
              contact={contact}
              setFormIsVisible={setFormIsVisible}
              formIsVisible={formIsVisible} />

            <div className={`bg-gray-100 p-1 md:p-6 ${!formIsVisible ? 'hidden' : ''}`}>



              {formulaire.client_partenaire_section_is_visible == "1" && <RegistrationFormClienPartenaireSection
                formulaire={formulaire}
                contact={contact} />}


              {formulaire.modalites_collaboration_section_is_visible == "1" && <RegistrationFormModalitesCollaborationSection formulaire={formulaire} />}

              {formulaire.programmes_action_is_visible == "1" && <RegistrationFormProgrammesActionSection formulaire={formulaire} />}


              {(
                //formConfig.displayContactSection ||
                formulaire.contact_section_is_visible == "1") && <RegistrationFormContactSection
                  contact={contact}
                  supertitle={formConfig.contactSectionSuperTitle}
                  formulaire={formulaire} />
              }

              {formulaire.contreparties_section_is_visible == "1" &&
                <RegistrationFormEngagementsContrepartiesSection
                  title={formulaire.contreparties_section_title}
                  instructions={HTMLReactParser(formulaire.contreparties_section_instructions ?? "")}
                  formulaire={formulaire} />
              }

              {(
                //formConfig.displayOrganismeSection || 
                formulaire.organisme_section_is_visible == "1") && <RegistrationFormOrganismeSection
                  formulaire={formulaire}
                  contact={contact} />}

              {(
                //formConfig.displayContactAndOrganismeSection || 
                formulaire.contact_and_organisme_section_is_visible == "1") && <RegistrationFormContactAndOrganismeSection
                  formulaire={formulaire}
                  contact={contact} />}


              {formulaire.profil_section_is_visible == "1" && <RegistrationFormProfilCooperationSection contact={contact} formulaire={formulaire} />}


              {formulaire.profil_investisseur_section_is_visible == "1" && <RegistrationFormProfilInvestisseurSection contact={contact} formulaire={formulaire} />}


              {/* formConfig.displayPasswordSection || */}
              {(formulaire.password_section_is_visible == "1") && <RegistrationFormPasswordSection contact={contact} />}

              {(formulaire.demo_section_is_visible == "1") && <RegistrationFormDemoSection
                formulaire={formulaire}
                presta={presta}
                groupe_section_sourcing_title={formulaire?.groupe_section_demo_title} />}

              {formulaire.demo_light_section_is_visible == "1" && <RegistrationFormDemoSectionLight presta={presta} groupe_section_sourcing_title={formulaire?.groupe_section_demo_title} />}

              {formulaire.certificate_section_is_visible == "1" && <RegistrationFormCertification contact={contact} />}



              {formulaire.video_presentation_section_is_visible == "1" && <RegistrationFormVideoPresentationSection formulaire={formulaire} contact={contact} />}

              {formConfig.displayCollectifsProgramme && !["30"].includes(formulaire.id_formulaire) && <div className="bg-dark font-bold p-3  text-white text-2xl rounded-t-2xl">
                {formulaire && formulaire.groupe_section_sourcing_title != "" ? formulaire.groupe_section_sourcing_title : "Descriptif de mes domaines de sourcing ou d'investissement"}
              </div>
              }

              {formulaire.delagation_section_is_visible == "1" && <RegistrationFormCycleLangSection
                //  title={formConfig.collectifProgrammeListSectionTitle}

                //   instructions={formConfig.collectifProgrammeListSectionInstructions}

                title={formulaire && formulaire.collectif_programme_section_title != "" ? formulaire.collectif_programme_section_title : formConfig.collectifProgrammeListSectionTitle}
                instructions={formulaire && formulaire.collectif_programme_section_instructions != "" ? formulaire.collectif_programme_section_instructions : formConfig.collectifProgrammeListSectionInstructions}
                instructions_="Afin de vous connecter avec les clients-finaux, les distributeurs, les investisseurs, les coordinateurs de programmes d’accélération et les « tech’xporteurs » de myGlobalVillage, précisez ci-dessous les domaines d’usage prioritaires de votre solution."
                cycleLangTypeList={['collectif_programme', 'collectif_planaction']} />}


              {formulaire.id_formulaire == 61 && <RegistrationFormYourProjectSection

                presta={presta}
                formulaire={formulaire}
                contact={contact} />
              }


              {/* {
                (formConfig.displayCasterSectionOffreur || formulaire.id_formulaire == 47) && <RegistrationFormCasterSectionOffreur
                  instructions="onboarding.caster_section.instructions"
                  title="onboarding.caster_section.title"
                  displayCollectifsGeographiquesSection={displayCollectifsGeographiquesSection}
                  setDisplayCollectifsGeographiquesSection={setDisplayCollectifsGeographiquesSection}
                  displayEventSection={displayEventSection}
                  setDisplayEventSection={setDisplayEventSection}
                  displayCollectifsSectoSection={displayCollectifsSectoSection}
                  setDisplayCollectifsSectoSection={setDisplayCollectifsSectoSection}
                  setDisplayContactCommunauteSection={setDisplayContactCommunauteSection}
                  displayContactCommunauteSection={displayContactCommunauteSection}

                  setDisplayTechFundRaiserSection={setDisplayTechFundRaiserSection}
                  displayTechFundRaiserSection={displayTechFundRaiserSection}
                  contact={contact}
                  formulaire={formulaire}

                />
              } */}

              {formConfig.displayStatutSection || formulaire.statut_section_is_visible == "1"
                &&
                <RegistrationFormStatutSection
                  contact={contact}
                  formulaire={formulaire}
                  idStatutSelection={formConfig.idStatutSelection ?? formulaire.id_statut_selection} />}



              {formulaire.cycle_lang_event_ligne_section_is_visible == 1 && <RegistrationFormCycleLangSectionEventLigne

                title={formulaire.cycle_lang_event_ligne_section_title}
                instructions={formulaire.cycle_lang_event_ligne_section_instructions}
                formulaire={formulaire}
              />}



              {
                //displayContactCommunauteSection 
                formConfig.displayContactCommunauteSection && <RegistrationFormContactCommunauteSection contact={contact} />}

              {formConfig.displayTechFundRaiserSection || formulaire.recherche_investisseurs_section_is_visible == "1"
                //|| displayTechFundRaiserSection 
                &&

                <>

                  <RegistrationFormTechFundRaiserSection
                    formulaire={formulaire}
                    contact={contact}
                    setDisplayTechFundRaiserSection={setDisplayTechFundRaiserSection}
                    displayTechFundRaiserSection={displayTechFundRaiserSection} />
                </>
              }



              {formulaire.collectif_geographique_section_is_visible == "1" &&
                <RegistrationFormCycleLangListSection
                  contact={contact}
                  cycleLangType="collectif_geographique"
                  formulaire={formulaire} />}

              {formulaire.delagation_section_is_visible == "1" && <RegistrationFormDelegationSection
                supertitle={<RegistrationFormSupertitleSection>Descriptif de ma délégation sur {currentEvent?.nom}</RegistrationFormSupertitleSection>}
                eventCycleLang={eventCycleLang} />}



              {formConfig.displayCollectifsGeographiquesSection && formulaire.collectif_geographique_section_is_visible == "1"
                // || displayCollectifsGeographiquesSection 
                && <>
                  {/* <RegistrationFormCycleLangSection
                    title={collectifsGeographiquesSectionTitle}
                    subtitle_={formConfig.collectifsGeographiquesSectionSubtitle}
                    instructions={HTMLReactParser(collectifsGeographiquesSectionInstruction ?? "")}
                    cycleLangTypeList={['collectif_geographique']}
                    sectionBodyIntro={HTMLReactParser(t('form.templates.startup-onboarding.collectifsGeographiquesSection_options'))}
                    sectionBodyEnd={HTMLReactParser(t('form.templates.startup-onboarding.collectifsGeographiquesSection_notabene'))}

                    sectionBodyRevealInput={t('form.templates.startup-onboarding.collectifsGeographiquesSection_body_reveal_input')}
                    sectionBodyHideInput={t('form.templates.startup-onboarding.collectifsGeographiquesSection_body_hide_input')}
                    idEventContactType="253"
                    contact={contact}
                    formulaire={formulaire}
                  /> */}

                  {/* 
                  <RegistrationFormCycleLangSection
                    title="Recherche d'entreprises à racheter dans un pays pour stimuler votre croissance externe :"
                    subtitle_={formConfig.collectifsGeographiquesSectionSubtitle}
                    instructions="MyGlobalVillage peut mandater son réseau de confiance sur 5 continents pour identifier des entreprises dans votre secteur à racheter."
                    cycleLangTypeList={['collectif_geographique']}
                    sectionBodyIntro=" Je détaille dans le cadre ci-dessous le profil des entreprises recherchées et serai recontacté par un relais MyGlobalVillage pour affiner la recherche et signer un mandat de vente dans les pays de votre choix."
                    sectionBodyEnd="Comment se rémunère MyGlobalVillage : Si vous devenez membre de MyGlobalVillage, la rémunération s'effectuera uniquement au résultat, avec un montant calculé sur la vente réalisée lors de votre opération de croissance externe."
                    sectionBodyRevealInput='Je cherche à racheter des entreprises de mon secteur'
                    sectionBodyHideInput="Je ne cherche pas à racheter des entreprises de mon secteur"
                    idEventContactType="254"
                  /> 
                  */}

                  {/* 
                  <RegistrationFormCycleLangSection
                    title="Recherche de territoires et de programmes d'attractivité territoriale pour accueillir l'une de vos agences/filiales dans des pays cibles."
                    subtitle_={formConfig.collectifsGeographiquesSectionSubtitle}
                    instructions={collectifsGeographiquesSectionInstruction}
                    cycleLangTypeList={['collectif_geographique']}
                    sectionBodyIntro=""
                    sectionBodyEnd="Comment se rémunère MyGlobalVillage : Si vous êtes membre de MyGlobalVillage, nous sommes rémunérés par les territoires afin de leur identifier des entreprises pertinentes à attirer. Vous n'aurez rien à payer pour ce service."
                    sectionBodyRevealInput="Je ne recherche pas des programmes d'attractivité territoriale (appels à projets, subventions gouvernementales, etc.) pour m'implanter dans un ou plusieurs pays."
                    sectionBodyHideInput="Je ne cherche pas de programmes d'attractivité territoriale pour m'implanter dans un ou plusieurs pays."
                    idEventContactType="255"
                  /> 
                  */}
                </>
              }


              {/* {formConfig.displayOnboardingSection || formulaire.onboarding_section_is_visible && <RegistrationFormOnboardingSection />} */}


              {
                //formulaire.cycle_lang_preselection_section_is_visible == "1" &&

                idCycleLangPreselectionList &&
                <>

                  <RegistrationFormCycleLangPreselection
                    contact={contact}
                    formulaire={formulaire}
                    title_="Vos cercles géographiques"
                    title={t('form.sections.cycle_lang_preselection_geographique.section_title')}
                    cycleLangType="collectif_geographique"
                    cycleLangTypeList={['collectif_geographique', 'collectif_planaction', 'collectif_linguistique', 'collectif_partenaire']}
                    instructions_="Je détaille ci-dessous les actions collectives (programmes d'innovation, de labélisation, d'accélération, d'attractivité… auxquels je peux m'associer" />

                  {/* <RegistrationFormCycleLangPreselection
                    formulaire={formulaire}
                    title_="Vos cercles géographiques"
                    title={t('form.sections.cycle_lang_preselection_geographique.section_title')}
                    cycleLangType="collectif_geographique"
                    instructions_="Je détaille ci-dessous les actions collectives (programmes d'innovation, de labélisation, d'accélération, d'attractivité… auxquels je peux m'associer" />
 */}

                  {/* <RegistrationFormCycleLangPreselection
                    formulaire={formulaire}
                    cycleLangType="collectif_planaction"
                    title_="Sélection de vos programmes d'innovation et d'accélération"
                    title={t('form.sections.programmes_innovation.section_title')}
                    instructions_="Je détaille ci-dessous les actions collectives (programmes d'innovation, de labélisation, d'accélération, d'attractivité… auxquels je peux m'associer" /> */}
                </>
              }

              {formConfig.displayCasterSectionAcheteur && <RegistrationFormCasterSectionAcheteur
                instructions="onboarding.caster_section.instructions"
                title="onboarding.caster_section.title"
                displayCollectifsGeographiquesSection={displayCollectifsGeographiquesSection}
                setDisplayCollectifsGeographiquesSection={setDisplayCollectifsGeographiquesSection}

                displayCollectifsSectoSection={displayCollectifsSectoSection}
                setDisplayCollectifsSectoSection={setDisplayCollectifsSectoSection}

                setDisplayTechFundRaiserSection={setDisplayTechFundRaiserSection}
                displayTechFundRaiserSection={displayTechFundRaiserSection}

              />}

              {formConfig.displayEventCycleLangSection && <RegistrationFormEventCycleLangSection
                title="Sélection de la zone et de la thématique de démonstration" />}




              {(formConfig.displayParticipationDistance || formulaire.participation_mode_section_is_visible == 1) && <RegistrationFormParticipationDistance />}


              {formConfig.displayTempsFortsSection &&
                formulaire && formulaire.id_formulaire != 24 && <RegistrationFormJuryEventSection
                  formulaire={formulaire}
                  contact={contact}
                  title={formConfig.sectionTempsfortsTitle ?? t('form.sections.tempsforts_section.section_title')}
                  instructions={formConfig.sectionTempsfortsInstructions ?? t('form.sections.tempsforts_section.section_title')}
                  confEventTypeList={formConfig.displayTempsFortsSectionConfeventTypeList} />}


              {(formConfig.displayTempsFortsSectionAll ||
                formulaire.temps_forts_section_all_is_visible == "1")
                && formulaire && formulaire.id_formulaire != 24
                && <RegistrationFormJuryEventSection
                  formulaire={formulaire}
                  contact={contact}
                  title={t('form.sections.tempsforts_section.section_title')}
                  instructions={t('form.sections.tempsforts_section.section_title')} //"Je coche ci-dessous les temps forts (sessions d'ouverture, brief de départ des tech'xplorations, débriefings...) auxquels je souhaite assister ou participer"
                  confEventTypeList={['3', '13', '83', '84', '86', '90', '78', '51', '62', '89', '67', '88', '74', '92', '78', '95', '96', '97', '101', '102', '116']}
                />}

              {formConfig.displayTempsFortsExposantsSection && <RegistrationFormJuryEventSection
                formulaire={formulaire}
                contact={contact}
                title="Validation  de mes interventions rapides lors des briefing matinaux"
                instructions="Je valide mon intention rapide de présentation de mes solutions innovantes lors des briefing des tech'xplorateurs du matin"
                confEventTypeList={['97']} />} {
              /*
              97 = techreview 
              95 = opening session 
              */}


              {formConfig.displaySoiree && <RegistrationFormJuryEventSection
                formulaire={formulaire}
                contact={contact}
                title={t('form.sections.soirees_section.section_title')}
                instructions={t('form.sections.soirees_section.section_title')}
                confEventTypeList={['65']} />}

              {formConfig.displayBreakfast && <RegistrationFormJuryEventSection
                formulaire={formulaire}
                contact={contact}
                title={t('form.sections.breakfast_section.section_title')}
                instructions={t('form.sections.breakfast_section.section_instructions')}
                confEventTypeList={['5']} />}

              {formConfig.displayParcoursThematiques && <RegistrationFormParcoursThematiques
                title={t('form.sections.parcours_thematiques.section_title')}
                instructions={t('form.sections.parcours_thematiques.section_instructions')}
                confEventTypeList={['66', '63', 67, '67', null, 'null', ' ', '']}
                parcoursThematiquesSelection={parcoursThematiquesSelection}
                setParcoursThematiquesSelection={setParcoursThematiquesSelection}
              />}


              {formConfig.displayPitchsSection && currentEvent && <RegistrationFormJuryEventSection
                formulaire={formulaire}
                contact={contact}
                title_={t('form.sections.session_pitch.section_title')}
                title={formulaire && formulaire.session_pitch_section_title != "" ? formulaire.session_pitch_section_title : t('form.sections.session_pitch.section_title')}

                instructions_={t('form.sections.session_pitch.section_instructions')}
                instructions={formulaire && formulaire.pitchs_section_instructions != "" ? formulaire.pitchs_section_instructions : parse(formConfig.pitchsSectionInstructions)}

                confEventTypeList={['67', '88', '74']}
                confEventTypeListFilter={['97']} />}

              {/* {formConfig.displayTexplorationsSection && currentEvent && <RegistrationFormJuryEventSection
                formulaire={formulaire}
                title={t('form.sections.techxploration_section.section_title')}
                instructions={t('form.sections.techxploration_section.section_instructions')}
                confEventTypeList={['92', '78', '95']} />} */}

              {formConfig.displayParcoursSection && currentEvent && <RegistrationFormJuryEventSection
                contact={contact}
                title={t('form.sections.parcours_de_visite_ouvert.section_title')}
                isThematiques={formName == 'startup-parcours' ? true : false}
                instructions={formConfig.parcoursInstructions}
                confEventTypeList={['66', '63']} />}

              {formConfig.displayTechreviewSection && currentEvent && <RegistrationFormJuryEventSection
                formulaire={formulaire}
                contact={contact}
                title={formulaire && formulaire.techreview_section_title != "" ? formulaire.techreview_section_title : "choix de mes Techreviews"}
                isThematiques={formName == 'startup-parcours' ? true : false}
                instructions={formulaire && formulaire.techreview_instructions != "" ? formulaire.techreview_instructions : parse(formConfig.techreviewInstructions)}
                confEventTypeList={['97']} />}

              {formulaire.onboarding_section_is_visible == "1" && <RegistrationContactsOnboardingSection
                contact={contact} />}


              {formulaire.id_formulaire == "4" && <RegistrationFormJuryEventCoordinatorToolsSection />}


              {formConfig.displayRegistrationFormPlanActionSection && <RegistrationFormPlanActionSection
                activeProgramme={activeProgramme}
                setActiveProgramme={setActiveProgramme} />}



              {formConfig.displayCollectifsPlanActionSection ||

                formulaire.id_formulaire == "52" &&
                <RegistrationFormCycleLangSection
                  title={t('form.sections.mes_plans_action.section_title')}
                  instructions={t('form.sections.mes_plans_action.section_instructions')}
                  cycleLangTypeList={['collectif_planaction']} />}

              {formConfig.displayCollectifsTechnoSection && <RegistrationFormCycleLangSection
                title={t('form.sections.collectifs_technologiques.section_title')}
                instructions={t('form.sections.collectifs_technologiques.section_instructions')}
                cycleLangTypeList={['collectif_technologique']} />}

              {formConfig.displayCollectifsSectoSection || displayCollectifsSectoSection && <RegistrationFormCycleLangSection
                title={formConfig.sectionCollectifsSectorielsTitle ?? t('form.sections.collectifs_sectoriels.section_title')}
                instructions={formConfig.sectionCollectifsSectorielsInstructions ?? t('form.sections.collectifs_linguistiques.section_instructions')}
                cycleLangTypeList={['collectif_sectoriel']} />}


              {formConfig.displayRegistrationFormZonesDecors && <RegistrationFormZonesDecors />}

              {formConfig.displayCooptationSection && <RegistrationFormCooptation
                title={t('form.sections.cooptation_section.section_title')}
                instructions={t('form.sections.cooptation_section.section_instructions')}
              />}


              {formulaire.event_section_is_visible == "1"

                && <RegistrationFormEventSection formulaire={formulaire} />}

              {formulaire.event_referencement_section_is_visible == "1" && <RegistrationFormEventReferencementSection formulaire={formulaire} />}

              {formulaire.profil_ecosystem_section_is_visible == "1" && <RegistrationFormProfilEcosystemSection formulaire={formulaire} />}

              {formulaire.role_multiple_section_is_visible == "1" && <RegistrationFormRoleMultipleSection
                title={formulaire.role_multiple_section_title}
                instructions={formulaire.role_multiple_section_instructions} />}

              {idEvent != 1738 && formConfig.displayDroitImageSection
                && formulaire.droit_image_section_is_visible == "1"
                && <RegistrationFormDroitImageSection
                  contact={contact} />}


              {/* <RegistrationFormPreviewSection
                contact={contact}
                presta={presta} /> */}


              {formulaire.shopping_cart_section_is_visible == "1"
                && <RegistrationFormShoppingCartSection
                  contact={contact}
                  handleLoginPaiement={handleLoginPaiement}
                  id_event_cycle_lang={packId} />
              }

              {formConfig.displayCoordinateurPresentationSection && <RegistrationFormCoordinateurPresentationSection />}

              <div className="flex items-center p-5 justify-between">
                {formulaire && <img src={formulaire.visuels.small} className="w-20 bg-white p-2" />}
                {/* !formIsSubmitted && */}
                {<input type="submit" className="btn btn-primary btn-lg" value={t('form.envoyer')} />}
              </div>

            </div>
          </form>
          <div className="text-xs py-2 mx-5 text-gray-500"> Id formulaire :  {formulaire.id_formulaire}</div>

        </FormProvider>
      </div>
    </Layout >
  )
}

export default PitchRegistration;