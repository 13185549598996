import React, { useContext, useEffect, useState } from 'react';

import RegistrationFormRow from './RegistrationFormRow'
import RegistrationFormSection from './RegistrationFormSection';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useApiUrlTranslation from './useApiUrlTranslation';
import HTMLReactParser from 'html-react-parser';
import { CheckData } from '../utils';
//import PhoneInput from 'react-phone-number-input/input';

const FORM_SECTION_ID = 3;

const ContactShow = ({ contact, setShowEditSection }) => {

    const { t } = useApiUrlTranslation();

    if (!contact) return null;

    return <div className="flex flex-row">

        <div className="pr-5 basis-1/6">
            <img className=" w-68 rounded-full" src={contact.photos.large} />
        </div>
        <div className="basis-5/6">
            <div className="divide-y divide-gray-100">
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name={t('form.sections.contact_section.prenom')} value={contact.prenom} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name={t('form.sections.contact_section.nom')} value={contact.nom} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name={t('form.sections.contact_section.fonction')} value={contact.fonction} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="E-mail" value={contact.mail} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="mobile" value={contact.port} />

                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="linkedin" value={contact.sn_linkedin} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="X (Twitter) ID" value={contact.sn_twitter} />
                <CheckData
                    setShowEditSection={setShowEditSection}
                    name="Bio" value={contact.edito_court ? HTMLReactParser(contact.edito_court) : ""} />
            </div>
        </div>
    </div>
}


const RegistrationFormContactSection = ({ contact, supertitle, formulaire }) => {

    const { t, i18n } = useApiUrlTranslation();

    const { idContact, idEvent } = useParams();

    const [showEditSection, setShowEditSection] = useState(false);

    const [value, setValue] = useState();

    const handleEditButton = (e) => {
        e.preventDefault()

        setShowEditSection(!showEditSection);
        console.log("handleEditButton")
    }

    useEffect(() => {

        // if contact, show edit section

        if (contact) {

            setShowEditSection(false);
        } else {
            setShowEditSection(true);
        }

        if (formulaire.id_formulaire == "27") {
            // setShowEditSection(true);
        }

    }, [contact, i18n.resolvedLanguage])

    return <>
        <div className="bg-dark font-bold p-3  text-white text-2xl rounded-t-2xl">
            {t('form.sections.contact_and_organisation_section.section_title')}
        </div>

        <RegistrationFormSection
            formSectionId={FORM_SECTION_ID}
            title={t('form.sections.contact_section.section_title')}
            supertitle={supertitle}
            showEditSection={showEditSection}
            handleEditButton={handleEditButton}>


            <div className='flex justify-end'>
                <button
                    onClick={handleEditButton}
                    className=" border-2 bg-blue-200 rounded-full w-28 text-black border-blue-600 ">
                    {showEditSection ? t('form.voir_appercu') : t('form.modifier')}
                </button>
            </div>


            {!showEditSection ? <ContactShow

                setShowEditSection={setShowEditSection}
                contact={contact} /> :

                <>
                    <RegistrationFormRow name='prenom' placeholder={t('form.sections.contact_section.prenom')} defaultValue={contact?.prenom} />
                    <RegistrationFormRow name='nom' placeholder={t('form.sections.contact_section.nom')} defaultValue={contact?.nom} />
                    <RegistrationFormRow name='fonction' placeholder={t('form.sections.contact_section.fonction')} defaultValue={contact?.fonction} />
                    <RegistrationFormRow name='mail' placeholder="E-mail" defaultValue={contact?.mail} />
                    <RegistrationFormRow name='port' placeholder="Mobile" defaultValue={contact?.port} />


                    {/* <PhoneInput
                        placeholder="Enter phone number"
                        value={value}
                        onChange={setValue} /> */}


                    <RegistrationFormRow name='sn_linkedin' placeholder="Linkedin " required={false} defaultValue={contact?.sn_linkedin} />

                    <RegistrationFormRow name='sn_twitter' placeholder="Twitter ID " required={false} defaultValue={contact?.sn_twitter} />

                    {/*<RegistrationFormRow name='sn_facebook' placeholder="Facebook page" required={false} defaultValue={contact?.sn_facebook} />*/}

                    <RegistrationFormRow
                        inputType="file"
                        name='photo'
                        placeholder={t('form.sections.contact_section.photo')}
                        defaultValue={contact?.photos.large}
                        required={contact?.photos.large != '' ? false : true}
                        accept={[
                            '.jpeg',
                            '.png',
                            '.jpg'
                        ]} />

                    <RegistrationFormRow
                        isRegister
                        inputType="textarea"
                        name='edito_court'
                        placeholder="Bio"
                        defaultValue={contact?.edito_court}
                        required={false} />
                </>
            }

        </RegistrationFormSection></>
}

export default RegistrationFormContactSection;
