import React from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import RegistrationFormRow from './RegistrationFormRow'

function RegistrationFormProfilInvestisseurSection({ formulaire }) {
    return (
        <RegistrationFormSection
            title={formulaire.profil_investisseur_section_title}
            instructions={formulaire.profil_investisseur_section_instructions}
            formSectionId={33}>

            <RegistrationFormRow
                isRegister
                inputType="text"
                name="deede"
                helper="texte 80 caractères maximum"
                maxLength={80}
                placeholder="Dans quel type de business modèles investissez vous " />

            <RegistrationFormRow
                isRegister
                inputType="number"
                name="xcscrefd"
                placeholder="Montant d'investissement minium en $" />

            <RegistrationFormRow
                isRegister
                inputType="number"
                name="fedxszs"
                placeholder="Montant maximum d'investissement en $ " />

            <RegistrationFormRow
                isRegister
                inputType="number"
                name="fdedsxzws"
                placeholder="Combien d'investissement sur des start’up/scall’up avez vous réalisé ces 24 derniers mois  " />


            <RegistrationFormRow
                isRegister
                inputType="number"
                name="dexws"
                placeholder="Combien d'investisseurs représentez vous " />

            <RegistrationFormRow
                isRegister
                inputType="textarea"
                name="aaaaa"
                placeholder="En plus de l'investissement quels services  proposez vous aux startups de votre portefolio dans lesquelles vous investissez ?" />


        </RegistrationFormSection>
    )
}

export default RegistrationFormProfilInvestisseurSection